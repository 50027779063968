@for $i from 0 through 20 {
  $value: 4 * $i;
  .m-#{$value} {
    margin: #{$value}px;
  }
  .m-t-a-#{$value} {
    margin: #{$value}px auto;
  }
  .m-l-a-#{$value} {
    margin: auto #{$value}px;
  }
  .m-t-l-#{$value} {
    margin: #{$value}px 0;
  }
  .m-l-l#{$value} {
    margin: 0 #{$value}px;
  }
  div.m-t-#{$value} {
    margin-top: #{$value}px;
  }
  .m-l-#{$value} {
    margin-left: #{$value}px;
  }
  .m-r-#{$value} {
    margin-right: #{$value}px;
  }
  .m-b-#{$value} {
    margin-bottom: #{$value}px;
  }

  .p-#{$value} {
    padding: #{$value}px;
  }
  div.p-t-#{$value} {
    padding-top: #{$value}px;
  }
  .p-l-#{$value} {
    padding-left: #{$value}px;
  }
  .p-r-#{$value} {
    padding-right: #{$value}px;
  }
  div.p-b-#{$value},
  h3.p-b-#{$value} {
    padding-bottom: #{$value}px;
  }
  .p-t-a-#{$value} {
    padding: #{$value}px auto;
  }
  .p-l-a#{$value} {
    padding: auto #{$value}px;
  }
  .p-t-l-#{$value} {
    padding: #{$value}px 0;
  }
  .p-l-l#{$value} {
    padding: 0 #{$value}px;
  }
}
.is-list-less {
  margin: 0;
  list-style: none;
  padding: 0;
}
.Button {
  border-radius: 5px;
  line-height: 50px;
  min-width: 160px;
  border: none;
  color: white;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  &-principal {
    background: var(--colors-frozenberry);
  }
}

.box-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.CardWelcomePia--padding {
  height: 173px;
  width: 100%;
  @media (max-width: $medium) {
    height: 0px;
  }
}
.container-CardWelcomePia {
  .nav {
    display: flex;
    .link {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #340aab;
      margin-right: 30px;
      margin-bottom: 15px;
      &-op {
        opacity: 0.3;
      }
    }
  }
  position: fixed;
  z-index: 500;
  width: 100%;
  max-width: 1137px;
  top: 51px;
  background: #f8f8f8;
  padding-top: 36px;
  @media (max-width: 1180px) {
    max-width: calc(100vw - 45px);
  }
  @media (max-width: $medium) {
    padding-top: 0px;
    border: none;
    box-shadow: none;
    border-radius: 20px;
    max-width: calc(100vw - 30px);
    position: initial;
  }
}

.CardWelcomePia {
  background: #1c0e42;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 1000px) {
    width: 100%;
    .box, .box-large {
      width: 100% !important;
    }
    .box {
      display: flex;
      justify-content: center;
    }
    &__head {
      .box {
      width: 100% !important;
        &:last-child {
          width: auto !important;
        }
      }
    }
  }
  .box-large {
    width: 50%;
    @media (min-width: $xx-large) {
      width: 58%;
    }
    @media (max-width: $medium) {
      width: 100%;
    }
  }

  .box {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-left: 20px;

    .card-policies {
      padding: 20px;
      gap: 10px;
      width: 235px;
      height: 150px;
      background: #342856;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

      &__head {
        display: flex;
        justify-content: space-evenly;
        gap: 10px;
        border-bottom: 1px solid var(--colors-pureberry);
        padding-bottom: 10px;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .circle {
            margin-bottom: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #ffbb0d;
            border: 1px solid #ffbb0d;
            &--conectado {
              background: #25d366;
              border: 1px solid #25d366;
            }
            &--vencida {
              background: #ffbb0d;
              border: 1px solid #ffbb0d;
            }
            &--proceso {
              background: transparent;
              border: 1px solid #ffbb0d;
            }
          }
          &-image {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          background-color: transparent;
          border: none;
          box-shadow: none;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #35d8d0;
          padding-top: 10px;
          img {
            margin-left: 10px;
          }
        }
      }
      &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid var(--colors-pureberry);
        span {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-left: 5px;
            margin-right: 10px;
            &--proceso {
              background: transparent;
              border: 1px solid #ffbb0d;
            }
            &--revisar {
              background: #ffbb0d;
              border: 1px solid #ffbb0d;
            }
            &--conectada {
              background: #25d366;
              border: 1px solid #25d366;
            }
          }
        }
      }
    }

    .card-expiration {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      gap: 5px;
      width: 150.84px;
      height: 150px;
      background: #342856;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

      @media (max-width: $medium) {
        width: 45%;
      }

      @media (max-width: $small) {
        width: 100%;
      }

      h5 {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #eb5757;
      }
      p {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }

    @media (max-width: $medium) {
      padding-left: 0;
      width: 100%;

      .card-policies {
        width: 48%;
      }
    }

    @media (max-width: $small) {
      padding-left: 0;

      .card-policies {
        width: 100%;

        &__head {
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $medium) {
      align-items: flex-start;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .icon {
      width: 59px;
      height: 62px;
      object-fit: contain;
    }
    h1 {
      font-family: Lato;
      font-style: normal;
      margin-bottom: 0 !important;
      font-weight: 900;
      font-size: 30px;
      line-height: 31px;
      color: #ffffff;
      margin-left: 20px;

      @media (max-width: $small) {
        margin-left: 0;
        font-size: 28px;
      }
    }
    .circle {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 20px;
      background-color: #c4c4c4;
    }
    .transparent {
      background-color: transparent;
      margin-left: 10px;
      border: none;
      padding: 0;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
  }

  &__head > div:nth-child(2) {
    justify-content: flex-end;
    height: 100%;
  }

  &__footer {
    margin-top: 20px;
    position: relative;
    .btn-delete-search,
    .btn-delete-search:hover,
    .btn-delete-search:active,
    .btn-delete-search:focus {
      position: absolute;
      right: 0;
      top: 7px;
      z-index: 200;
      border: none;
      box-shadow: none;
      background-color: transparent;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #b2b2b2;
      .close {
        margin-left: 10px;
      }
    }
    .ant-input-affix-wrapper {
      height: 51px;
      background: #ffffff;
      border: 1.5px solid #f7f7f7;
      border-radius: 10px;
      .anticon {
        font-size: 20px;
        color: #5d30dd;
      }
      .ant-input {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-pureberry);
        opacity: 1 !important;
        &::placeholder {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-pureberry);
          opacity: 1 !important;
        }
      }
    }
  }
  .mode-darck {
    .ant-input-affix-wrapper {
      background: #1c0e42 !important;
      border: 1.5px solid #f7f7f7;
      input {
        background: #1c0e42 !important;
      }
    }
    .ant-input {
      color: #ffffff !important;
      &::placeholder {
        color: #ffffff !important;
      }
    }
  }
  @media (max-width: $medium) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

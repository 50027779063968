.added-offices {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #f7f7f7;
    border-bottom-left-radius: 20px;
    max-width: 220px;

    &__header {
        height: 35px;
        border-top-left-radius: 20px;
        cursor: pointer;

        background: var(--colors-litberry);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 17px;
        padding-right: 11px;
        h5 {
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: var(--colors-white);
            margin-right: 16px;
            margin-bottom: 0;
        }
    }

    &__wrapper {
        height: calc(100% - 35px);
        overflow: auto;
        padding: 16px 19px;

        &::-webkit-scrollbar {
            background: #5f5f5f2c;
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #5f5f5f3b;
        }
    }

    &__info {
        width: 180px;
        background: var(--colors-white);
        border: 1px solid var(--colors-litberry);
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 14px 0;
        h6 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.02em;
            color: var(--colors-pureberry);
            margin-left: 17px;
            margin-bottom: 0;
        }

        .address {
            margin-bottom: 8px;
        }

        .text-lines {
            margin-top: 18px;
        }

        .lines {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            overflow-y: hidden;
            margin: 6px 5px 14px 17px;

            &__wrapper {
                width: 25.76px;
                height: 25.76px;
                min-width: 25.76px;
                min-height: 25.76px;
                background: var(--colors-pureberry);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 5px;
                margin-right: 5px;
            }

            img {
                width: 14px;
                height: 13px;
            }

            &::-webkit-scrollbar {
                background: #5f5f5f2c;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #5f5f5f3b;
            }
        }

        .insurances {
            display: flex;
            flex-direction: row;
            padding-bottom: 5px;
            overflow-x: auto;
            margin: 15px 10px 30px 17px;

            img {
                margin-right: 5px;
            }

            &::-webkit-scrollbar {
                background: #5f5f5f2c;
                height: 7px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #5f5f5f3b;
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .ant-btn {
            width: 50%;
            height: 40px;
            border-bottom: none !important;
            background: none;
            border-radius: 0;
            border: 1px solid var(--colors-litberry)1a;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.02em;
            color: var(--colors-pureberry);
            padding: 0;
            margin: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin-right: 11px;
            }
        }

        .ant-btn:hover,
        .ant-btn:focus,
        .ant-btn:active {
            width: 50%;
            height: 40px;
            border-bottom: none !important;
            background: none;
            border-radius: 0;
            border: 1px solid var(--colors-litberry)1a;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.02em;
            color: var(--colors-pureberry);
            padding: 0;
            margin: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin-right: 11px;
            }
        }
    }

    &--close{
        background: var(--colors-white);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
    }
}

:root {
  font-size: font-size($typography-body-size);
}

@media screen and (min-width: 768px) {
  :root {
    font-size: font-size($typography-body-size-tablet);
  }
}

@media screen and (min-width: 1367px) {
  :root {
    font-size: font-size($typography-body-size-mobile);
  }
}
.CardCommissions {
  // width: 213px;
  height: 340px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f7f7f7;
    padding: 10px;
    .sub-title {
      text-transform: capitalize;
    }
    .sub-title {
      display: none;
    }
    .small-detail {
      margin-top: 2px;
    }
    .body-bold {
      margin-bottom: 0;
    }
  }
  &__body {
    padding: 10px 0;
		display: flex;
    justify-content: center;
    align-items: center;
    .h1-gradient {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 38.1886px;
      line-height: 46px;
      letter-spacing: -0.02em;
      background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 0;
      margin-left: 5px;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
			&-two {
				display: none;
			}
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
  .circle {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 1px 1px 3px -1px #b9b9b9;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 15px;
      height: auto;
    }
  }
  .rtl {
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        .divider {
          width: 1px;
          height: 12px;
          margin: 0 15px;
          margin-top: -5px;
          background-color: #b2b2b2;
        }
      }
    }
  }
}

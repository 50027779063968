.page__commissions {
  padding-top: 35px;
  margin-top: -35px;
  position: relative;
  z-index: 20;
  background-color: #f7f7f7;
  min-height: 100vh;
  .loader {
    img {
      width: 90px !important;
      height: auto !important;
    }
    p {
      font-size: 14px !important;
    }
  }
  .container {
    width: 100%;
    padding: 0 20px;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .apexcharts-toolbar {
    display: none !important;
  }
  .column {
    width: 63%;
    &-rt {
      width: 37%;
    }
    &_content {
      width: 100%;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      .CardTotals {
        width: 70%;
      }
    }
  }
  .content-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 28%;
    .loader {
      margin-top: 250px;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    .CardCommissions {
      width: 100%;
      &:nth-child(1),
      &:nth-child(2),
      &--branch {
        height: auto;
      }
      &--branch {
        height: 270px !important;
        .circle {
          height: 57px !important;
          width: 57px !important;
          img {
            width: 25px !important;
          }
        }
        .CardCommissions__body {
          justify-content: space-between;
          .item {
            .h1-gradient {
              font-size: 38.19px;
              line-height: 34px;
            }
            &-two {
              display: flex;
            }
          }
        }
        .CardCommissions__head {
          display: flex;
          justify-content: center;
          .sub-title {
            display: flex !important;
            margin-bottom: 0;
            margin-left: 20px;
          }
        }
        .rtl {
          display: none;
        }
      }
    }
  }
  &--dark {
    background-color: #1c0e42;
    .list-items {
      .item {
        p {
          color: #FFFFFF !important;
        }
      }
    }
    .CardTotals__content {
      border-bottom: 1px solid #4B3A7C;
      .divider {
        background-color: #F7F7F7;
      }
    }
    .types {
      button {
        border: 1px solid #F7F7F7 !important;
        background-color: #1C0E42 !important;
        svg {
          filter: brightness(100) !important;
        }
      }
      .btn-active {
        background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%) !important;
        border: none !important;
      }
    }
    .range-date {
      border: 1px solid #f7f7f7 !important;
      .ant-picker.ant-picker-range {
        background-color: #1C0E42 !important;
      }
      input {
        &::placeholder {
          color: #FFFFFF !important;
        }
        color: #FFFFFF !important;
      }
    }
    .container,
    .HeaderFilters,
    .CardCommissions,
    .CardTotals,
    .CardBranch {
      background-color: #1c0e42;
    }
    .CardBranch {
      border: 1px solid #35D8D0;
      .h2 {
        color: #FFFFFF !important;
      }
      .btn-tab {
        color: #00DBD0 !important;
      }
    }
    .LongGraph tspan, title {
      color: #FFFFFF !important;
      opacity: 1;
      filter: brightness(5);
    }
    .loader {
      p {
        color: #FFFFFF !important;
      }
    }
    .HeaderFilters,
    .CardCommissions,
    .CardTotals {
      .content-filters {
				.btn {
					background-color: #1c0e42;
					border: 1px solid #F7F7F7;
					color: #FFFFFF;
					&--active {
						border: 0px solid #F7F7F7;
					}
				}
			}
      border: 1px solid #35D8D0;
      .h2, .h3, .h1-mobile, .body-bold {
        color: #FFFFFF;
      }
      .tabs-tab {
        color: #35D8D0 !important;
      }
      .row {
        .filters {
          .tab {
            background: #1C0E42;
            border: 1px solid #F7F7F7;
            color: #FFFFFF;
            img {
              filter: brightness(200);
            }
            &--active {
              background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
              border-radius: 5px;
              color: #ffffff;
              border: none !important;
            }
          }
        }
      }
    }
    .CardCommissions__head {
      .sub-title {
        color: #FFFFFF;
      }
    }
    .CardTotals {
      &__head, .row {
        border-color: #35D8D0;
      }
      .row-data:last-child {
        border-left: 1px solid #35D8D0;
      }
    }
    @media (max-width: $medium) {
      .slick-dots li.slick-active button:before {
        background: #35D8D0 !important;
      }
      .slick-dots li button:before {
        border: 1px solid #35D8D0 !important;
      }
    }
  }
  .caroucel-res {
    display: none;
    margin-bottom: 20px;
  }
	@media (max-width: $medium) {
    padding-top: 20px;
    padding-bottom: 150px;
    .CardCommissions {
      &__head {
        justify-content: center;
      }
      &--branch {
        .item-two {
          display: flex !important;
        }
        .h1-gradient {
          font-size: 30px !important;
        }
        .CardCommissions__body {
          flex-direction: column;
          padding: 10px;
        }
        .sub-title {
          display: flex;
          margin-left: 10px;
          margin-bottom: 0;
        }
        .rtl {
          display: none;
        }
      }
    }
    .slide-active {
      .slick-slide.slick-active.slick-current {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .CardCommissions {
        margin: 0 auto;
        margin-top: 15px;
      }
    }
    .caroucel-res {
      display: block;
      padding: 0 20px;
      padding-right: 0;
    }
    .slick-slide.slick-active.slick-current {
      margin-left: 95px;
      margin-right: -10px;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 0px;
      height: 0px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
    .slick-dots li button:before {
      font-family: "slick";
      font-size: 0px;
      line-height: 20px;
      position: absolute;
      top: 10px;
      left: 0;
      width: 8px;
      height: 8px;
      content: "•";
      text-align: center;
      border: 1px solid var(--colors-litberry);
      opacity: 1;
      color: black;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border-radius: 50%;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: black;
      background: var(--colors-litberry);
    }
    .CardCommissions {
      margin-top: 15px;
      width: 256.5px !important;
      height: 145px !important;
    }
    .container {
      flex-direction: column;
      padding: 0;
     
      .column {
        width: 100%;
        .row {
          &--head {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .h2 {
              margin-bottom: 20px;
            }
            .filters {
              overflow-x: auto;
              max-width: 100%;
              justify-content: inherit;
              padding-bottom: 10px;
            }
          }
        }
      }
      .content-grid {
        display: none;
      }
      .CardTotals {
        position: relative;
        .chartPoint {
          margin-left: -5px;
          padding: 0;
        }
        &__head {
          .h1-gradient {
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
          }
          .small-detail {
            margin-bottom: 5px;
          }
        }
        .row {
          &--absolut {
            // position: absolute;
            top: 18px;
            width: 60%;
            right: 0;
            flex-direction: column;
            border-bottom: 0;
            .row-item {
              margin-left: 73px;
              margin-bottom: 10px;
            }
            .circle {
              &--insure {
                // margin-left: -42px;
                // margin-bottom: -30px;
              }
            }
          }
        }
      }
    }
  }
  .messaje-empty {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    .leaf {
      width: 100px;
    }
    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 105%;
      background: linear-gradient(90deg, #00DBD0 0.01%, #7B3BF9 99.98%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

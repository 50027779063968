.products {
  position: absolute;
  top: 457px;
  z-index: 2;
  left: 0;
  padding: 22px 23px 10px 24px;
  width: 100%;

  .content-transparent {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(74, 58, 124, 0.308),
      rgba(74, 58, 124, 0.301)
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    border-radius: 10px;
  }

  &__card {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    .ant-card-body {
      padding: 36px;
    }

    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 26px;

      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
      }

      .ant-btn {
        padding: 0;
      }
    }

    &Layout-one {
      display: flex;
      flex-direction: row;

      .image-big {
        height: 221px;
        position: relative;
        border-radius: 10px;
        display: flex;
        margin-right: 17px;

        &__background {
          width: 428px;
          height: 221px;
          position: absolute;
          z-index: 1;
          border-radius: 10px;
        }
        &__content {
          z-index: 99;
          width: 428px;
          height: 221px;
          display: flex;
          padding: 35px 0 0 27px;
          flex-direction: column;
          background: linear-gradient(
            0deg,
            rgba(74, 58, 124, 0.308),
            rgba(74, 58, 124, 0.301)
          );
          border-radius: 10px;

          &Title {
            height: 15%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h5 {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 150%;
              color: var(--colors-white);
              margin: 0 10px 0 0;
            }
          }

          &Info {
            width: 250px;
            height: 55%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              color: var(--colors-white);
              margin: 0;
            }
          }

          &Footer {
            width: 132px;
            height: 40%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              font-family: Lato;
              font-style: normal;
              font-weight: bolder;
              font-size: 10px;
              line-height: 130.69%;
              color: var(--colors-white);
              text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      &__small {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        .image-small {
          height: 105px;
          position: relative;
          border-radius: 10px;
          display: flex;
          margin-right: 7px;
          margin-bottom: 9px;

          &__background {
            width: 124px;
            height: 105px;
            position: absolute;
            z-index: 1;
            border-radius: 10px;
          }
          &__content {
            z-index: 99;
            width: 124px;
            height: 105px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            background: linear-gradient(
              0deg,
              rgba(74, 58, 124, 0.308),
              rgba(74, 58, 124, 0.301)
            );
            border-radius: 10px;
            padding: 8px 11px;

            img {
              width: 13px;
              height: 11px;
              margin-bottom: 6px;
            }

            h5 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 150.19%;
              color: var(--colors-white);
              margin: 0;
              position: relative;
              z-index: 99;
            }
          }
        }
      }
    }

    &Layout-two {
      display: flex;
      flex-direction: column;

      .image-big {
        width: 100%;
        height: 140px;
        position: relative;
        border-radius: 10px;
        display: flex;
        height: 169px;
        margin-right: 17px;

        &__background {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          z-index: 1;
          border-radius: 10px;
        }
        &__content {
          z-index: 99;
          width: 100%;
          height: 100%;
          display: flex;
          object-fit: cover;
          padding: 5px 0 0 10px;
          flex-direction: column;
          background: linear-gradient(
            0deg,
            rgba(74, 58, 124, 0.308),
            rgba(74, 58, 124, 0.301)
          );
          border-radius: 10px;

          &Title {
            height: 30%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h5 {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 150%;
              color: var(--colors-white);
              margin: 0 10px 0 0;
            }
          }

          &Info {
            width: 90%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-top: 15px;
            .see-more {
              display: flex;
              align-items: center;
              img {
                margin-left: 5px;
              }
            }
            .box {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -15px;
              p {
                max-width: 70px !important;
              }
              .left {
                display: flex;
                align-items: center;
                img {
                  width: 27px;
                  margin-left: 20px;
                  height: 27px;
                }
              }
              .right {
                .uber {
                  img {
                    width: 38.48px;
                    height: 13.35px;
                  }
                  display: flex;
                  width: 56.59px;
                  height: 36.03px;
                  align-items: center;
                  justify-content: center;
                  background: var(--colors-black);
                  border-radius: 4.74847px;
                }
                .credit {
                  margin-top: 5px;
                  font-family: Lato;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 11.8712px;
                  line-height: 14px;
                  letter-spacing: -0.02em;
                  color: var(--colors-white);
                  img {
                    margin-left: 5px;
                  }
                }
              }
            }
            p {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 16px;
              color: var(--colors-white);
              margin: 7px 0;
            }
            @media screen and (max-width: 768px) {
              width: auto;
            }
          }

          &Footer {
            width: 132px;
            height: 30%;
            display: flex;
            justify-content: flex-start;

            span {
              font-family: Lato;
              font-style: normal;
              font-weight: bolder;
              font-size: 10px;
              line-height: 130.69%;
              color: var(--colors-white);
              text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      &__small {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        margin-top: 5px;

        .image-small {
          width: 24%;
          height: 135px;
          position: relative;
          border-radius: 10px;
          display: flex;
          margin-bottom: 9px;

          &__background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            z-index: 1;
            border-radius: 10px;
          }
          &__content {
            z-index: 99;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: column;
            background: linear-gradient(
              0deg,
              rgba(74, 58, 124, 0.308),
              rgba(74, 58, 124, 0.301)
            );
            border-radius: 10px;
            padding: 8px 11px;

            img {
              width: 13px;
              height: 11px;
              margin-bottom: 6px;
            }

            h5 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 150.19%;
              color: var(--colors-white);
              margin: 0;
              position: relative;
              z-index: 99;
            }
          }
        }
      }
    }
    .styles-focus {
      .for-one {
        width: 100%;
        .products__cardLayout-two__small {
          display: none;
        }
        .image-big {
          width: 100%;
          height: 285px;
        }
      }
      .for-two {
        .image-big {
          height: 207px !important;
        }
        .img-2,
        .img-3,
        .img-4 {
          display: none;
        }
        .image-small {
          width: 100%;
        }
      }
      .for-three {
        .image-small {
          width: 48%;
        }
        .img-3,
        .img-4 {
          display: none;
        }
      }
      .for-four {
        .image-small {
          width: 32%;
        }
        .img-4 {
          display: none;
        }
      }
      .for-five {
      }
    }

    .styles-multiple {
      flex-direction: row;
      .products__cardLayout-two__small {
        flex-wrap: wrap;
        justify-content: space-around;
        width: 50%;
        .image-small {
          height: 135px;
          min-width: 164px;
          &__background {
            height: 100%;
          }
          &__content {
            height: 100%;
          }
        }
      }
      .image-big {
        width: 50%;
        height: 285px;
        &__background {
          height: 100%;
        }
        &__content {
          height: 100%;
        }
      }
      .for-one {
        display: flex;
        width: 100%;
        .products__cardLayout-two__small {
          display: none;
        }
        .image-big {
          width: 100%;
        }
      }
      .for-two {
        display: flex;
        width: 100%;
        .img-2,
        .img-3,
        .img-4 {
          display: none;
        }
        .image-big {
          width: 65%;
        }
        .image-small {
          height: 100%;
          min-width: 100%;
          width: 100%;
        }
      }
      .for-three {
        display: flex;
        width: 100%;
        .image-small {
          height: 135px;
          min-width: 100%;
          width: 100%;
        }
        .img-3,
        .img-4 {
          display: none;
        }
        .image-big {
          width: 60%;
          height: 285px;
        }
        .products__cardLayout-two__small {
          flex-direction: column;
          width: 40%;
        }
      }
      .for-four {
        display: flex;
        width: 100%;
        .img-4 {
          display: none;
        }
        .image-small {
          height: 30%;
          min-width: 100%;
          width: 100%;
        }
        .products__cardLayout-two__small {
          flex-wrap: wrap;
          justify-content: space-around;
          flex-direction: column;
          width: 40%;
        }
        .image-big {
          width: 60%;
          height: 295px;
        }
      }
      .for-five {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 768px) {
    top: 972px;
    padding-left: 0;
    padding-right: 0;
    &__card {
      .ant-card-body {
        padding: 20px;
      }
      .styles-multiple .products__cardLayout-two__small .image-small {
        height: 22.5%;
        min-width: 160px;
      }
      &Layout-two .image-big {
        margin-right: 5px;
      }
      .Logo_simbolo {
        width: 17px;
        margin-left: 10px;
        height: 17px;
        object-fit: contain;
      }
      .uber {
        width: 46.59px !important;
        height: 26.03px !important;
        img {
          width: 28.48px !important;
          height: 10.35px !important;
          object-fit: contain !important;
        }
      }
      &Layout-two .image-big {
        height: 198px;
      }
    }
  }
}

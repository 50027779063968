.license-responsive {
  margin-bottom: 20px;
  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step {
      width: 49%;
      background: #340AAB;
      height: 5px;
      visibility: visible;
      margin-bottom: 20px;
      opacity: 0.3;
      box-shadow: none !important;
      &:hover {
        background-color: #340AAB;
        border: 0px solid var(--colors-litberry);
        border-radius: 0px;
      }
      &--active {
        opacity: 1;
      }
    }

  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      color: var(--colors-pureberry);
      margin-bottom: 15px;
    }

    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--colors-pureberry);
      opacity: 0.5;
      margin-bottom: 30px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    Button {
      width: 120px;
      height: 50px;
      margin: 0px 10px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      Button {
        width: 49%;
        height: 50px;
        margin: 2.5px 0;
      }
    }
  }
  .licenseType,
  .ant-btn {
    &--selected {
      color: var(--colors-white) !important;
      border: 2px solid var(--colors-litberry);
      background-color: var(--colors-litberry);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
    }

    &--notSelected {
      border: 2px solid var(--colors-litberry);
      color: var(--colors-pureberry);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
    }
  }

  .ant-btn:active,
  .ant-btn:hover,
  .ant-btn:focus {
    border: 2px solid var(--colors-litberry);
    color: var(--colors-white) !important;
    background-color: var(--colors-litberry);
    border-radius: 5px;
  }
}

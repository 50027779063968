.SectionPolicy {
  border-radius: 10px;
  background-color: #ffffff;
  margin-left: 20px;
  padding: 20px;
  min-height: 600px;
  .Policies {
    .desSelect {
      margin-left: 5px;
    }
    .h2 {
      display: none;
    }
    .slick-slider-content .btn-select-caroucel {
      padding: 0 20px;
    }
    .InsurersFilter .box .btn-select-caroucel {
      min-width: auto;
      margin-bottom: 15px !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  }
  .button-transparent {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #5d30dd;
    padding: 0;
    img {
      width: 15px;
      height: 10px;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 5px;

    .body-bold {
      cursor: pointer;
    }
    h2 {
      margin-bottom: 0;
    }
  }
  .list-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    button {
      height: 37px;
      border: 1px solid #5D30DD;
      border-radius: 5px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #5D30DD;
      margin-bottom: 5px;
      text-transform: capitalize;
      svg {
        margin-left: 10px;
        margin-bottom: -2px;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .ant-tabs-tab-btn {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #4b3a7c;
    opacity: 0.5;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    opacity: 1;
    color: #5d30dd;
    text-shadow: 0 0 0.25px currentColor;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 32px;
    margin-left: 0 !important;
    margin-right: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  .ant-tabs-tabpane {
    max-height: 480px;
    overflow: auto;
    padding-top: 10px;
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      border-radius: 3px;
      background: #555;
    }
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: #5d30dd;
    pointer-events: none;
  }
  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-tabs-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .button-seemore {
      background-color: transparent;
      border: none;
      box-shadow: none;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #5D30DD;
    }
  }
  .CardInsurance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    .image {
      min-width: 59.98px;
      margin-right: 5px;
    }
    &_icon {
      max-width: 45px;
    }
    p {
      margin-bottom: 0 !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .image {
      width: 59.98px;
      height: 58px;
      background: #ffffff;
      box-shadow: -5.99798px 4.19858px 13.1955px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 1px 5px rgb(226, 226, 226);
    }
    .ant-btn {
      height: 50px;
      width: 30px;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    &__content {
      width: 190px;
      &__head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .body-bold {
          cursor: pointer;
        }
        img {
          margin-right: 10px;
          width: 12px;
          height: 10.8px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    .body-detail {
      color: #eb5757;
      font-weight: 700;
    }
    .cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      min-width: 50px;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 37px;
        height: 37px;
        background: #f7f7f7;
        border-radius: 50%;
        padding: 0;
        p {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #5d30dd;
          margin-bottom: 0;
        }
      }
      &:hover {
        .filter-absolute {
          display: flex !important;
        }
      }
    }
  }
  .filter-absolute {
    position: absolute;
    width: 151.03px;
    height: 111px;
    background: #1c0e42;
    border-radius: 5px;
    padding: 10px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    top: 20px;
    right: 70px;
    z-index: 100;
    display: none;
    .btn-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      width: 100%;
      color: #ffffff;
    }
    .cilrcle {
      margin-right: 10px;
      background: #ffffff;
      height: 26px;
      width: 26px;
      border-radius: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__responsive {
    display: none;
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 30px;
    }
  }
  @media (max-width: $medium) {
    background-color: transparent;
    background: linear-gradient(164.53deg, #f7f7f7 20.49%, rgba(247, 247, 247, 0) 89.16%);
    &__responsive {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: linear-gradient(268.98deg, #f7f7f7 0.88%, rgba(247, 247, 247, 0) 99.16%);
      .button-transparent {
        height: 40px;
        img {
          width: auto;
          height: auto;
        }
      }
      .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &-item {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 5px;
          &:nth-child(odd) {
            justify-content: flex-start;
          }
          span {
            margin-left: 5px;
          }
          .circle {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 7px;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            background: #ffffff;
          }
        }
      }
      .content-r {
        display: flex;
        padding: 10px;
        background: #ffffff;
        border-radius: 5px;
        width: 100%;
        margin-top: 5px;
        .left {
          width: 90%;
          p {
            margin: 0;
          }
          .sub-title {
            margin-top: 5px;
          }
          .small-detail {
            color: #4b3a7c;
          }
        }
        .rigth {
          width: 10%;
        }
      }
    }
    .loader {
      img {
        width: 40px !important;
        height: 40px !important;
      }
      p {
        display: none !important;
      }
    }
  }
}

.insuranceCarrier-responsive {
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.02em;
      margin-bottom: 5px;
    }

    p {
      font-family: Roboto;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: var(--colors-black);
      opacity: 0.5;
      margin: 0;
    }
  }
  &__wrapper {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Buttons {
      display: block;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .insuranceCarrier,
      .ant-btn {
        width: 117.72px;
        height: 50px;
        margin: 12px;
        &--active {
          color: var(--colors-white) !important;
          border: 2px solid var(--colors-litberry);
          background-color: var(--colors-litberry);
          border-radius: 5px;
        }

        &--disabled {
          border: 2px solid var(--colors-litberry);
          color: var(--colors-black);
          border-radius: 5px;
        }
      }
      .ant-btn:active,
      .ant-btn:hover,
      .ant-btn:focus {
        border: 2px solid var(--colors-litberry);
        background-color: var(--colors-litberry);
        border-radius: 5px;
        
      }
      
    }
  }
}

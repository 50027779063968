@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?66th0l");
  src: url("./fonts/icomoon.eot?66th0l#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?66th0l") format("truetype"),
    url("./fonts/icomoon.woff?66th0l") format("woff"),
    url("./fonts/icomoon.svg?66th0l#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 22px;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    color: var(--colors-litberry);
  }
}

.icon-google:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-link:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-add:before {
  content: "\e905";
}
.icon-filter:before {
  content: "\e907";
}
.icon-arrow-right-bold:before {
  content: "\e908";
}
.icon-facebook-login:before {
  content: "\e909";
}
.icon-dropdown:before {
  content: "\e90a";
}
.icon-italic-format:before {
  content: "\e90b";
}
.icon-add-2:before {
  content: "\e90c";
}
.icon-up:before {
  content: "\e90d";
}
.icon-arrow-right:before {
  content: "\e90e";
}
.icon-obsequies:before {
  content: "\e90f";
}
.icon-number-format:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-google-login:before {
  content: "\e912";
}
.icon-bold-format:before {
  content: "\e913";
}
.icon-rc-med:before {
  content: "\e914";
}
.icon-life:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e916";
}
.icon-bail:before {
  content: "\e917";
}
.icon-location:before {
  content: "\e918";
}
.icon-badge:before {
  content: "\e919";
}
.icon-dashboard:before {
  content: "\e91a";
}
.icon-sidemenu:before {
  content: "\e91b";
}
.icon-copy:before {
  content: "\e91c";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-videocall:before {
  content: "\e91e";
}
.icon-user:before {
  content: "\e91f";
}
.icon-tutorial:before {
  content: "\e920";
}
.icon-subtract:before {
  content: "\e921";
}
.icon-facebook-border:before {
  content: "\e922";
}
.icon-headband:before {
  content: "\e923";
}
.icon-leaf-berry:before {
  content: "\e924";
}
.icon-menu:before {
  content: "\e925";
}
.icon-qualify-bold:before {
  content: "\e926";
}
.icon-notification:before {
  content: "\e927";
}
.icon-luggage:before {
  content: "\e928";
}
.icon-error:before {
  content: "\e929";
}
.icon-logout:before {
  content: "\e92a";
}
.icon-world:before {
  content: "\e92b";
}
.icon-emoticon:before {
  content: "\e92c";
}
.icon-verified .path1:before {
  content: "\e92d";
  color: rgb(75, 58, 124);
}
.icon-verified .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-linkedin:before {
  content: "\e92f";
}
.icon-Marketplace:before {
  content: "\e930";
}
.icon-qualify:before {
  content: "\e931";
}
.icon-tooltip:before {
  content: "\e932";
}
.icon-videocall-2:before {
  content: "\e933";
}
.icon-warning:before {
  content: "\e934";
}
.icon-visible:before {
  content: "\e935";
}
.icon-point-star:before {
  content: "\e936";
}
.icon-home:before {
  content: "\e937";
}
.icon-phone:before {
  content: "\e938";
}
.icon-email:before {
  content: "\e939";
}
.icon-Link:before {
  content: "\e93a";
}
.icon-scroll:before {
  content: "\e93b";
}
.icon-education:before {
  content: "\e93c";
}
.icon-back:before {
  content: "\e93d";
}
.icon-back-2:before {
  content: "\e93e";
}
.icon-civil-liability:before {
  content: "\e93f";
}
.icon-layout:before {
  content: "\e940";
}
.icon-bullet-format:before {
  content: "\e941";
}
.icon-report:before {
  content: "\e942";
}
.icon-delete:before {
  content: "\e943";
}
.icon-share:before {
  content: "\e944";
}
.icon-wait:before {
  content: "\e945";
}
.icon-investment:before {
  content: "\e946";
}
.icon-close:before {
  content: "\e947";
}
.icon-close-2:before {
  content: "\e948";
}
.icon-health:before {
  content: "\e949";
}
.icon-odontology:before {
  content: "\e94a";
}
.icon-Twitter:before {
  content: "\e94b";
}
.icon-note:before {
  content: "\e94c";
}
.icon-attach:before {
  content: "\e94d";
}
.icon-code:before {
  content: "\e94e";
}
.icon-car:before {
  content: "\e94f";
}
.icon-whatsapp:before {
  content: "\e950";
}
.icon-eye:before {
  content: "\e951";
}
.icon-user-2:before {
  content: "\e952";
  color: #b2b2b2;
}
.icon-image:before {
  content: "\e953";
  color: #b2b2b2;
}
.icon-password:before {
  content: "\e954";
  color: #b2b2b2;
}
.icon-warning-2:before {
  content: "\e955";
}
.icon-strikethrough-format:before {
  content: "\e956";
}
.icon-save .path1:before {
  content: "\e957";
  color: rgb(93, 48, 221);
}
.icon-save .path2:before {
  content: "\e958";
  margin-left: -1.095703125em;
  color: rgb(242, 201, 76);
}
.icon-save .path3:before {
  content: "\e959";
  margin-left: -1.095703125em;
  color: rgb(255, 255, 255);
}
.icon-accidents:before {
  content: "\e95a";
}
.icon-save-2 .path1:before {
  content: "\e95b";
  color: rgb(93, 48, 221);
}
.icon-save-2 .path2:before {
  content: "\e95c";
  margin-left: -1.0478515625em;
  color: rgb(111, 207, 151);
}
.icon-save-2 .path3:before {
  content: "\e95d";
  margin-left: -1.0478515625em;
  color: rgb(255, 255, 255);
}
.icon-settings:before {
  content: "\e95e";
}
.icon-share-2:before {
  content: "\e95f";
}
.icon-ready:before {
  content: "\e960";
}
.icon-travel:before {
  content: "\e961";
}
.icon-instagram:before {
  content: "\e962";
}
.icon-adwords:before {
  content: "\e963";
}
.icon-invisible:before {
  content: "\e964";
}
.icon-rocket:before {
  content: "\e965";
}
.icon-family:before {
  content: "\e966";
}
.icon-viral:before {
  content: "\e967";
}
.icon-calc:before {
  content: "\e968";
}
.icon-9--5000Resultados-de-traduccinUnemployment-:before {
  content: "\e969";
}
.icon-pet:before {
  content: "\e96a";
}
.icon-leaf-halftone:before {
  content: "\e96b";
}

.belongAssociation-responsive {
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.02em;
      margin-bottom: 5px;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    Button {
      width: 120px;
      height: 84px;
      margin: 0px 10px;
      
    }

    .active {
      color: var(--colors-white) !important;
      border: 2px solid var(--colors-litberry);
      background-color: var(--colors-litberry);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
    }

    .disabled {
      border: 2px solid var(--colors-litberry);
      color: var(--colors-black);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
    }
    .ant-btn:active,
    .ant-btn:hover,
    .ant-btn:focus {
      border: 2px solid var(--colors-litberry);
      color: var(--colors-white) !important;
      background-color: var(--colors-litberry);
      border-radius: 5px;
    }
  }
}

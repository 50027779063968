.input-custom {
	font-family: "Lato";
	border-radius: font-size($border-radius);
	block-size: font-size($input-height);
	border: 1px solid $input-line;
	color: var(--colors-pureberry);
	&:focus {
		border: 1px solid var(--colors-midberry);
	}
	&--error {
		border: 1px solid var(--colors-badberry);
		box-shadow: 0px 0px 4px 1px var(--colors-badberry);
		color: var(--colors-badberry);
	}
	.input-custom .ant-input-prefix  {
		margin-right: 13px;
	}
	.ant-input-affix-wrapper>input.ant-input {
		padding: 0;
		border: none;
		outline: none;
	}
	input {
		font-family: $input-font-family;
		font-style: normal;
		font-weight: $input-font-weight;
		font-size: font-size($input-font-size);
		line-height: $input-line-height;
	}
	.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input-affix-wrapper:hover, .ant-form-item-has-error .ant-input:hover {
		border: 1px solid var(--colors-badberry);
		box-shadow: 0px 0px 4px 1px var(--colors-badberry);
		color: var(--colors-badberry);
		.ant-form-item-has-error .ant-input-prefix {
			color: var(--colors-badberry);
		}
	}
	.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
    color: var(--colors-badberry);
	}
}  

.ant-select {
	&-arrow {
    color: var(--colors-pureberry);
	}
	&-selector {
		border: 1px solid $input-line;
		border-radius: font-size($border-radius);
		height: font-size($input-height);
	}
}

.ant-picker {
	width: 100%;
	border: 1px solid #bdbdbd;
	box-sizing: border-box;
	border-radius: 5px;
	height: 50px;
	&-focused {
		border: 1px solid var(--colors-midberry);
	}
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding: font-size(8) 0;
	color: var(--colors-pureberry);
}
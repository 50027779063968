.leads-details {
  width: 100%;
  min-height: 100vh;
  &__modal {
    .ant-modal-header {
      display: none;
      @media (max-width: $medium) {
        display: flex;
      }
    }
  }
  &__modal .ant-modal {
    &-content {
      height: 100%;
    }

    &-body {
      background: #f8f8f8;
      height: 100%;
      padding: 0;
    }

    &-footer {
      display: none;
    }

    &-close-x {
      display: none;
    }
  }

  &__header {
    background: var(--colors-midberry);
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn {
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    height: 95%;
    padding: 0 4px 42px 9px;

    &Sidebar {
      width: 360px;
      height: 540px;
      .button-history {
        display: none;
        @media (max-width: $medium) {
          display: flex;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #ffffff;
          background: #5d30dd;
          border: none;
          width: 133px;
          height: 50px;
          border-radius: 5px !important;
					margin-top: 20px;
					margin-left: 20px;
					justify-content: center;
					align-items: center;
        }
      }
    }
    &Container {
      width: calc(100% - 360px);
      height: 100%;
      padding-left: 21px;
    }
    @media (max-width: $medium) {
      padding: 0;
      &Sidebar {
        height: auto;
      }
    }
  }
}

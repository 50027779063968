.checkEmail {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__card {
    padding: 15px;
    width: 60%;
    height: 60%;
    background: rgba($color: #444444, $alpha: 0.2);
    border-radius: 20px;

    &Wrapper {
      height: 100%;

      &Title {
        height: 20%;
        text-align: center;
      }

      &Content {
        height: 40%;
        padding: 0px 100px;
        text-align: justify;
      }

      &Footer {
        height: 35%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
    }
  }
}

.ranking {
  padding: 0px 23px 10px 24px;
  padding-left: 0;
  display: flex;
  position: sticky;
  top: 20px;
  font-family: Roboto;

  &-page-actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .btn-custom--preview {
      width: 50%;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      &--disabled {
        button {
          background: white;
          color: var(--colors-litberry) !important;
          width: 100%;
          height: 100%;
          border-color: white;
        }
      }
    }

    .btn-custom--secundary {
      width: 50%;
    }
  }

  .information-button {
    background: var(--colors-litberry);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--colors-white);
  }
  &-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--colors-backberry) !important;
    box-shadow: none;
    border: none !important;

    .ant-card-body {
      padding: 0;
      width: 100%;
    }

    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        margin-bottom: 19px;
      }
    }

    &__level {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: var(--colors-white);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      margin-bottom: 29px;
      padding: 35px 26px;

      h5 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-litberry);
        margin: 0;
      }
      &Wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 21px;
        justify-content: space-around;

        &__ranking {
          text {
            font-family: Lato;
            font-weight: 700;
            font-style: normal;
          }
        }

        &__text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 8px;
            line-height: 130.69%;
            color: var(--colors-black);
            opacity: 0.5;
          }

          .level {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 12px 0;
            img {
              margin-right: 8px;
            }
            p {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 150%;
              color: var(--colors-pureberry);
              margin: 0;
            }
          }
        }
      }

      &Info {
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
          color: var(--colors-black);
          opacity: 0.35;
          width: 80%;
          margin-top: 12px;
          margin-bottom: 25px;
        }
      }

      &Tips {
        .tip {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          border-bottom: 0.2px solid rgba(0, 0, 0, 0.11);
          margin-bottom: 15px;
          margin-right: 50px;

          img {
            margin: 4px 11px 0 0;
          }

          &__info {
            h6 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 150.19%;
              color: var(--colors-black);
              opacity: 0.65;
              margin: 0;
            }
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 15px;
              color: var(--colors-black);
              opacity: 0.45;
              margin-bottom: 15px;
            }
          }
        }

        .last-tip {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 25px;

          img {
            margin: 4px 11px 0 0;
          }

          &__info {
            h6 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 150.19%;
              color: var(--colors-black);
              opacity: 0.65;
              margin: 0;
            }
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 15px;
              color: var(--colors-black);
              opacity: 0.45;
              margin: 0;
            }
          }
        }
      }
    }

    &__complete {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 157px;
      background: var(--colors-white);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      &Wrapper {
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: row;
        padding: 26px 35px;

        &__text {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h5 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: var(--colors-litberry);
            margin: 0;
          }

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 8px;
            line-height: 130.69%;
            color: var(--colors-black);
            opacity: 0.5;
          }
        }
      }

      margin-bottom: 20px;
      &Go {
        width: 100%;
        height: 30%;
        button {
          width: 100%;
          height: 100%;
          background: var(--colors-frozenberry);
          border: none;
          border-radius: 0 0 10px 10px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: var(--colors-white);
        }
      }
    }

    &__items {
      margin: 35px 0px;
      &Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h6 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: var(--colors-pureberry);
          width: 70%;
          margin: 0 9px;
        }

        button {
          width: 94.61px;
          min-width: 30%;
          height: 40.87px;
          background: var(--colors-litberry);
          border-radius: 5px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
          border: none;
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: var(--colors-frozenberry);
      }

      button {
        border-radius: 50%;
        width: 60px;
        background: white;
        border: none;
        top: 95%;
        height: 60px;
        position: absolute;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 !important;
    margin-top: 25px;
  }
}

.editAgentPage {
  max-width: 100%;
  width: 100%;

  @media only screen and (max-width: $large) {
    height: 100%;
  }

  &__onboardingWrapper {
    width: 100%;

    &--mobile {
      display: none;
    }

    &--desktop {
      display: initial;
    }

    @media only screen and (max-width: $large) {
      &--mobile {
        display: initial;
      }

      &--desktop {
        display: none;
      }
    }
  }

  &__mainAgentInfoWrapper {
    width: 100%;
    padding-top: 0px;
    background: #f7f7f7;
  }

  &__spinnerWrapper {
    width: 100%;
  }
}

.TypeQuarter {
	display: flex;
	justify-content: space-between;
	.caroucel-item {
		display: flex !important;
		justify-content: center;
	}
	.ShareButton {
		display: flex !important;
	}
  &__view {
		display: block;
		width: 70%;
		padding: 30px;
		background: var(--colors-white);
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		.cont-card {
			width: 100%;
			max-width: 259px;
			.card {
				width: 259px;
				&__background {
					object-fit: cover;
				}
			}
		}
	}
	&__recommendation {
		width: 25%;
		padding: 30px 0;
		display: block;
		background: var(--colors-white);
		border-radius: 10px;
		box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
		.cont-card {
			width: 100%;
			max-width: 259px;
			.card {
				width: 259px;
				p {
					width: 0px;
				}
				&__background {
					object-fit: cover;
				}
				.modal-meneric-editable {
					display: none;
				}
				img{
					max-width: 17px;
				}
			}
		}
	}
}
.TypeOneMedium {
  display: block;
  .caroucel-item {
      display: flex !important;
      justify-content: center;
      .draw__backdrop , .draw{
        display: none;
      }
  }
  .btns {
    min-height: 70px;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 0px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    content: "•";
    text-align: center;
    border: 1px solid var(--colors-litberry);
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: black;
    background: var(--colors-litberry);
}
  .card {
    .ShareButton {
      .icons {
        background-color: #f5f5f57d;
        border-radius: 5px !important;
      }
    }
  }
}
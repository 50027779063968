$small: 440px;
$s-medium: 570px;
$medium: 768px;
$large: 960px;
$x-large: 1060px;
$xx-large: 1250px;

$white: #ffffff;
$black: #000000;
$transparent: transparent;


// ----------------------------------------------------------------
//Font base
$rem-value: 16 !default;
$unit: rem !default;

$theme-color: rebeccapurple;
$desisable: #b2b2b2;
$input-line: #bdbdbd;

$colors-12: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%,#7F14FC 56.11%);
$colors-litberry: #5d30dd !default;
$colors-midberry: #340aab !default;
$colors-frozenberry: #35d8d0 !default;
$colors-backberry: #f6f6f6 !default;
$colors-pureberry: #4B3A7C !default;
$colors-coldberry: #6171ae !default;
$colors-darkberry: #1c0e42 !default;
$colors-warningberry: #fffaed;
$colors-smoothieberry: linear-gradient(0deg, #00dbd0 0%, #7b3bf9 100%);
$colors-hotberry: linear-gradient(274deg, #5d30ddab 0%, #7f14fc 100%);
$colors-gradient: linear-gradient(0deg, #5d33e4 0%, #08cdd2 100%);
$colors-black: #1b1b1b;
$colors-white: #ffffff;
$colors-highlight-azul: #4814dc;
$colors-whatsapp: #25d366;
$colors-oldberry: #b2b2b2;
$colors-badberry: #eb5757;
$colors-gradient-image: linear-gradient(0deg, rgba(74, 58, 124, 0.308), rgba(74, 58, 124, 0.301));

$font-families-font-lato: Lato;
$font-families-font-roboto: Roboto;
$line-heights-0: AUTO;
$line-heights-1: 150.5%;
$line-heights-2: 150.19%;
$line-heights-3: 150%;
$font-weights-font-lato-0: Black;
$font-weights-font-lato-1: Bold;
$font-weights-font-roboto-2: Bold;
$font-weights-font-roboto-3: Regular;
$font-weights-font-lato-4: Regular;
$font-sizes-0: 14;
$font-sizes-1: 15;
$font-sizes-2: 16;
$font-sizes-3: 18;
$font-sizes-4: 20;
$font-sizes-5: 25;
$font-sizes-6: 28;
$font-sizes-7: 30;
$font-sizes-8: 50;
$letter-spacing-0: 0%;
$letter-spacing-1: -2%;
$paragraph-spacing-0: 0;




$typography-headings-font-weight: Bold;
$typography-headings-font-style: normal;
$typography-headings-line-height: auto;

$typography-body-size: 16;
$typography-body-size-tablet: 16;
$typography-body-size-mobile: 16;

$typography-h-1-font-family: Lato;
$typography-h-1-font-weight: Black;
$typography-h-1-line-height: AUTO;
$typography-h-1-font-size: 50;
$typography-h-1-letter-spacing: 0%;
$typography-h-1-paragraph-spacing: 0;
$typography-h-2-font-family: Lato;
$typography-h-2-font-weight: Black;
$typography-h-2-line-height: AUTO;
$typography-h-2-font-size: 30;
$typography-h-2-letter-spacing: 0%;
$typography-h-2-paragraph-spacing: 0;
$typography-h-3-font-family: Lato;
$typography-h-3-font-weight: Bold;
$typography-h-3-line-height: AUTO;
$typography-h-3-font-size: 28;
$typography-h-3-letter-spacing: 0%;
$typography-h-3-paragraph-spacing: 0;
$typography-sub-title-font-family: Lato;
$typography-sub-title-font-weight: Bold;
$typography-sub-title-line-height: normal;
$typography-sub-title-font-size: 20;
$typography-sub-title-letter-spacing: -2%;
$typography-sub-title-paragraph-spacing: 0;
$typography-small-detail-font-family: Lato;
$typography-small-detail-font-weight: Bold;
$typography-small-detail-line-height: 150.5%;
$typography-small-detail-font-size: 18;
$typography-small-detail-letter-spacing: 0%;
$typography-small-detail-paragraph-spacing: 0;
$typography-small-detail-font-style: normal;
$typography-button-txt-font-family: Lato;
$typography-button-txt-font-style: normal;
$typography-button-txt-font-weight: Bold;
$typography-button-txt-line-height: 1.2em;
$typography-button-txt-font-size: 14;
$typography-button-txt-letter-spacing: 0%;
$typography-button-txt-paragraph-spacing: 0;
$typography-body-bold-font-family: Roboto;
$typography-body-bold-font-weight: Bold;
$typography-body-bold-line-height: 150.19%;
$typography-body-bold-font-size: 15;
$typography-body-bold-letter-spacing: 0%;
$typography-body-bold-paragraph-spacing: 0;
$typography-body-bold-font-style: normal;
$typography-body-regular-font-family: Roboto;
$typography-body-regular-font-weight: normal;
$typography-body-regular-line-height: 150%;
$typography-body-regular-font-size: 15;
$typography-body-regular-font-style: normal;
$typography-body-regular-letter-spacing: 0%;
$typography-body-regular-paragraph-spacing: 0;
$typography-mobile-h-1-font-family: Lato;
$typography-mobile-h-1-font-weight: Black;
$typography-mobile-h-1-line-height: AUTO;
$typography-mobile-h-1-font-size: 25;
$typography-mobile-h-1-letter-spacing: 0%;
$typography-mobile-h-1-paragraph-spacing: 0;
$typography-mobile-h-2-font-family: Lato;
$typography-mobile-h-2-font-weight: Bold;
$typography-mobile-h-2-line-height: AUTO;
$typography-mobile-h-2-font-size: 20;
$typography-mobile-h-2-letter-spacing: -2%;
$typography-mobile-h-2-paragraph-spacing: 0;
$typography-mobile-body-bold-font-family: Roboto;
$typography-mobile-body-bold-font-weight: Bold;
$typography-mobile-body-bold-line-height: 150%;
$typography-mobile-body-bold-font-size: 15;
$typography-mobile-body-bold-letter-spacing: 0%;
$typography-mobile-body-bold-paragraph-spacing: 0;
$typography-mobile-body-regular-font-family: Roboto;
$typography-mobile-body-regular-font-weight: Regular;
$typography-mobile-body-regular-line-height: 150%;
$typography-mobile-body-regular-font-size: 15;
$typography-mobile-body-regular-letter-spacing: 0%;
$typography-mobile-body-regular-paragraph-spacing: 0;
$typography-mobile-detail-font-family: Roboto;
$typography-mobile-detail-font-weight: Regular;
$typography-mobile-detail-line-height: 150%;
$typography-mobile-detail-font-size: 18;
$typography-mobile-detail-letter-spacing: 0%;
$typography-mobile-detail-paragraph-spacing: 0;
$typography-blog-mobile-body-font-family: Lato;
$typography-blog-mobile-body-font-weight: Regular;
$typography-blog-mobile-body-line-height: 150%;
$typography-blog-mobile-body-font-size: 16;
$typography-blog-mobile-body-letter-spacing: 0%;
$typography-blog-mobile-body-paragraph-spacing: 0;
$button-size-height: 50;
$button-padding-side: 36;
$border-radius: 5;

$input-height: 50;
$input-font-family: Roboto;
$input-font-style: normal;
$input-font-weight: normal;
$input-font-size: 15;
$input-line-height: 150%;

:root {
  --colors-12: #{$colors-12};
  --colors-litberry: #{$colors-litberry};
  --colors-midberry: #{$colors-midberry};
  --colors-frozenberry: #{$colors-frozenberry};
  --colors-backberry: #{$colors-backberry};
  --colors-pureberry: #{$colors-pureberry};
  --colors-coldberry: #{$colors-coldberry};
  --colors-darkberry: #{$colors-darkberry};
  --colors-warningberry: #{$colors-warningberry};
  --colors-smoothieberry: #{$colors-smoothieberry};
  --colors-hotberry: #{$colors-hotberry};
  --colors-gradient: #{$colors-gradient};
  --colors-black: #{$colors-black};
  --colors-white: #{$colors-white};
  --colors-highlight-azul: #{$colors-highlight-azul};
  --colors-whatsapp: #{$colors-whatsapp};
  --colors-oldberry: #{$colors-oldberry};
  --colors-badberry: #{$colors-badberry};
  --colors-gradient-image: #{$colors-gradient-image};
}
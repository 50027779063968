.product-modal {
  width: 100%;
  height: 100%;
  background: var(--colors-white);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 20px 20px;
  @media (max-width:768px) {
    background-color: #f6f6f6;
  }

  &__header {
    margin: 0px;
    height: 118px;
    background: var(--colors-litberry);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    .button-primary {
      width: 100px !important;
      padding: 0 !important;
      margin-left: 10px !important;
    }
    .button-dow, .button-dow:hover, .button-dow:active, .button-dow:focus {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #5D30DD;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 5px;
        margin-top: -5px;
      }
    }

    &Close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 11px;
      padding-right: 11px;
      height: 5%;
      display: none;
      cursor: pointer;
      .res-close {
        display: none;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }

    &Wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 95%;
      padding: 0 40px;
      .card-img-res {
        display: none;
      }
      .application-res {
        display: none;
      }
      &__category {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        .icon-product {
          max-height: 51px;
          width: auto;
        }
        h2 {
          display: none;
        }
        @media (max-width: 768px) {
          width: 100%;
          padding-left: 45px;
          padding-bottom: 15px;
          margin-bottom: 0 !important;
          background-color: #f6f6f6;
          h2 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            margin-top: 18px;
          }
          .icon-product {
            display: none;
          }
          a {
            display: none;
          }
        }
        a {
          margin-right: 20px;
          margin-top: -24px;
          img {
            width: 26px;
            height: 23px;
          }
        }

        .category {
          width: 67px;
          height: 67px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          background: var(--colors-pureberry);

          i:before {
            color: var(--colors-white);
          }

          .anticon {
            font-size: 20px;
            color: var(--colors-white);
          }
          .background {
            width: 67px;
            height: 67px;
            position: absolute;
            z-index: 1;
          }

          .content {
            z-index: 99;
            width: 26px;
            height: 24px;
          }
        }

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: var(--colors-pureberry);
          margin: 0;
          margin-left: 18px;
        }
      }

      .primary {
        border: none;
        background: var(--colors-litberry);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 5px;
        width: 137px;
        margin-right: 18px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }

      .primary:hover,
      .primary:focus,
      .primary:active {
        border: none;
        padding: 0;
        background: var(--colors-litberry);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 5px;
        width: 137px;
        margin-right: 18px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }
      .secundary {
        border: none;
        background: var(--colors-frozenberry);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        border-radius: 5px;
        width: 211px;
        transition: 0.3s;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
          margin-left: 14px;
        }
        &--active {
          width: 40px !important;
          .span-btn {
            display: none;
          }
        }
      }
      .icons {
        width: 200px;
        height: 40px;
        background: #f5f5f5;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .anticon {
          color: var(--colors-litberry);
          font-size: 16px;
        }
      }

      .secundary:hover,
      .secundary:focus,
      .secundary:active {
        border: none;
        padding: 0;
        background: var(--colors-frozenberry);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        border-radius: 5px;
        width: 211px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
          margin-left: 14px;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    height: 40px;
    margin: 0 10px;
    .tabs {
      background: var(--colors-litberry);
      border: 0.5px solid var(--colors-highlight-azul);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50%;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
      }
    }

    .tabs:hover,
    .tabs:focus,
    .tabs:active {
      background: var(--colors-midberry);
      border: 0.5px solid var(--colors-highlight-azul);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50%;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
        text-decoration: underline;
      }
    }

    .tabs--selected {
      background: var(--colors-midberry);
      border: 0.5px solid var(--colors-highlight-azul);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50%;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
      }
    }

    .tabs--selected:hover,
    .tabs--selected:focus,
    .tabs--selected:active {
      background: var(--colors-midberry);
      border: 0.5px solid var(--colors-highlight-azul);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 50%;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
        text-decoration: underline;
      }
    }
  }

  &__summary {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;

    &Info {
      width: 40%;
      padding: 34px 31px;

      h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 16px;
        color: var(--colors-pureberry);
      }
      a {
        color: var(--colors-litberry);
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        img {
          margin-left: 5px;
        }
      }
      
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin-bottom: 15px;
      }
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin-bottom: 21px;
        overflow: hidden;
        display: -webkit-box;
        margin-top: 16px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      &__items {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &Item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 26px;
          img {
            margin-right: 10px;
            margin-top: 4px;
          }
          span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            color: var(--colors-pureberry);
          }
        }
      }
    }
    &Benefits {
      width: 60%;
      min-height: 400px;
      embed.cont-pdf {
        width: 100% !important;
      }
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin-bottom: 24px;
      }

      &__items {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &Item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-bottom: 26px;
          align-items: flex-start;
          img {
            margin-right: 10px;
            margin-top: 4px;
          }
          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: var(--colors-litberry);
          }
        }
      }
    }
  }

  &__data-sheet {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 180px);

    &Info {
      width: 40%;
      padding: 34px 31px;

      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-white);
        margin-bottom: 15px;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin: 0;
        color: var(--colors-white);
        opacity: 0.75;
        margin-bottom: 21px;
      }
      &__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &Item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 26px;
          img {
            margin-right: 10px;
            margin-top: 4px;
          }
          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: var(--colors-white);
          }
        }
      }

      .ant-btn {
        border: none;
        background: var(--colors-white);
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 5px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-litberry);
          margin-left: 10px;
        }
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        border: none;
        padding: 0 20px;
        background: var(--colors-white);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 5px;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-litberry);
          margin-left: 10px;
        }
      }
    }
    &Benefits {
      width: 60%;
      height: 100%;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;
      padding: 25px 16px;

      .image-information {
        width: 100%;
        height: 160px;
        position: relative;
        border-radius: 10px;
        display: flex;
        margin-bottom: 26px;
        &__background {
          width: 100%;
          height: 160px;
          position: absolute;
          z-index: 1;
          border-radius: 10px;
        }
        &__content {
          z-index: 99;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 9px 18px 0 14px;
          &Text {
            h4 {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 150%;
              color: var(--colors-white);
            }

            p {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 130.69%;
              color: var(--colors-white);
            }

            .sponsor {
              width: 22px;
              height: 22px;
            }
          }

          .share {
            width: 12px;
            height: 12px;
          }
        }
      }

      &__items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &Item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 26px;

          .item-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
            img {
              margin-right: 17px;
            }
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 150%;
              color: var(--colors-pureberry);
              margin: 0;
            }
          }

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: var(--colors-litberry);
          }
        }
      }
    }
  }
  @media (max-width: $medium) {
    &__header {
      margin: 0;
      height: auto;
      &Close {
        img {
          display: none;
        }
        .res-close {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          .anticon {
            color: var(--colors-frozenberry);
            margin: 0 10px;
          }
          h2 {
            margin: 0;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: var(--colors-litberry);
          }
        }
      }
      &Wrapper {
        padding: 0px;
        margin-top: 0px;
        flex-direction: column;
        background-color: #ffffff;
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-radius: 15px;
        .ShareButton {
          position: absolute;
          margin-top: 100px;
          right: 20px;
          z-index: 2;
          img {
            width: 20px !important;
            height: 20px;
            object-fit: contain !important;
          }
          .icons {
            border-radius: 7px !important;
          }
          button,
          button:hover,
          button:active,
          button:focus {
            width: 45px !important;
            background-color: transparent;
            img {
              width: 22px;
              height: 25px;
            }
            span {
              display: none;
            }
          }
        }
        .application-res {
          display: flex;
          justify-content: flex-start;
          padding: 20px;
          padding-bottom: 5px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          width: 100%;
          .circle {
            width: 42.85px;
            height: 42.85px;
            border-radius: 42.85px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--colors-pureberry);
          }
          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-left: 22px;
            max-width: 75%;
            .ant-select {
              width: 100px;
              margin-top: 5px;
              height: 26px;
            }
            .ant-select-arrow {
              margin-top: -10px;
            }
            span.ant-select-selection-item {
              opacity: 1;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 150.19%;
              color: #5d30dd !important;
              margin-bottom: 0;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              position: relative;
              background-color: transparent;
              border: 0px solid #d9d9d9;
              padding: 0;
              border-radius: 2px;
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
            h6 {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 150%;
              color: var(--colors-pureberry);
              margin-bottom: 0px;
            }
            span {
              margin-bottom: 5px;
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 10px;
              line-height: 15px;
              color: #1B1B1B;
              opacity: 0.6;
            }
            p {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              margin-bottom: 0px;
              font-size: 10px;
              line-height: 130.69%;
              color: #000000;
              opacity: 0.3;
            }
          }
        }
        .card-img-res {
          display: flex;
          width: 100%;
          height: 150px;
          position: relative;
          .icon-product {
            position: absolute;
            left: 20px;
            top: 20px;
            z-index: 20;
            width: auto;
            height: 24px;
            left: 20px;
          }
          .gradient {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 1;
            height: 100%;
            background: linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55));
          }
          img {
            object-fit: cover;
            width: 100%;
          }
        }
        &__category {
          margin-bottom: 20px;
        }
        .ant-row {
          flex-flow: nowrap;
          .button-primary {
            display: none;
          }
          .button-dow {
            background: #5D30DD;
            border-radius: 5px;
            color: #fff;
            position: absolute;
            margin-top: 65px !important;
            right: 18px;
            padding-left: 17px !important;
            padding-right: 17px !important;
            img {
              filter: brightness(12.5);
            }
          }
        }
      }
    }
    &__summary {
      flex-direction: column;
      &Info {
        width: 100%;
        padding: 30px 20px;
        h2 {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &Benefits {
        width: 100%;
        height: auto;
        min-height: auto;
        margin-bottom: 20px;
        border-radius: 10px;
      }
    }
  }
  .button-coti {
    display: none;
  }
  @media (max-width: $medium) {
    background-color: #f6f6f6;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    .button-coti {
      display: unset;
      margin-top: 30px !important;
      width: 90%;
    }
    &__header {
      box-shadow: none;
      background-color: transparent;
      .primary {
        position: absolute;
        right: 0px;
        margin-top: 40px;
      }
    }
    &__summary {
      &Info {
        background-color: var(--colors-white);
        border-radius: 15px;
        h2 {
          color: var(--colors-pureberry);
        }
        .primary {
          display: none;
        }
        h4,
        p {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 150%;
          color: var(--colors-pureberry);
          margin-top: 40px;
        }
        &__items {
          span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            color: var(--colors-pureberry);
          }
        }
      }
    }
  }
}

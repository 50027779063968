.information-editing {
  padding: 0px 23px 10px 24px;

  &__cardProfile {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
      width: 100%;
      background: var(--colors-white);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 2px;
      overflow: hidden;

      .ant-card-body {
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
      }

      &__edit {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 20px 22px;
        i {
          font-size: 11px;
          &::before {
            color: var(--colors-white);
          }
        }
				.btn-back {
					display: none;
				}
        a {
          background: var(--colors-litberry);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 21px;
          height: 21px;
        }
      }

      &__upload {
        width: 100%;

        .ant-upload-picture-card-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        .ant-upload.ant-upload-select-picture-card,
        .ant-upload-list-picture-card .ant-upload-list-item {
          width: 120px;
          height: 120px;
          border-radius: 100px;
          margin: 0px;
          padding: 0px;
          border: none;
          background-color: transparent;

          img {
            width: 120px;
            height: 120px;
          }
        }

        .ant-upload-list-item-thumbnail img {
          width: 120px;
          height: 120px;
          border-radius: 100px;
          margin: 0px;
          padding: 0px;
          border: none;
          background-color: transparent;
        }

        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          padding: 0px;
        }
      }

      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-litberry);
        margin-bottom: 20px;
      }
      .bio {
        margin-top: 40px;
      }
      &__form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 40px;

        .ant-form-item-label > label {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: rgba(51, 51, 51, 0.75);
        }
        &Info {
          width: 50%;
          padding: 35px 10px;
        }

        &Social {
          width: 50%;
          padding: 35px 10px;
          .ant-input-group-addon {
            color: #bebebe !important;
          }
          .ant-input-affix-wrapper {
            position: relative;
            display: inline-block;
            width: 100%;
            min-width: 0;
            padding: 0px 0px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 1.5715;
            background-color: transparent;
            background-image: none;
            border: 0px solid #d9d9d9;
            border-radius: 2px;
            transition: all 0.3s;
            display: inline-flex;
            height: 47px;
        }
          .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
            border-color: transparent;
            box-shadow: none;
            border-right-width: 1px !important;
            outline: 0;
          }
          .ant-input-group-addon {
            position: relative;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-weight: normal;
            font-size: 14px;
            padding-left: 5px;
            text-align: center;
            background-color: transparent;
            border: 0px solid #d9d9d9;
            border-radius: 2px;
            transition: all 0.3s;
          }
          .ant-input-suffix {
            margin-left: 4px;
            padding-right: 5px;
          }
        }
      }

      &__biography {
        padding: 0 80px;

        .ant-input {
          background: var(--colors-white);
          border: 1px solid #e5e5e5 !important;
          box-sizing: border-box;
          border-radius: 5px !important;
          color: var(--colors-black) !important;
          font-size: 14px;
          line-height: 1.5715;
        }
      }

      &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 91.31px;
        background: #f7f7f7;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        margin-top: 40px;

        .outline {
          width: 163.72px;
          height: 51px;
          background: var(--colors-white);
          border: 2px solid var(--colors-litberry);
          box-sizing: border-box;
          border-radius: 5px;

          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-black);
          margin-right: 6px;
        }

        .filled {
          width: 163.72px;
          height: 51px;
          background: var(--colors-frozenberry);
          border: 2px solid var(--colors-frozenberry);
          box-sizing: border-box;
          border-radius: 5px;

          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
          margin-left: 6px;
        }
      }
      @media screen and (max-width: 768px) {
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .ant-card-bordered {
    @media screen and (max-width: 768px) {
      border: 1px solid transparent;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    &__cardProfile--desktop {
      &__edit {
				padding: 0 20px;
				justify-content: flex-start;
      }
			.btn-edit {
				display: none;
			}
			.btn-back {
				display: block;
				background-color: transparent;
				img {
					transform: rotate(
						180deg
						);
				}

			}
      &__buttons {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
        padding: 0;
        padding-top: 20px;
        margin-top: 0px;
        box-shadow: none;
        button {
          width: 100% !important;
          margin: 10px 0 !important;
        }
      }
    }
    &__cardProfile--desktop__form {
      flex-direction: column;
      padding: 0 0px;
      &Info {
        width: 100%;
        margin-top: 40px;
        padding: 40px 20px;
        background-color: var(--colors-white);
        border-radius: 10px;
        h4 {
          color: var(--colors-pureberry);
        }
      }
      &Social {
        width: 100%;
        margin-top: 50px;
        background-color: var(--colors-white);
        border-radius: 10px;
        padding: 40px 20px;
        h4 {
          color: var(--colors-pureberry);
        }
      }
    }
  }
}

.edit-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  height: 120px;
  width: 120px;
  border-radius: 100px;

  img {
    position: absolute;
    width: 120px;
    object-fit: cover;
    height: 120px;
    border-radius: 50%;
  }

  &__text {
    background: rgba(52, 10, 171, 0.35);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 20px;
    border-radius: 0 0 100px 100px;

    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--colors-white);
      margin: 0;
    }
  }
}

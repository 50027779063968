.onboarding-offices {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;

  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: var(--colors-litberry);
    margin-bottom: 5px;
    text-align: center;
  }

  p {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: var(--colors-black);
    opacity: 0.5;
    text-align: center;
    margin-bottom: 27px;
  }

  &__tabs {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;

    a {
      img {
        width: 27px;
        height: 23px;
        margin-right: 17px;
      }
    }

    .tab {
      &--active {
        width: 42%;
        height: 3.71px;
        background: var(--colors-midberry);
        margin-right: 5px;
      }
      &--disabled {
        width: 42%;
        height: 3.71px;
        margin-left: 7px;
        background: var(--colors-midberry);
        opacity: 0.3;
      }
    }
  }

  &__offices {
    width: 30%;
  }

  &__add {
    &--offices-open {
      width: 63%;
      max-width: 500px;
      margin-left: 5%;
    }
    &--offices-close {
      width: 100%;
      max-width: 500px;
      margin-right: -10px;
    }
    &--offices-invisible {
      width: 100%;
      max-width: 500px;
      margin-right: 15%;
    }
  }
}
.add-offices {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__tabs {
    margin-left: -50px;
    width: 59%;
    .tab {
      &--active {
        span {
          color: #eb5757;
        }
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-midberry);
        margin-bottom: 20px;
        cursor: pointer;
        width: 100%;
        text-align: start;
        display: flex;
        justify-content: flex-start;
      }
      &--disabled {
        span {
          color: #eb5757;
        }

        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        margin-bottom: 14px;
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 45px;
    width: 100%;

    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
    }

    .ant-form label {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: rgba(51, 51, 51, 0.75);
    }

    .ant-input-number {
      height: 40px;
      background: var(--colors-white);
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      width: 90%;
      padding: 0%;
      .ant-input-number-input {
        height: 40px;
      }
    }
    .ant-input {
      height: 40px;
      background: var(--colors-white);
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      width: 90%;
    }

    .ant-form-item {
      margin-bottom: 16px !important;
    }

    .ant-form {
      width: 80%;
    }

    .ant-form-item-label > label.ant-form-item-required::before {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-right: 0;
      color: rgba(51, 51, 51, 0.75);
    }

    .ant-form-item-has-error .ant-form-item-explain,
    .ant-form-item-has-error .ant-form-item-split {
      margin: -5px 0 !important;
      width: 100%;
    }
  }
}
.offices-products {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-left: 24px;

  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--colors-midberry);
    margin: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 21px 0 42px;
    overflow: auto;

    .product {
      &--active {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        background-color: var(--colors-litberry);
        border: 2px solid var(--colors-litberry);
        box-sizing: border-box;
        border-radius: 5px;
        width: 110px;
        height: 50px;
        min-width: 110px;
        min-height: 50px;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        margin-bottom: 5px;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.02em;
          color: var(--colors-white);
          margin-left: 10px;
        }
      }
      
      &--disabled {
        display: flex;
        flex-direction: row;
        border: 2px solid var(--colors-litberry);
        cursor: pointer;
        box-sizing: border-box;
        border-radius: 5px;
        width: 110px;
        height: 50px;
        min-width: 110px;
        min-height: 50px;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        margin-bottom: 5px;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.02em;
          color: var(--colors-litberry);
          margin-left: 10px;
        }
      }
    }

    &::-webkit-scrollbar {
      background: #5f5f5f2c;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #5f5f5f3b;
    }
  }
}

.MonthlySummary {
  padding: 20px 0;
  transition: 0.7s;
  .title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 2000px;
    line-height: 36px;
    color: var(--colors-pureberry);
  }

  &--darck {
    margin-top: 20px;
    margin-bottom: 20px;
    .title {
      background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 15.97%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .slick-dots {
      li.slick-active button:before {
        opacity: 1;
        background-color: #00dbd0 !important;
      }
      li button:before {
        border: 1px solid #00dbd0 !important;
      }
    }
    .MonthlySummary__content__card,
    .card {
      background: #1c0e42 !important;
      border: 1px solid #f7f7f7;
      box-sizing: border-box;
      border-radius: 10px;
      .button {
        color: #1c0e42 !important;
      }
      .sub-title,
      .small-detail {
        color: #ffffff !important;
      }
      .cant {
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .btn {
      .anticon {
        color: #35d8d0 !important;
      }
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .b-rtl {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .content-button {
        margin-left: 5px;
      }
    }
    .content-button {
      button {
        padding: 0 !important;
        border: none;
        width: 136.6px;
        height: 37px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: none;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5d30dd;
        &:hover,
        &:active,
        &:focus {
          color: #5d30dd;
          background-color: #ffffff;
          border: none;
        }
        .filter-green {
          display: none;
        }
        .filter {
          width: 15px;
          height: 10px;
          object-fit: contain;
          box-shadow: none;
          margin-top: -2px;
          margin-left: 10px;
        }
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &--filter {
      background-color: transparent !important;
      border: none !important;
      display: none !important;
      margin-top: -10px;
      img {
        width: 15px;
        height: 9.6px;
      }
      @media (max-width: 768px) {
        display: block !important;
      }
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .btn {
      border: none;
      background-color: transparent;
      padding: 0 !important;
      &:hover,
      &:active,
      &:focus {
        border: none;
        background-color: transparent;
      }
      .anticon {
        color: #7f7f7f;
        font-size: 20px;
      }
    }
  }
  &__container,
  .slider {
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    margin-top: 15px;
    padding: 20px;
    height: 270px;
    .sub-title {
      margin-bottom: 15px;
    }
    .card {
      width: 100%;
      height: 181.51px;
      background: #1c0e42;
      border-radius: 5px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;
      .text {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        color: #35d8d0;
        margin-top: 15px;
        margin-bottom: 10px;
        span {
          color: #ffffff;
        }
      }
      h3 {
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        color: #eb5757;
        margin-bottom: 0;
      }
    }
    &__cards {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
  .slider {
    display: none;
  }
  &__box {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      .MonthlySummary__container {
        width: 100%;
        margin-bottom: 15px;
        .slider {
          display: block;
          padding: 20px;
          height: auto;
          width: 100%;
          .slick-slide {
            display: block;
            padding: 1px;
          }
          .slick-dots {
            li.slick-active button:before {
              opacity: 1;
              background-color: #5d30dd;
            }
            li button:before {
              font-family: "slick";
              font-size: 0px;
              line-height: 20px;
              position: absolute;
              top: 10px;
              background: transparent;
              border: 1px solid #5d30dd;
              left: 0;
              border-radius: 50%;
              width: 8px;
              height: 8px;
              content: "•";
              text-align: center;
              opacity: 1;
              color: black;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
        }
        height: auto;
        &__cards {
          display: none;
        }
      }
      .MonthlySummary__content {
        width: 100%;
      }
    }
  }
  &__content {
    margin-top: 15px;
    margin-left: 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    &__card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 48%;
      height: 160px;
      padding: 20px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 15px;
      
      @media (max-width: $s-medium) {
        width: 100%;
      }

      .sub-title {
        margin-bottom: 10px;
      }

      .numbers {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        .cant {
          font-family: Lato;
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 48px;
          color: #5d30dd;
          margin-bottom: 10px;
        }

        .small-detail {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: var(--colors-pureberry);
          margin-bottom: 0;
        }
      }

      .button {
        height: 50px !important;
        padding: 20px !important;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

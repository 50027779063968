.ThanksUpClient {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
  .box-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-icon-back {
    position: absolute;
    top: 15px;
    left: 15px;
    background: transparent;
    border: none;
  }
  .message,
  .box-img {
    width: 50%;
    padding: 100px 50px;
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      color: var(--colors-pureberry);
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
      margin: 30px 0;
    }

    .clock {
      width: 237px;
      height: 237px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 30px 10px;
    .message,
    .box-img {
      padding: 0;
      justify-content: flex-start;
      width: 100%;
      margin: 10px 0;
      .clock {
        width: 115px;
        height: 115px;
      }
      .button {
        width: 100% !important;
        min-width: 100%;
      }
    }
  }
}

.modal-meneric-editable {
  .open_modal,
  .open_modal:hover,
  .open_modal:active,
  .open_modal:focus {
    background-color: transparent;
    border-color: transparent;
    color: var(--colors-white);
    padding: 0;
  }
}
.modal-meneric-editable__modal {
  width: 888.96px !important;
  border-radius: 15px;
  min-height: 504px;
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    border-radius: 23px;
  }
  .anticon {
    color: var(--colors-white);
    font-size: 20px;
  }
  .ant-modal-body {
    background: var(--colors-hotberry);
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    height: 100%;
    min-height: 504px;
    padding: 50px;
    display: flex;
  }
  .link-text {
    width: 470px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--colors-highlight-azul);
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    color: var(--colors-white);
    padding-right: 20px;
  }
  .copy {
    position: absolute;
    margin-top: 6px;
    margin-left: -30px;
  }
  .box-left {
    width: 70%;
    height: 100%;
    min-height: 450px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    .cont-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .btn--succes {
        background: var(--colors-frozenberry);
        border-color: var(--colors-frozenberry);
        margin-top: 30px;
        color: var(--colors-white);
      }
      .btn--cancel {
        background: var(--colors-white);
        border-color: var(--colors-white);
        margin-top: 10px;
        color: var(--colors-litberry);
      }
      .btn {
        width: 185.22px;
        border-radius: 5px;
        height: 50px;
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .box-right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 60px;
    color: var(--colors-white);
  }
  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150.19%;
    color: var(--colors-white);
    margin: 10px 0;
    .link {
      color: var(--colors-frozenberry);
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    .ant-modal-body  {
      padding: 15px;
      flex-direction: column-reverse;
      min-height: auto;
      height: auto;
    }
    .box-right {
      width: 100%;
      justify-content: flex-start;
      img {
        width: 100px;
        margin-top: 20px;
        height: 100px;
        margin-bottom: 30px;
      }
    }
    .box-left {
      width: 100%;
      margin-bottom: 30px;
      min-height: auto;
      h1 {
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
      }
    }
    .link-text {
      min-width: 280px;
      width: 100%;
    }
  }
}
.modal-warnning {
  width: 700px !important;
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .anticon {
    color: var(--colors-pureberry);
  }
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    .cont-modal {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
    button {
      margin: 0 10px;
      width: 63.7px;
      height: 51.65px;
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--colors-white);
    }
    .yes {
      background: #EB5757;
    }
    .no {
      background: #27AE60;
    }
  }

  .ant-modal-header, .ant-modal-footer {
    display: none;
  }
}

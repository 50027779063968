.Reports {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px;

  .chartPoint {
    width: 100%;
    &--one {
        text  {
        color: #fff !important;
        filter: brightness(25);
      }
      tspan {
        font-family: inherit;
      }
    }
  }
  &-content {
    max-width: 1300px;
    background-color: var(--colors-darkberry);
    width: 100%;
		padding: 20px;
    border-radius: 10px;
		min-height: 70vh;
    .select {
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 18px;
      color: #fff;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 0px solid #d9d9d9;
    }
    .ant-select-arrow {
      color: #5D30DD;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      &--title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .sub-title {
          color: #ffffff !important;
          margin-left: 20px;
          margin-bottom: 0 !important;
        }
      }
      .circle {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colors-pureberry);
        border-radius: 50px;
      }
    }
    .table-metricks-medium {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .body-bold {
          color: #ffffff;
          margin: 0 18px;
        }
      }
      .apexcharts-legend.apexcharts-align-center.position-bottom {
        display: none;
      }
      .apexcharts-toolbar {
        display: none;
      }
      .chart-one {
        .apexcharts-toolbar {
          display: none;
        }
      }
      .box-table {
        width: 65%;
      }
      .box-number {
        width: 20%;
        display: flex;
        height: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #2c314e;
        &-small {
          width: 15%;
        }
        .body-bold ,.body-regular,.sub-title{
          color: #ffffff !important;
        }
        .body-regular,.sub-title{
          margin-bottom: 0;
        }

        .number {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 72px;
          line-height: 86px;
          display: flex;
          align-items: center;
          background: var(--colors-smoothieberry);
          -webkit-background-clip: text;
          max-width: 100% !important;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0;
          margin-top: -7px;
        }
      }
    }
    .table-metricks-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      .content-chart {
        width: 49.5%;
        margin-top: 10px;
        height: auto;
        background-color: #ffffff;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        position: relative;
        min-height: 200px;
        .item-select {
          position: absolute;
          left: 20px;
          top: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          z-index: 1000;
          .body-bold {
            margin-bottom: 0;
            margin-right: 10px;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: #fff;
            border: 0px solid #d9d9d9;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            padding-right: 18px;
            color: var(--colors-pureberry);
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            margin-top: 5px;
          }
          .ant-select-arrow {
            color: var(--colors-pureberry);
          }
        }
        .tower {
          width: 200px;
          display: flex;
          flex-direction: column;
          border-left: 1px solid #eeeeee;
          align-items: center;
          justify-content: center;
          .body-bold {
            margin-bottom: 3px;
          }
          &-table {
            width: 75px;
            height: 145px;
            border-top-right-radius: 5px;
            .up {
              background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
              height: 85.5%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-top-right-radius: 3px;
              border-top-left-radius: 3px;
              p ,span{
                text-align: center;
                color: #FFFFFF;
                margin: 0;
              }
              p {
                font-size: 8px;
              }
              span {
                font-size: 6px;
              }
            }
            .down {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #1C0E42;
              height: 13.5%;
              width: 100%;
              flex-direction: column;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
              p ,span{
                margin: 0;
                text-align: center;
                color: #FFFFFF;
              }
              p {
                font-size: 8px;
              }
              span {
                font-size: 6px;
              }
            }
          }
          .body-bold {
            text-align: center;
          }
        }
        .chart-point {
          width: 100% !important;
        }
        .chartPoint,.donut {
          width: 100%;
          &--one {
            background-color: red;
            svg, span, p,div, #SvgjsText1056, text  {
              color: #fff !important;
              filter: brightness(25);
            }
            tspan {
              font-family: inherit;
            }
          }
        }
        .donut {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .chartPoint,.chartbar {
          margin-top: 40px;
        }
        .chartPoint,.donut {
          .apexcharts-toolbar {
            display: none;
          }
        }
        .donut {
          
        }
      }
    }
  }
}

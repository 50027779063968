.SectionSummaryPia {
  &--table {
    .ant-tabs-ink-bar {
      position: absolute;
      background: #340aab;
      pointer-events: none;
    }
    .tab-head {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
        height: 12px;
      }
    }
    .ant-tabs-tab {
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5d30dd;
        opacity: 0.5;
        margin-bottom: 0;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #340aab;
      font-weight: 500;
      h1 {
        color: #340aab !important;
        margin-bottom: 0;
        opacity: 1;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .data-risk {
      border-bottom: 0px solid #b2b2b2 !important;
    }
    .data-risk-column {
      .column {
        width: 50% !important;
        padding-right: 20px;
        .number-serie {
          width: 100%;
        }
      }
    }
    &__column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid #b2b2b2;
      padding-top: 20px;

      iframe {
        margin-top: 20px;
        height: 155px !important;
        width: 100% !important;
        margin-bottom: 20px;
      }
      .content {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .columns {
        width: 100%;
        .small-detail {
          margin-top: 5px;
        }
      }
      .content--items {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .items {
          width: 33%;
        }
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #b2b2b2;
        margin-bottom: 10px;
      }
      .moneda {
        h4 {
          text-transform: uppercase !important;
        }
        text-transform: uppercase !important;
      }
      .btn-tooltip {
        background-color: transparent !important;
        border: none !important;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent !important;
          border: none !important;
        }
      }
      h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 150.19%;
        color: var(--colors-pureberry);
        margin-bottom: 10px;
        text-transform: capitalize;
        &::first-letter {
          text-transform: uppercase;
        }

        a {
          color: var(--colors-litberry);
        }
      }
      .item {
        width: 50%;
        margin-bottom: 15px;
        &-title {
        }
        &-rta {
        }
      }
      .content-gray {
        width: 100%;
        padding: 15px;
        height: auto;
        background: #f7f7f7;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        &--item {
          margin-right: 10px;
          max-width: 45%;
          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: #b2b2b2;
          }
          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 17px;
            color: var(--colors-pureberry);
            a {
              color: var(--colors-litberry);
            }
          }
        }
      }
      .content-lines {
        width: 100%;
        margin: 20px 0;
        .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .box {
        width: 50%;
        margin-bottom: 21px;
        &-placa {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .placa {
            margin-top: 0 !important;
            width: 100%;
          }
        }
        .column {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid #b2b2b2;
        }
        .placa {
          margin-top: 20px;
        }
        .button-placa {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 150.19%;
          width: 100%;
          height: 43px;
          color: var(--colors-pureberry);
          background: #f7f7f7;
          border-radius: 7px;
          h4 {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    h3 {
      h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9375rem;
        line-height: 1.5em;
        color: var(--colors-pureberry);
      }
    }
    .warnning-head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 5px;
      .small-detail {
        margin-left: 10px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        margin-bottom: 0px;
        line-height: 15px;
        color: #b2b2b2;
      }
    }
    .content-total {
      margin-top: 15px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .btn-tooltip {
        margin-top: -5px;
      }
      .h2 {
        h4 {
          font-size: 1.875rem !important;
          color: var(--colors-pureberry);
          line-height: 36px !important;
          font-weight: 900 !important;
          font-family: Lato;
        }
        .body-bold {
          font-size: 1.875rem !important;
          color: var(--colors-pureberry);
          line-height: 36px !important;
          font-weight: 900 !important;
          font-family: Lato;
        }
      }
      .total {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #b2b2b2;
      }
    }
  }
  .item-rta {
    text-transform: lowercase !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
  .empty {
    span {
      background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.875rem;
      color: var(--colors-pureberry);
      line-height: 36px;
      font-weight: 900;
    }
  }
}

.ant-popover-inner {
  background-color: #1c0e42 !important;
  p, span,.ant-popover-inner-content {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    color: #ffffff;
  }
}

.span.ant-popover-arrow-content, .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-right-color: #1C0E42 !important;
  border-bottom-color: #1C0E42 !important;
}
.ant-popover-arrow-content {
  background-color: #1c0e42 !important;
  color: #1c0e42 !important;
  border-color: #1c0e42 !important;
}

.btn-tooltip {
  background-color: transparent !important;
  border: none !important;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    border: none !important;
  }
}
.Policies {
  padding: 0;
  padding-top: 0px;
  transition: 0.1s;
  z-index: 300;
  &--open {
    position: absolute;
    right: 0;
    top: 0px;
    border: 1px solid #efefef;
    border-radius: 10px;
    box-shadow: 2px 3px 29px 3px rgba(0, 0, 0, 0.2);
    @media (max-width: $medium) {
      width: 349px;
      right: auto;
    }
  }
  .content-select-multiple .select-multiple {
    min-width: 200px;
  }
  .ant-slider-vertical .ant-slider-handle {
    display: none !important;
  }
  .ant-slider-vertical .ant-slider-step {
    display: none !important;
  }
  &--darck {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    .desSelect {
      color: #35d8d0 !important;
    }
    .Policies__content {
      background-color: #1c0e42;
      // padding: 0px 0;
      &__filter {
        .box-big__head-r .button-tab {
          color: #35d8d0 !important;
        }
        .box-big {
          .button-list {
            color: #ffffff !important;
            background-color: #1c0e42 !important;
          }
        }
      }
      .slick-slide .icon {
        height: 40px !important;
      }
      .slick-slider-content .ant-select .ant-select-selector {
        min-width: 100px;
        border: 2px solid #f7f7f7;
        box-sizing: border-box;
        border-radius: 5px;
        height: 59px;
      }
      .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        background: #f5f5f5;
      }
      .btn-select-caroucel,
      .select-multiple {
        background-color: transparent !important;
        .icon {
          height: 19px !important;
        }
        .circle--content {
          background-color: #1c0e42 !important;
          span {
            color: #ffffff !important;
          }
        }
      }

   
      .slick-dots li.slick-active button:before {
        opacity: 1;
        background-color: #35d8d0;
      }
      .text {
        color: #ffffff !important;
      }
      .DayPicker-Caption > div {
        color: #ffffff !important;
      }
      

      .DayPicker-Day {
        color: #ffffff !important;
      }
      .slick-dots li button:before {
        border: 1px solid #35d8d0;
      }
      &-head {
        
        button {
          color: #35d8d0;
          &:hover,
          &:active,
          &:focus {
            color: #35d8d0;
          }
          .filter-green {
            width: 15px;
            height: 10px;
            object-fit: contain;
            box-shadow: none;
            margin-top: -2px;
            margin-left: 10px;
            display: unset;
          }
          // .filter {
          //   display: none;
          // }
        }
      }
      .button--select {
        background-color: #1c0e42 !important;
        border-bottom: 1px solid #a8a8a8 !important;
        span {
          color: var(--colors-frozenberry) !important;
        }
      }
      .content-status {
        .box {
          background-color: #1c0e42 !important;
          border: 1px solid #00dbd0 !important;
          box-sizing: border-box;
          &--active {
            border: 1px solid #35d8d0 !important;
          }
        }
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #1c0e42 !important;
        .icon {
          height: 23px !important;
        }
      }
      .sub-title {
        color: #ffffff;
      }
    }
  }
  .content-select-multiple {
    position: relative;
    .select-multiple {
      margin: 0 14px;
      border: 2px solid #f7f7f7;
      box-sizing: border-box;
      border-radius: 5px;
      width: 180px;
      height: 59px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .circle {
        width: 26px;
        height: 26px;
        border-radius: 26px;
        background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
        padding: 1px;
        &--content {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: #ffffff;
          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 14px;
            color: var(--colors-pureberry);
          }
        }
      }
      &--selected {
        background-color: #340aab;
        .circle {
          &--content {
            background-color: #340aab;
            span {
              color: #ffffff;
            }
          }
        }
      }
      @media (max-width: $medium) {
        width: 160px !important;
      }
    }
    .absolute-content {
      left: 0;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 5px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 180px;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
      position: relative;
      z-index: 200;
      .item {
        height: 55px;
        width: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 5px;
        border-bottom: 1px solid #a69dbe;
        &:last-child {
          border-bottom: 0px solid #a69dbe;
        }
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: var(--colors-pureberry);
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #fff;
          border-color: #5d30dd;
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
          border-color: #5d30dd !important;
        }
        &--selected {
          span {
            background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      &::-webkit-scrollbar {
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background: #b1b1b1;
      }
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;

    &--buttons {
      display: flex;
      position: relative;
      .button-see-less {
        display: none;
        margin-top: 40px;
      }
      .button-filter {
        height: 37px;
        background: #ffffff;
        border: 1px solid #f7f7f7;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 10px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-pureberry);
        }
        &--active,
        &:active,
        
        &:focus {
          background: #5d30dd;
          svg {
            path {
              stroke: #ffffff;
            }
          }
          span {
            color: #ffffff;
          }
        }
      }
      .button-calendar {
        @media (max-width: 768px) {
          display: none !important;
        }
      }
      .calendar-absolute {
        position: absolute;
        background: #ffffff;
        border-radius: 10px;
        padding: 20px;
        right: 0;
        top: 42px;
        z-index: 400;
        width: 430px;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
        .content-calendars {
          display: flex;
          justify-content: space-between;
        }
        .calendar-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .titles {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: var(--colors-pureberry);
          }
          .button-close {
            background-color: transparent;
            border: none;
            margin-top: -15px;
            padding: 0 !important;
          }
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
          position: relative;
          background: transparent !important;
          color: #f0f8ff;
          border-radius: 0% !important;
          div {
            background: #7f14fc !important;
          }
          &:hover {
            background: transparent !important;
          }
        }

        .DayPicker-Week > .DayPicker-Day--selected:not(.DayPicker-Day--outside):first-child > div {
          background: #7f14fc !important;
          border-top-left-radius: 21px !important;
          border-bottom-left-radius: 21px !important;
        }
        .DayPicker-Week > .DayPicker-Day--selected:not(.DayPicker-Day--outside):last-child > div {
          background: #7f14fc !important;
          border-top-right-radius: 21px !important;
          border-bottom-right-radius: 21px !important;
        }
        .first-selected-day {
          background: #7f14fc !important;
          border-top-left-radius: 21px !important;
          border-bottom-left-radius: 21px !important;
        }
        .last-selected-day {
          background: #7f14fc !important;
          border-top-right-radius: 21px !important;
          border-bottom-right-radius: 21px !important;
        }
        .DayPicker--Policies--two {
          .DayPicker-Body {
            .DayPicker-Week {
              &:first-child {
                .DayPicker-Day--selected {
                  &:first-child {
                    div {
                      border-top-left-radius: 21px !important;
                      border-bottom-left-radius: 21px !important;
                    }
                  }
                }
              }
            }
          }
        }
        .DayPicker-disabled {
          margin-left: 2px;
          .DayPicker-Day, .DayPicker-Caption {
            color: #B2B2B2 !important;
            div {
              color: #B2B2B2 !important;
            }
          }
          .DayPicker-Caption > div {
            color: #B2B2B2 !important;
          }
          .DayPicker-NavButton {
            filter: grayscale(3);
          }
          .DayPicker-Months {
            background: #F7F7F7;
          }
          @media (max-width: 768px) {
            margin-left: 0;
          }
        }
        .DayPicker--Policies--one {
          .DayPicker-Body {
            .DayPicker-Week {
              &:last-child {
                .DayPicker-Day--selected {
                  &:last-child {
                    div {
                      border-top-right-radius: 21px !important;
                      border-bottom-right-radius: 21px !important;
                    }
                  }
                }
              }
            }
          }
        }

        

        .DayPicker-Caption > div {
          font-weight: 500;
          font-size: 1.15em;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--colors-pureberry);
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          position: relative;
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
          color: #f0f8ff;
        }
        .DayPicker-Day {
          display: table-cell;
          padding: 5px 0;
          border-radius: 0% !important;
        }
        .DayPicker-Weekday {
          display: table-cell;
          padding: 0.5em;
          color: #8b9898;
          text-align: center;
          font-size: 0.875em;
          @media (max-width: 768px) {
            font-size: 1.875em;
          }
        }
        .DayPicker-Weekday abbr[title] {
          border-bottom: none;
          text-decoration: none;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 9px;
          text-align: center;
          color: #b2b2b2;
        }
        .DayPicker-Day {
          display: table-cell;
          padding: 1px 0;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          cursor: pointer;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          border-bottom: 9px solid transparent;
          font-size: 10px;
          line-height: 15px;
          /* display: flex; */
          align-items: center;
          color: var(--colors-pureberry);
        }
        .DayPicker-Month {
          margin: 5px;
          margin-top: 1em;
        }
        &--res {
          display: none;
          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .title {
        display: none;
      }
      @media (max-width: 768px) {
        display: none;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .button-see-less {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          border: none;
          background-color: transparent;
          color: #5d30dd;
          img {
            margin-left: 10px;
          }
        }
        .calendar-absolute {
          position: relative;
          width: 100%;
          padding: 0px;
          box-shadow: none;

          .button-close {
            visibility: hidden;
          }
          .content-calendars {
            flex-direction: column;
          }
        }
        &--active {
          display: flex !important;
          flex-direction: column;
          position: absolute;
          top: 0;
          z-index: 433;
          width: 100%;
          left: 0;
          background: #ffffff;
          padding: 20px;
          border-radius: 10px;
          padding-bottom: 130px;
        }
        .button-filter {
          margin: 10px 0;
        }
      }
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .btn {
      border: none;
      background-color: transparent;
      padding: 0 !important;
      .anticon {
        color: #7f7f7f;
        font-size: 20px;
      }
    }
  }
  @media (max-width: $medium) {
    .slick-slide.slick-active.slick-cloned {
      display: none !important;
    }
  }
  &__content {
    padding: 0px;
    background: transparent;
    border-radius: 10px;
    height: auto;
    &--open {
      height: auto;
      padding: 0 20px;
      background-color: #ffffff;
    }
    &-head {
      margin-bottom: 5px;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      // margin-bottom: 20px;
      .sub-title {
        margin-bottom: 0;
      }
    }
    .slick-slider-content {
      // display: flex !important;
      justify-content: center;
      .btn-select-caroucel {
        background: #fff;
        border: 2px solid #f7f7f7;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0 28px;
        margin: 0 14px;
        height: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 200px;
        button {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--active {
          background: #340aab;
          border-color: #340aab;
        }
        @media (max-width: $medium) {
          width: 130px !important;
        }
      }
      span.btn-select-caroucel.ant-tooltip-disabled-compatible-wrapper {
        padding: 0 !important;
        min-width: 150px;
        @media (max-width: 768px) {
          min-width: auto !important;
        }
        button, .ant-btn {
          background: #F7F7F7 !important;
          border-color: #f7f7f7 !important;
          img.icon {
            filter: grayscale(1);
          }
        }
      }
      .ant-select {
        margin: 0 20px;
        width: 153px;
        .placeholder__icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon {
            max-height: 40px;
            max-width: 60px;
          }
          &-donw {
            margin-left: 10px;
          }
        }
        .ant-select-selector {
          min-width: 100px;
          border: 2px solid #f7f7f7;
          box-sizing: border-box;
          border-radius: 5px;
          height: 59px;
        }
      }
    }
    .btn-slick {
      height: 59px;
      display: flex !important;
      justify-content: center;
      max-width: 145px;
      align-items: center;
      width: auto !important;
      padding: 0 10px !important;
      box-sizing: border-box;
      border-radius: 5px;
      border: 2px solid #f7f7f7;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots li button:before {
      font-family: "slick";
      font-size: 0px;
      line-height: 20px;
      position: absolute;
      top: 15px;
      background: transparent;
      border: 1px solid #5d30dd;
      left: 0;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      content: "•";
      text-align: center;
      opacity: 1;
      color: black;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-dots li.slick-active button:before {
      opacity: 1;
      background-color: #5d30dd;
    }
    &__filter {
      margin-top: 20px;
      display: flex;
      padding: 0px;
      justify-content: space-between;
      &--global {
        justify-content: flex-start;
        gap: 20px;
      }
      .box-mini {
        width: 165px;
        padding: 10px 15px;
        &--border {
          padding: 10px 0;
          width: 200px;
          .ant-slider-vertical {
            margin-left: 0px;
          }
          @media (max-width: 768px) {
            border-right: 0px solid #f6f5f8;
            .ant-slider-vertical {
              width: 100% !important;
            }
          }
          .button {
            border: none !important;
            border-top: 1px solid #f6f5f8 !important;
            border-radius: 0;
            box-shadow: none;
            margin-top: 5px !important;
          }
          .ant-slider-rail {
            background-color: transparent !important;
          }
          .ant-slider-track {
            background-color: transparent;
          }
          .ant-slider.ant-slider-vertical:hover {
            .ant-slider:hover .ant-slider-rail {
              background-color: transparent !important;
            }
            .ant-slider-track {
              background-color: transparent;
            }
          }
          .button--select {
            margin-top: 5px;
            border: 1.5px solid var(--colors-pureberry);
            width: 162px !important;
            box-shadow: none;
            border-radius: 5 !important;
          }
        }
        .sub-title {
          margin-bottom: 10px;
        }
        .box-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .desSelect {
            padding: 0 !important;
            margin-top: -10px;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: #5d30dd;
            background-color: transparent;
            border: none;
            box-shadow: none;
          }
        }
        &--min {
          width: 20%;
        }
        .slick-dots {
          position: absolute;
          bottom: auto;
          display: block;
          width: 38px;
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;
          display: flex !important;
          flex-direction: column;
          right: -35px;
          align-items: flex-end;
          top: 51px;
          li button:before {
            width: 5px;
            height: 5px;
          }
        }
        .buttons {
          position: relative;
          .slick-track {
            height: auto !important;
          }
          .button {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            width: 99% !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
            .left {
              display: flex;
              align-items: center;
              justify-content: center;
              .image {
                width: 28px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-right: 5px;
              }
            }
          }
          .button--select {
            height: 50px;
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #5d30dd;
            border-radius: 5px;
            .left {
              display: flex !important;
              align-items: center;
              .image {
                width: 28px;
                justify-content: center;
                align-items: center;
                display: flex;
                margin-right: 5px;
              }
              img {
                height: auto;
              }
            }
            img {
              display: inherit;
            }
          }
        }
        .content-status {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          .box {
            border: 1px solid #f7f7f7;
            box-sizing: border-box;
            border-radius: 10px;
            width: 197px;
            height: 43.5px;
            margin-bottom: 15px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            @media (max-width: 768px) {
              height: 96px;
              width: 48% !important;
              flex-direction: column;
            }
            &--active {
              border: 1px solid #5d30dd;
            }
            img {
              width: 18px;
              height: 18px;
              object-fit: contain;
              margin-right: 5px;
            }
            span {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 150.19%;
              margin-top: 3px;
            }
            .vigor {
              color: #ffbb0d;
            }
            .cancelada {
              color: #b2b2b2;
            }
            .vencida {
              color: #eb5757;
            }
            .pagada {
              color: #35d8d0;
            }
          }
        }
      }
      .button-see-less {
        background-color: transparent;
        border: none;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5d30dd;
        margin-top: 20px;
        display: none;
        img {
          margin-left: 10px;
        }
        @media (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .box-big {
        width: 20%;
        padding: 10px 0;
        transition: 0.6s;
        .box-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .sub-title {
            margin-bottom: 10px;
          }
          @media (max-width: 768px) {
            padding: 0 20px;
          }
          .desSelect {
            padding: 0 !important;
            margin-top: -10px;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: #5d30dd;
            background-color: transparent;
            border: none;
          }
        }
        &-before {
          background: #ffffff;
          border-radius: 5px;
          padding: 10px 15px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: auto !important;
          border-left: 1px solid #f6f5f8;
          border-right: 1px solid #f6f5f8;
          .DayPicker {
            display: inline-block;
            font-size: 1rem;
            width: 50% !important;
          }
          .sub-title {
            margin-bottom: 15px;
          }
          @media (max-width: 768px) {
            margin-left: 0px;
            margin-right: 0px;
            padding: 10px 20px;
            button {
              width: 100% !important;
            }
          }
        }

        &--big {
          width: auto;
          .DayPicker-Caption {
            display: table-caption;
            margin-bottom: 0.5em;
            padding: 0 0.5em;
            text-align: left;
            padding: 0 20px;
            margin-top: -5px;
            .select-daypiker {
              width: 50%;
              border: 0px;
              font-weight: 500;
              font-size: 1.15em;
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 10px;
              line-height: 15px;
              /* display: flex; */
              justify-content: center;
              align-items: center;
              color: var(--colors-pureberry);
            }
          }
          .DayPicker {
            width: calc(50% - 4px) !important;
            @media (max-width: 768px) {
              width: 100% !important;
            }
          }
        }
        .content-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          .content-icon {
            position: relative;
            width: 100%;
            .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active, button[disabled] {
              color: #B2B2B2 !important;
              background: #F7F7F7 !important;
              border-color: #F7F7F7 !important;
            }
            .icon {
              position: absolute;
              z-index: 2;
              top: 12px;
              left: 8px;
              cursor: pointer;
            }
          }
          .button-list {
            background: #ffffff;
            border: 1px solid #f7f7f7;
            box-sizing: border-box;
            border-radius: 5px;
            width: 159px;
            height: 37px;
            margin-bottom: 18px;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            text-align: left;
            line-height: 17px;
            color: var(--colors-pureberry);
            display: flex;
            justify-content: center;
            align-items: center;
            .icon {
              display: none;
              margin-right: 10px;
            }
            &--active {
              border-color: #340aab;
              background: #340aab;
              color: #ffffff;
              .icon {
                display: block;
              }
            }
          }
        }
        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-r {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .button-tab {
              border: none;
              margin-top: -20px;
              padding-top: 0;
              padding-bottom: 0;
              background-color: transparent;
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              opacity: 0.5;
              color: #5d30dd;
              &--active {
                opacity: 1;
              }
            }
          }
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
          position: relative;
          background: transparent !important;
          color: #f0f8ff;
          border-radius: 0% !important;
          div {
            background: #7f14fc !important;
          }
          &:hover {
            background: transparent !important;
          }
        }

        .DayPicker-Week > .DayPicker-Day--selected:not(.DayPicker-Day--outside):first-child > div {
          background: #7f14fc !important;
          border-top-left-radius: 21px !important;
          border-bottom-left-radius: 21px !important;
        }
        .DayPicker-Week > .DayPicker-Day--selected:not(.DayPicker-Day--outside):last-child > div {
          background: #7f14fc !important;
          border-top-right-radius: 21px !important;
          border-bottom-right-radius: 21px !important;
        }
        .first-selected-day {
          background: #7f14fc !important;
          border-top-left-radius: 21px !important;
          border-bottom-left-radius: 21px !important;
        }
        .last-selected-day {
          background: #7f14fc !important;
          border-top-right-radius: 21px !important;
          border-bottom-right-radius: 21px !important;
        }
        .DayPicker--Policies--two {
          .DayPicker-Body {
            .DayPicker-Week {
              &:first-child {
                .DayPicker-Day--selected {
                  &:first-child {
                    div {
                      border-top-left-radius: 21px !important;
                      border-bottom-left-radius: 21px !important;
                    }
                  }
                }
              }
            }
          }
        }
        .DayPicker-disabled {
          margin-left: 4px;
          .DayPicker-Day, .DayPicker-Caption {
            color: #B2B2B2 !important;
          }
          .DayPicker-NavButton {
            filter: grayscale(3);
          }
          .DayPicker-Caption > div {
            color: #B2B2B2 !important;
          }
          .DayPicker-Months {
            background: #F7F7F7;
          }
          @media (max-width: 768px) {
            margin-left: 0;
          }
        }
        .DayPicker--Policies--one {
          .DayPicker-Body {
            .DayPicker-Week {
              &:last-child {
                .DayPicker-Day--selected {
                  &:last-child {
                    div {
                      border-top-right-radius: 21px !important;
                      border-bottom-right-radius: 21px !important;
                    }
                  }
                }
              }
            }
          }
        }

        .DayPicker-Caption > div {
          font-weight: 500;
          font-size: 1.15em;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--colors-pureberry);
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          position: relative;
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
          color: #f0f8ff;
        }
        .DayPicker-Day {
          display: table-cell;
          padding: 5px 0;
          border-radius: 0% !important;
        }
        .DayPicker-Weekday {
          display: table-cell;
          padding: 0.5em;
          color: #8b9898;
          text-align: center;
          font-size: 0.875em;
          @media (max-width: 768px) {
            font-size: 1.875em;
          }
        }
        .DayPicker-Weekday abbr[title] {
          border-bottom: none;
          text-decoration: none;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 8px;
          line-height: 9px;
          text-align: center;
          color: #b2b2b2;
        }
        .DayPicker-Day {
          display: table-cell;
          padding: 1px 0;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          cursor: pointer;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          border-bottom: 9px solid transparent;
          font-size: 10px;
          line-height: 15px;
          /* display: flex; */
          align-items: center;
          color: var(--colors-pureberry);
        }
        .DayPicker-Month {
          margin: 5px;
          margin-top: 1em;
        }
      }
      @media (max-width: $medium) {
        flex-direction: column;
        padding-bottom: 100px;
        .box-mini,
        .box-big {
          width: 100% !important;
          .DayPicker-Month {
            margin: 0px;
            margin-top: 1em;
          }
        }
        .box-big {
          text-align: center;
        }
      }
    }
    @media (max-width: $medium) {
      height: auto !important;
      padding-bottom: 0px;
      .absolute-content {
        margin-bottom: 20px;
      }
    }
  }
  .InsurersFilter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 15px;
    width: 40%;
    .sub-title {
      text-align: left;
    }
    .box {
      flex-wrap: wrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      grid-area: 15px;
      .btn-select-caroucel {
        min-width: auto;
        margin-bottom: 15px !important;
        margin-right: 15px !important;
      }
      button {
        margin-bottom: 15px !important;
        width: auto;
      }
    }
    .slick-slider-content {
      margin-right: 15px !important;
      width: auto;
      button {
        margin: 0 !important;
        width: 100%;
      }
    }
  }
}

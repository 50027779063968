.SectionCommissions {
  width: 100%;
  height: 352px;
  border-radius: 10px;

  background: #ffffff;
  // margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box-r {
    width: 75%;
    max-height: 331px;
    padding: 10px;
    .LongGraph {
      min-height: 200px;
    }
    .content-filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 5px;
      .btn {
        width: 31px;
        height: 25px;
        background: #ffffff;
        border: 1px solid #f7f7f7;
        border-radius: 5px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b3a7c;
        &--active {
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
          border-radius: 5px;
          color: #ffffff;
        }
      }
    }
  }
  .LongGraph {
    height: 310px;
    padding: 20px;
    background: transparent;
    .apexcharts-xaxis-title {
      display: none;
    }
  }
  .box-left {
    width: 35%;
    padding: 20px;
    border-right: 1px solid #e5e5e5;
    height: 100%;
    .small-detail {
      &--date {
        text-transform: uppercase;
      }
    }
    .loader,
    .loader-small {
      padding: 20px !important;
    }
    .body-regular {
      color: #b2b2b2;
      margin-bottom: 20px;
    }
    .button-transparent {
      background-color: transparent;
      border: none;
      padding: 0;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #5d30dd;
    }
    .small-detail {
      span {
        color: #4b3a7c;
      }
    }
  }
  &__responsive {
    display: none;
  }
  @media (max-width: $medium) {
    height: auto;
    background: linear-gradient(268.98deg, #f7f7f7 0.88%, rgba(247, 247, 247, 0) 99.16%);
    
    &__responsive {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: linear-gradient(268.98deg, #f7f7f7 0.88%, rgba(247, 247, 247, 0) 99.16%);
      .button-transparent {
        // height: 60px;
        margin-top: 5px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
      }
      .sub-title {
        margin-bottom: 0;
      }
      .medium {
        .small-detail {
          margin-bottom: 0;
          margin-top: 10px;
        }
        .sub-title {
          margin-bottom: 10px;
        }
      }
      .content-r {
        display: flex;
        padding: 10px;
        background: #ffffff;
        border-radius: 5px;
        width: 100%;
        margin-top: 5px;
        height: 64px;
        &-2 {
          height: auto;
          padding: 0;
          margin-top: 10px;
          background-color: transparent;
        }
        .left {
          width: 80%;
          p {
            margin: 0;
          }
          .sub-title {
            margin-top: 0px;
            margin-bottom: 5px;
            &--gradient {
              background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          .small-detail {
            color: #4b3a7c;
          }
        }
        .rigth {
          width: 20%;
        }
      }
    }
  }
}

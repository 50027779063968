.leadsSelect {
  display: flex;
	justify-content: flex-start;
	margin-left: 40px;
  .button--select {
    display: flex;
    justify-content: flex-start;
    margin: 0 10px;
		&-item, &-item:hover , &-item:active {
      background: var(--colors-white);
      border: 1.5px solid var(--colors-litberry);
      box-sizing: border-box;
      border-radius: 5px;
      height: 40px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
			color: var(--colors-litberry);
			.anticon {
				margin-left: 15px;
				font-size: 15px
			}
    }
  }
}

.section-notifications {
  max-width: 1300px;
  background-color: var(--colors-white);
  width: 100%;
  margin-left: 50px;
  padding: 50px 70px;
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: var(--colors-pureberry);
  }
  .btn-custom {
    font-family: "Lato";
    font-style: normal;
    margin-top: 40px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--colors-white);
    border-radius: 5px;
    height: 50px;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    &--secundary {
      background: var(--colors-frozenberry);
    }
  }
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 60px;
    background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
		margin-top: 80px;
		margin-bottom: 40px;
  }
  p {
    font-family: Lato;
    margin-bottom: 50px;
    font-style: normal;
    margin-top: 70px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--colors-pureberry);
  }
	.button-arrow {
		
	}
  .notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
		width: 100%;
		height: auto;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #d8d8d8;
		margin: 30px 0;
    margin-bottom: 0;
		padding-left: 0;
		&:active,
		&:hover,
		&:focus {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid #d8d8d8;
		}
    &-text {
      display: block;
			width: 95%;
      p {
        margin: 0;
      }
      h4,
      h5 {
				text-align: left;
        line-height: 150.19%;
        margin: 0;
      }
			h6 {
				text-align: left;
				white-space: break-spaces;
			}
      .span-opacity {
        font-family: "Roboto";
        font-style: italic;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        opacity: 0.5;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0px 0px;
    margin-left: 0;
    background-color: transparent;
    &--content {
      padding: 30px;
      background-color: var(--colors-white);
      padding-bottom: 100px;
    }
    h1 {
      margin-bottom: 30px;
      margin-left: 30px;
    }
    h3 {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

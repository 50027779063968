.loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &--full {
    min-height: calc(100vh - 100px);
  }
  img {
    width: 207.7px !important;
    height: 208.78px;
    object-fit: cover;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: var(--colors-pureberry);
    text-align: center;
    width: 100%;
  }
  &-small {
    padding: 50px;
    p {
      display: none !important;
    }
    img {
      width: 50px !important;
      height: 50px !important;
      object-fit: contain !important;
    }
  }
  &-ligth {
    p {
      color: var(--colors-white);
    }
    img {
      width: 100px !important;
      height: 100px !important;
      object-fit: contain !important;
    }
  }
}
.loader-not-padding {
  margin-top: -50px;
}

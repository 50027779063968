.ModalUpBookClient {
	max-width: 1100px !important;
	width: 90% !important;

	.content-modal {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 20px;
		position: relative;
		.btn-icon-back {
			position: absolute;
			top: 0px;
			left: 5px;
			background: transparent;
			border: none;
		}
		&--box-mediun {
			width: 35%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 10px;
			.button {
				width: 160px !important;
			}
			h1 {
				font-family: Lato;
				font-style: normal;
				font-weight: 900;
				font-size: 50px;
				line-height: 60px;
				margin-top: 15px;
				display: flex;
				align-items: center;
				color: var(--colors-pureberry);
			}
			p {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 150%;
				display: flex;
				align-items: center;
				color: var(--colors-pureberry);
			}
		}
		&--box-big {
			width: 65%;
			display: flex;
			flex-direction: column;
			padding: 10px;
			&--down {
				display: flex;
				justify-content: space-between;
				.card-up-book {
					width: 48%;
					padding: 20px;
					margin-top: 30px;
					background: var(--colors-hotberry);
					border-radius: 10px;
					.pronto {
						color: #d9d9d9;
					}
					.button {
						img {
							margin-right: 5px;
							margin-top: -3px;
						}
					}
					h4 {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 20px;
						line-height: 24px;
						display: flex;
						align-items: center;
						letter-spacing: -0.02em;
						color: var(--colors-white);						
					}
					p {
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 10px;
						line-height: 15px;
						display: flex;
						align-items: center;
						color: var(--colors-white);
						margin: 20px 0;
					}
					.button {
						height: 40px !important;
					}
				}
			}
			&--up {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 1px solid var(--colors-oldberry);
				box-sizing: border-box;
				border-radius: 10px;
				padding: 26px 21px;
				.space-text {
					&--title {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						h3 {
							font-family: Lato;
							font-style: normal;
							font-weight: bold;
							margin: 0;
							font-size: 20px;
							line-height: 24px;
							display: flex;
							align-items: center;
							letter-spacing: -0.02em;
							color: var(--colors-pureberry);
						}
						i {
							margin-right: 10px;
						}
						img {
							margin-right: 10px;
							width: 16px;
							height: 16px;
							object-fit: contain;
						}
					}
					p {
						font-family: Roboto;
						font-style: normal;
						font-weight: normal;
						font-size: 15px;
						line-height: 150%;
						display: flex;
						align-items: center;
						color: var(--colors-pureberry);
						margin-top: 15px;
						max-width: 380px;
					}
				}
				.space-up-excel {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					img {
						margin-bottom: 25px;
						margin-left: -15px;
					}
					.button {
						height: 40px !important;
						min-width: 99px !important;
					}
				}
			}
		}
		&--left {
			width: 50%;
			padding: 20px;
			&--two {
				display: flex;
				justify-content: center;
				flex-direction: column;
				height: auto;
			}
			.select-button {
				display: flex;
				flex-direction: column;
				.button- {
					border-radius: 5px;
					height: 46px;
					background: transparent;
					border-color: transparent;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-family: Lato;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					color: var(--colors-pureberry);
					.text {
						font-size: 10px;
						margin-top: 5px;
						margin-left: 5px;
					}
					.circle {
						background-color: var(--colors-white);
						height: 22px;
						width: 22px;
						border-radius: 50%;
						margin-right: 20px;
					}
					&active {
						background-color: var(--colors-midberry);
						color: var(--colors-white);
						.circle {
							background: var(--colors-white);
							border-color: var(--colors-white);
						}
					}
					&inactive {
						.circle {
							border: 1.5px solid var(--colors-litberry);
						}
					}
				}
			}
			
			a {
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				display: flex;
				justify-content: center;
				margin-top: 50px;
				text-decoration-line: underline;
				color: var(--colors-litberry);
				width: 100%;
			}
		}
		&--rihgt {
			width: 50%;
			padding: 20px;
			.form {
				.ant-picker {
					width: 100%;
					height: 50px;
					border-radius: 5px;
					border: 1px solid #BDBDBD;
					box-sizing: border-box;
				}
				.cont-btn {
					display: flex;
					justify-content: flex-end;
				}
				&--date {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.form--item {
						width: 49%;
						.ant-picker {
							width: 100%;
							border: 1px solid #BDBDBD;
							box-sizing: border-box;
							border-radius: 5px;
							height: 50px;
						}
					}
				}
				&--item {
					width: 100%;
					.ant-select:not(.ant-select-customize-input) .ant-select-selector {
						border: 1px solid #BDBDBD;
						box-sizing: border-box;
						border-radius: 5px;
						height: 50px;
					}
					.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
						padding: 7px 0px;
					}		
					.ant-input {
						border: 1px solid #BDBDBD;
						box-sizing: border-box;
						border-radius: 5px;
						height: 50px;
					}		
				}
			}
			.form-page {
				display: flex;
				justify-content: space-between;
				.input--page {
					border: 1px solid #BDBDBD;
					box-sizing: border-box;
					border-radius: 5px;
					height: 50px;
					font-size: 15px;
				}
				.button {
					width: 86px !important;
					min-width: 86px;
					margin-left: 15px !important; 
					padding-inline-end: 6px !important;
					padding-inline-start: 6px !important;
				}
			}
			
			p {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				margin-top: 20px;
				font-size: 15px;
				line-height: 150%;
				color: var(--colors-pureberry);
			}
			.continue {
				width: 100%;
				height: 40px !important;
				margin-top: 25px !important;
			}
		}
		.ant-upload {
			width: 100%;
			display: flex !important;
			justify-content: center;
		}
		h2 {
			font-family: Lato;
			font-style: normal;
			font-weight: 900;
			font-size: 30px;
			line-height: 36px;
			color: var(--colors-pureberry);
		}
		p {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			line-height: 150%;
			color: var(--colors-pureberry);
			max-width: 410px;
		}
		.button-up {
			width: 100%;
			height: 220.97px;
			border: 1px dashed var(--colors-oldberry);
			box-sizing: border-box;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.anticon {
				color: var(--colors-litberry);
				font-size: 60px;
			}
			span {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 150%;
				text-align: center;
				color: var(--colors-pureberry);
			}
			.green {
				color: var(--colors-frozenberry);
			}
		}
		.box-up-leads {
			width: 35%;
			padding: 20px;
		}
		.box-caroucel {
			width: 65%;
			padding: 20px;
			.cont-btn {
				margin-top: 80px;
				display: flex;
				justify-content: flex-end;
				.button {
					width: auto !important;
					min-width: auto;
					&--secundary {
						min-width: 150px;
					}
				}
			}
			.message-warnning {
				min-height: 50px;
				background: var(--colors-warningberry);
				padding: 16px 18px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 30px;
				.icon-tooltip {
					font-size: 14px;
					margin-right: 10px;
				}
				h5 {
					margin: 0;
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 15px;
					line-height: 150%;
					color: var(--colors-pureberry);
				}
			}
			li.slick-active {
				button {
					background-color: var(--colors-litberry) !important;
				}
			}
			.slick-dots li button:before {
				color: transparent !important;
			}
			.slick-dots {
				bottom: -45px;
			}
			.slick-dots li button {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				border: 1px solid var(--colors-litberry);
			}
			
			.card-car {
				border: 1px solid var(--colors-litberry);
				box-sizing: border-box;
				border-radius: 5px;
				width: 182.77px !important;
				height: 263.84px;
				.ant-select {
					width: 100%;
					.ant-select:not(.ant-select-customize-input) .ant-select-selector {
						height: 40px;
					}
				}
				&--header {
					padding: 10px;
					border-bottom: 1px solid var(--colors-oldberry);
					height: 120px;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					align-items: flex-start;
				}
				&--body {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					height: 144px;
					.item {
						width: 100%;
						height: 25%;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						border-bottom: 1px solid var(--colors-oldberry);
						box-sizing: border-box;
					}
				}
				h6 {
					font-family: Roboto;
					font-style: normal;
					font-weight: normal;
					font-size: 15px;
					line-height: 150%;
					color: var(--colors-pureberry);
					margin: 0;
				}
				.section-title {
					align-items: center;
					display: flex;
					justify-content: space-between;
					img {
						height: 13px;
						width: 13px;
						object-fit: contain;
						margin-right: 10px;
					}
					button {
						font-family: Lato;
						padding: 0 5px;
						font-style: normal;
						font-weight: bold;
						font-size: 10px;
						line-height: 15px;
						background-color: transparent;
						border-color: transparent;
						color: var(--colors-frozenberry);
					}
				}
			}
		}
		.ant-upload-list-item {
			position: relative;
			height: 22.001px;
			margin-top: 8px;
			font-size: 14px;
		}
		.ant-upload-list-item:hover .ant-upload-list-item-info {
			background-color: transparent;
			padding: 20px;
		}
		.ant-upload-list-item {
			position: relative;
			height: 22.001px;
			margin-top: 8px;
			font-size: 14px;
			&:hover {
				height: 22.001px;
			}
		}
		.ant-upload-list-item-info {
			height: 100%;
			padding: 0 4px;
			-webkit-transition: background-color 0.3s;
			transition: background-color 0.3s;
			border: 1px dashed var(--colors-oldberry);
			padding: 20px;
			border-top: 0;
			margin-top: -11px;
			border-end-end-radius: 10px;
			border-end-start-radius: 10px;
		}
		.ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
			flex-direction: row-reverse;
		}
		.ant-upload-list-item-name {
			justify-content: flex-end;
			display: flex;
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			color: var(--colors-frozenberry);
		}
		.ant-upload-list-item-card-actions-btn {
			opacity: 1;
			.anticon {
				color: #EB5757;
				font-size: 20px;
			}
		}
		.ant-upload-list-text .ant-upload-text-icon .anticon {
			position: static;
			display: none;
		}
	}
	
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    border-radius: 13px;
  }
  .ant-modal-close-x {
    color: var(--colors-litberry);
    font-size: 18px;
  }
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .berry_link {
      width: 96px;
      height: 96px;
      object-fit: cover;
      &--active {
        display: none;
      }
    }
    
    
  }
	@media screen and (max-width: 768px) {
		.ant-modal-body {
			padding: 10px;
		}
		width: 100% !important;
		.content-modal {
			.content-car {
				width: 100%;
				display:flex !important;
				justify-content: center ;
			}
			flex-direction: column;
			padding: 20px 0;
			&--box-mediun {
				width: 100%;
				h1 {
					font-weight: 900;
					font-size: 30px;
					line-height: 36px;
				}
				.button {
					width: 100% !important;
				}
			}
			&--box-big {
				width: 100%;
				&--up {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 1px solid var(--colors-oldberry);
					box-sizing: border-box;
					border-radius: 10px;
					padding: 26px 21px;
					.space-text--title {
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
					}
					i {
						margin-right: 10px;
					}
					
					p {
						display: none !important;
					}
				}
				&--down {
					flex-direction: column;
					.card-up-book {
						width: 100%;
					}
				}
			}
			&--left {
				width: 100%;
				padding: 10px;
			}
			&--rihgt {
				width: 100%;
				.button {
					width: 100% !important;
				}
			}
			.box-up-leads,.box-caroucel {
				width: 100%;
				.car-upload {
					.button-up {
						height: 50.97px;
						.anticon {
							font-size: 30px;
						}
					}
				}
				.cont-btn {
					justify-content: center;
				}
			}
		}
	}
}

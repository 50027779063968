.HeaderReportsPia {
  padding: 20px 0px 20px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #1C0E42;  
  .button-transparent {
    padding: 0 !important;
    background-color: transparent;
    border: none;
    img {
      margin-right: 10px;
    }
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
    }
    &:hover , &:active, &:focus {
      background-color: transparent;
      border: none;
    }
  }
  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #FFFFFF;
    }
    &--left {
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF;
        margin-bottom: 10px !important;
      }
      &--seclet {
        width: 126.13px;
        height: 37px;
        background: #5D30DD;
        border-radius: 20px 0px 0px 20px;
        padding: 3px 10px;
        padding-right: 0;
        .ant-select.ant-select-single.ant-select-show-arrow {
          width: 100%;
          background: transparent;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
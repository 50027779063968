.leads {
  margin: 0 29px 0 36px;
  .ant-empty.ant-empty-normal {
    display: none !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #f0f0f0 !important;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 160px;
    margin-bottom: 15px;

    &Know-more {
      width: 100%;
      height: 100%;
      background-image: url(../../../assets/images/backgroundLeads.png);
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      background-size: 100% 100%;

      &__text {
        width: 50%;
        height: 100%;
        padding: 47px 57px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--colors-white);
          margin-bottom: 11px;
        }

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
          color: var(--colors-white);
          margin-bottom: 11px;
        }
      }
      &__button {
        width: 50%;
        height: 100%;
        padding: 0px 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-btn {
          background: var(--colors-litberry);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          width: 100%;
          height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            margin: 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: var(--colors-white);
          }
        }

        .ant-btn:hover,
        .ant-btn:focus,
        .ant-btn:active {
          background: var(--colors-midberry);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;

          span {
            margin: 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: var(--colors-white);
          }
        }
      }
    }
  }

  &__table {
    width: 100%;
    height: 100%;
    padding: 20px 20px 20px 45px;
    border-radius: 0 0 0 20px;
    box-shadow: 4px 4px 8px rgba(44, 42, 42, 0.1);
    background-color: white;
    overflow: auto;

    .ant-tabs {
      overflow: hidden;
    }
    &--content-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 37px;
      .section-responsive {
        display: none;
      }
      .button--secondary {
        img {
          margin-right: 15px;
        }
      }
    }
    &Title {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-litberry);
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      .icon-b {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colors-pureberry);
        margin-right: 15px;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        i {
          &:before {
            color: var(--colors-white);
          }
        }
      }
    }
    &__head {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      &__open-modal {
        cursor: pointer;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      opacity: 1;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--colors-litberry);
    }
    .ant-tabs-tab {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--colors-litberry);
      opacity: 0.55;
      margin-bottom: 10px;
    }
    .ant-tabs-tab-disabled {
      color: var(--colors-litberry) !important;
      opacity: 0.55;
    }
    .ant-tabs-tab:hover {
      color: var(--colors-litberry);
      opacity: 1;
    }
    .ant-tabs-ink-bar {
      background: var(--colors-pureberry);
      opacity: 0.75;
      height: 1px !important;
    }
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid var(--colors-litberry);
        opacity: 0.25;
        border: 0.5px solid var(--colors-litberry);
      }
    }
    .ant-empty-image {
      display: none;
    }
    .table-leads {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .ant-table-pagination-center {
            display: none;
          }
        }
      }
      .ant-table-thead > tr > th {
        color: rgba(0, 0, 0, 0.35);
        font-weight: 500;
        text-align: left;
        background: var(--colors-white);
        border-bottom: none;
        font-size: 12px;
        line-height: 14px;
        &:nth-of-type(1) {
          padding: 16px 16px 16px 0;
        }
      }

      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th,
      .ant-table tfoot > tr > td,
      .ant-table tfoot > tr > th {
        position: relative;
        padding: 16px 0;
        overflow-wrap: break-word;
      }

      .ant-table-cell {
        a {
          margin-left: 8px;
        }
      }
      .table-cell {
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-black);
        
        &--name {
          img {
            margin-right: 10px;
          }
        }
        &--email {
          text-decoration-line: underline;
          color: var(--colors-highlight-azul);
        }
        &--line {
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: var(--colors-pureberry);
        }
      }
      .custom-trigger-btn {
        background: var(--colors-litberry);
        color: #fff;
        width: 95px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        font-family: Lato;
        span {
          margin-left: 15px;
        }
      }
      .ant-pagination-item {
        border-radius: 100%;
        border-color: rgba(93, 48, 221, 0.5);
        a {
          color: var(--colors-pureberry);
        }
      }
      .ant-pagination-item-active {
        background: var(--colors-litberry);
        opacity: 0.85;
        a {
          color: white;
        }
      }
      .ant-pagination-item-link {
        border-radius: 100%;
        border-color: rgba(93, 48, 221, 0.5);
      }
      .button-action {
        background: var(--colors-litberry);
        width: 95px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: "Lato";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: var(--colors-white);
        &:first-child {
          margin-right: 13px;
        }
      }
    }
    .ant-pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .ant-pagination-item-link {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-pagination-item {
        border-radius: 50%;
      }
      .ant-pagination-item-active {
        background: var(--colors-litberry) !important;
        border-color: var(--colors-litberry);
        a {
          color: var(--colors-white);
        }
      }
    }
    @media screen and (max-width: 768px) {
      border-radius: 5px !important;
      padding: 15px;
      .tabs-leads {
        overflow: auto;
      }
    }
  }
  .actions-head {
    display: flex;
    align-items: center;
    padding: 15px 10px 20px 0;
    justify-content: space-between;
    margin-bottom: 50px;
    &__content {
      display: flex;
    }
    &__select {
      display: flex;
      align-items: center;
      &Label {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-black);
        opacity: 0.35;
        margin-right: 10px;
      }
      .ant-select-selector {
        border: 0.5px solid var(--colors-pureberry) !important;
        box-sizing: border-box;
        border-radius: 2px !important;
        height: 20px !important;
        input {
          height: 20px !important;
        }
        span {
          line-height: 20px !important;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-black);
        }
      }
      .ant-select-arrow {
        svg {
          fill: var(--colors-pureberry);
        }
      }
    }
    &__search {
      max-width: 480px;
      display: flex;
      align-items: center;
      input.ant-input.actions-head__searchInput {
        width: 210px;
      }
      .icon-search {
        font-size: 16px;
      }
      figure {
        margin-bottom: 0;
      }
      &Input {
        border: 0.5px solid rgba(222, 222, 222, 0.5) !important;
        box-sizing: border-box;
        border-radius: 2px !important;
        height: 25px !important;
        margin-right: 12px;
      }
    }
    &__filter {
      .btn-active-button {
        background-color: transparent;
        border-color: transparent;
      }
      .icon-filter {
        font-size: 16px;
      }
      .ant-dropdown-link {
        border-color: transparent;
        background: transparent;
      }
      .menu-leads {
        position: absolute;
        right: 59px;
        z-index: 300 !important;
        margin-top: 140px;
      }
      max-width: 480px;
      display: flex;
      align-items: center;
      margin-left: 20px;
      figure {
        margin-bottom: 0;
      }
    }
  }
  &__leadType,
  .ant-btn {
    &--selected {
      border-color: #40a9ff !important;
      color: #40a9ff !important;
    }

    &--notSelected {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }
  &--responsive {
    display: none;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 70px;
    &--responsive {
      display: flex;
      flex-direction: column;
      .body-small {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        opacity: 0.3;
      }
      .card-lead {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 11px 10px;
        padding-bottom: 0;
        margin-top: 20px;
        .body-small {
          text-align: left;
          margin-bottom: 0 !important;
        }
        &--head {
          display: flex;
          justify-content: flex-start;
          .cont-icon {
            width: 52px;
            height: 54.2px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F7F7F7;
            border-radius: 5px;
            .icon {
              width: 18px;
              height: 16.2px;
            }
          }
          .origin {
            padding-top: 20px;
          }
          .title {
            display: block;
            padding: 20px;
            padding-left: 0px;
            position: relative;
            z-index: 1;
            background: #ffffff;
            width: 80%;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px !important;
            &-footer {
              display: flex;
              justify-content: center;
              .box {
                width: 60%;
              }
              .box-two {
                width: 40%;
              }
            }
            h4 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 150.19%;
              color: var(--colors-pureberry);
              margin: 0;
              margin-bottom: 0px;
            }
            
          }
        }
        .contacts {
          margin-top: 20px;
          margin-bottom: 20px;
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 10px 0;
            img {
              height: 13px;
              width: 14px;
              object-fit: contain;
            }
          }
          p {
            margin: 0;
            margin-left: 15px;
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          background: #B2B2B2;
        }
        .see-detail {
          width: 100%;
          border: none;
          background: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          height: 30px;
          box-shadow: none;
          span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: var(--colors-pureberry);
          }
        }
      }
    }
    margin: 0 !important;
    &__table {
      background-color: transparent;
      box-shadow: none;
      
      &--content-title {
        margin-bottom: 20px;
        .section-responsive {
          display: flex !important;
          margin-top: -10px;
          .btn-transarent, .btn-transarent:hover , .btn-transarent:active, .btn-transarent:focus {
            border: none;
            background: none;
            box-shadow: none;
            padding: 0 10px;
            margin-left: 5px;
          }
        }
      }
      .tabs-leads {
        display: none;
      }
    }
  }
}

.leads-detail {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__button {
    background: var(--colors-litberry);
    border-radius: 5px;
    width: 94px;
    height: 40px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-white);
  }

  &__button:hover {
    background: var(--colors-midberry);
    border-radius: 5px;
    width: 94px;
    height: 40px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-white);
  }
}

.filter {
  width: 283px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  .ant-menu {
    background: var(--colors-backberry);
    border-radius: 10px;
    width: 100%;
    padding: 15px 10px 45px;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    opacity: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colors-litberry);
  }
  .ant-tabs-tab {
    opacity: 0.55;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: rgba(27, 27, 27, 0.55);
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab-disabled {
    color: var(--colors-litberry) !important;
    opacity: 0.55;
  }
  .ant-tabs-tab:hover {
    color: var(--colors-litberry);
    opacity: 1;
  }
  .ant-tabs-ink-bar {
    background: var(--colors-pureberry);
    opacity: 0.75;
    height: 1px !important;
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: 1px solid var(--colors-litberry);
      opacity: 0.25;
      border: none;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    h4 {
      margin: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: #333333;
    }
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 10%;
      img {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__buttons {
    &Wrapper {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--colors-litberry) 1a;
      border-radius: 5px;

      .ant-btn {
        height: 26px;
        padding: 0 0 0 7px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: rgba(27, 27, 27, 0.55);
        border-bottom: 1px solid var(--colors-litberry) 1a;
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        background: var(--colors-midberry);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: none;
        display: flex;
        justify-content: flex-start;
        padding: 0 0 0 7px;
        align-items: center;
        margin: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--colors-white);
      }
    }
    &Wrapper__select {
      background: var(--colors-midberry) !important;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border: none !important;
      color: var(--colors-white) !important;
      span {
        color: var(--colors-white);
      }
    }
  }

  &__day {
    &Wrapper {
      width: 100%;
      border: none;
      border-radius: 2px;

      .calendar {
        &-month {
          &_header {
            display: flex;
            align-items: center;
            button {
              background-color: transparent;
              border-color: transparent;
              .anticon {
                color: var(--colors-pureberry);
              }
            }
          }
          &_header_title {
            width: 100%;
            text-align: center;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            color: rgba(0, 0, 0, 0.872566);
          }
          .calendar-days_of_week {
            display: flex;
            overflow-x: auto;
            &_day {
              margin: 10px 6px;
              width: 24px;
              text-align: center;
              color: transparent;
              font-size: 1px;
            }
          }
          .calendar-days_of_week_day:first-letter {
            display: unset !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 6.12764px;
            line-height: 7px;
            color: rgba(0, 0, 0, 0.543337);
          }
        }
        &-week {
          &-day {
            width: 24px;
            margin: 4px 6px;
            padding: 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 10.12764px;
            line-height: 7px;
            color: rgba(0, 0, 0, 0.867782);
            border-color: transparent;
            background-color: transparent;
          }
          button.calendar-week-day.is-prev_month.is-selectable.is-selected.is-start_selection.is-working_day {
            background: var(--colors-midberry) !important;
            color: var(--colors-white) !important;
            width: 22.47px;
            height: 22.47px;
            border-radius: 50%;
          }
          .is-selected {
            background-color: #d0c1fd;
            height: 20px;
          }
        }
      }

      .ant-picker {
        width: 100%;
      }

      .ant-picker-calendar .ant-picker-panel {
        background: var(--colors-backberry);
      }

      .calendar-filter .ant-picker-date-panel .ant-picker-content th {
        width: 24px !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 7px;
        text-align: left;
        color: rgba(0, 0, 0, 0.867782);
      }
      .calendar-filter .ant-picker-content td {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 7px;
        text-align: center;
        height: 24px;
        max-height: 24px;
      }
      .calendar-filter .ant-picker-cell-in-view {
        color: rgba(0, 0, 0, 0.867782);
      }
      .calendar-filter .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: var(--colors-litberry) !important;
        border-radius: 50%;
      }
      .calendar-filter .ant-picker-content tr {
        height: 24px;
        max-height: 24px;
      }
      .calendar-filter .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
        display: none !important;
      }
      .calendar-filter .ant-select-selector {
        border: none !important;
      }
      .calendar-filter .ant-select-selector span.ant-select-selection-item {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
      }
      .ant-picker-calendar-mini .ant-picker-content {
        height: 130px;
      }
      .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 7px;
        background: var(--colors-backberry);
      }
      .calendar-filter .ant-picker-calendar-header {
        flex-direction: row-reverse;
        padding: 12px 0 !important;
        background: var(--colors-backberry);
      }
      .calendar-filter .ant-picker-calendar-header .ant-picker-calendar-month-select {
        margin-left: 0;
      }
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid var(--colors-pureberry) !important;
        border-radius: 50%;
      }

      .ant-picker-cell .ant-picker-cell-inner {
        width: 16px;
        min-width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.container--SummaryPia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.SummaryPia {
  max-width: 1200px;
  width: 100%;
	margin-left: 20px;
	margin-right: 20px;
  @media (max-width: 768px) {
    margin: 0 !important;
  }
}
.CustomizeReport {
  padding: 20px;
  padding-top: 50px;
  &--checks {
    margin-top: 10px;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #340AAB;
      border-color: #340AAB;
    }
    .ant-checkbox {
      border: 1px solid #B2B2B2;
      box-sizing: border-box;
      border-radius: 3px;
      + span {
        padding-right: 8px;
        padding-left: 8px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: var(--colors-pureberry);
        margin-top: -3px;
      }
    }
  }
  &--btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 70px;
    .btn { 
      width: 24%;
      height: 95px;
      background: #ffffff;
      color: var(--colors-pureberry);
      border-color: #340AAB;
      border-radius: 5px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      &:hover, &:focus, &:active {
        color: #ffffff;
        background: var(--colors-litberry) !important;
        border-color: var(--colors-litberry) !important;
      }
      &--active {
        background: #340AAB;
        color: #ffffff;
        border: none;
        &:hover, &:focus, &:active {
          background: #340AAB;
          color: #ffffff;
        }
      }
    }
    &--check-all {
      .btn {
        background: #340AAB !important;
        color: #ffffff !important;
      }
    }
    &--check-none {
      .btn {
        color: var(--colors-pureberry);
        background: #ffffff;
        border-color: #340AAB;
        border: 1px solid;
      }
    }
  }
  .Policies{
    .h2 {
      display: none;
    }
  }
  .generate-report {
    width: 100%;
    border-top: 1px solid #d8d8d8;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.SummarPoliza--padding {
  padding-top: 0;
  width: 100%;
  transition: 0s;
  &--dark {
    padding-top: 314px;
  }
}
.SummarPoliza {
  // position: fixed;
  z-index: 400;
  width: 100%;
  max-width: 1200px;
  top: 51px;
  padding: 20px;
  padding-top: 10px;
  background: #f8f8f8;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .nav {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    .link {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #340AAB;
      margin-right: 30px;
      margin-bottom: 15px;
      &-op {
        opacity: 0.3;
      }
    }
    @media (max-width: 1480px){
      max-width: 100%;
      .link {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 1480px){
    max-width: 100%;
  }
  @media (max-width: $medium){
    max-width: calc(100vw);
    padding-top: 0px;
    .content-dark-mode {
      border-radius: 0 !important;
      margin-top: 0px !important;
      padding: 0 20px;
      &--none {
        padding: 0px;
      }
    }
    .HeaderSummarPoliza {
      margin-top: 20px;
      &--footer {
        margin-top: 5px !important;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        .box-left {
          margin-top: 10px;
          width: 100%;
          justify-content: space-between !important;
          .button-purple {
            margin-left: 0;
          }
        }
      }
    }
    .welcome--name {
      flex-direction: column;
      .left {
        .h2 {
          font-size: 20px;
          line-height: 22px;
        }
      }
      .h3 {
        font-size: 18px;
        line-height: 20px;
        margin-top: 5px;
        margin-left: 34px;
      }
    }
  }

  .HeaderSummarPoliza {
    width: 100%;
    padding: 20px;
    background: #1C0E42;
    border-radius: 10px;
    &--head {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      border: none !important;
      background-color: transparent !important;
      padding: 0 !important;
      .anticon {
        color: #35D8D0;
        font-size: 20px;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        margin-bottom: 0;
        margin-left: 10px;
      }
  
    }
    &--footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .circle {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: #C4C4C4;
        margin-right: 5px;
      }
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0 5px;
        &:active , &:hover, &:focus {
          background-color: transparent;
          border: none;
        }
      }
      .box-left {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          align-items: center;
          display: flex;
          justify-content: center;
        }
        .loading {
          height: 20px;
          margin-right: 30px;
        }
        .button-purple {
          .loading {
            margin-right: 0px;
          }
          width: 146px;
          height: 37px;
          background: #5D30DD;
          border-radius: 5px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
          .upImg {
            margin-top: -2px;
          }
        }
      }
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 0;
        color: #FFFFFF;
      }
    }
  }
  .ant-input-affix-wrapper {
    .ant-input {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-pureberry);
    }
  }
  
  .welcome--name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .h2,.h3 {
      margin-bottom: 0 !important;
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      .h2 {
        margin-left: 10px;
        text-transform: capitalize;
      }
    }
  }
  .ant-input-affix-wrapper {
    height: 50px;
    border-radius: 10px;
    margin-top: 20px;
    border-color: #FFFFFF;
    .anticon {
      font-size: 20px;
      color: #5D30DD;
    }
    .ant-input {
      &::placeholder {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-pureberry);
        opacity: 1 !important;
      }
    }
  }
  .content-dark-mode {
    padding: 27px 20px;
    padding-bottom: 20px;
    background: #1C0E42;
    margin-top: 20px;
    border-radius: 10px;
    transition: .6s;
    width: 100%;
    .welcome--name {
      margin-top: 0 !important;
    }
    .h2,.h3 {
      color: #FFFFFF !important;
    }
    .ant-input-affix-wrapper {
      background: #1C0E42;
      color: #ffffff;
      .ant-input {
        background: #1C0E42;
        color: #ffffff;
        &::placeholder {
          color: #ffffff;
        }
      }
      .anticon {
        color: #35D8D0;
      }
    }
  }
  .content-dark-mode--none {
    transition: .6s;
    width: 100%;
  }
}
.sidebar-modal {
  width: 100%;
  background: var(--colors-white);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 20px 20px;

  &__profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 21px 31px 34px 32px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    &--left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .rigth {
      display: flex;
      align-items: center;
      a {
        margin-left: 20px;
      }
      .icon {
        width: auto;
        object-fit: cover;
        &-email {
          width: 15px;
          height: 12px;
        }
        &-phone {
          width: 15px;
          height: 14px;
        }
        &-whatsaap {
          width: 15px;
          height: 15px;
        }
      }
    }
    .name {
      margin-left: 15px;
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-pureberry);
        margin-bottom: 4px;
      }
      button {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 11.2708px;
        line-height: 13px;
        text-decoration-line: underline;
        color: var(--colors-pureberry);
        @media (max-width: $medium) {
          height: 18px;
        }
      }
    }
    &Info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 27px;
        height: 27px;
      }

      p {
        margin: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-pureberry);
        margin-left: 16px;
      }
    }
    &Letter {
      line-height: 40px;
      width: 40px;
      background: #56ccf2;
      text-align: center;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 700;
      color: white;
      text-transform: capitalize;
    }

    .ant-btn {
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 11.2708px;
        line-height: 13px;
        text-decoration-line: underline;
        color: var(--colors-litberry);
      }
    }
  }
  &__product_description {
    display: felx;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 26px 34px;
    @media (max-width: 768px) {
      background-color: #ffffff;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #e6e6e6;
      .car-icon {
        height: 25px;
      }
    }
  }

  &__request {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 26px 34px;

    .category {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--colors-pureberry);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      .icon {
        height: 12px;
        width: auto;
        object-fit: contain;
      }

      i:before {
        color: var(--colors-white);
      }

      img {
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 1;
      }

      span {
        z-index: 99;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 13.4154px;
        line-height: 16px;
        color: var(--colors-white);
      }
    }

    &Info {
      margin-left: 15px;
      h4 {
        margin: 0;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--colors-black);
        opacity: 0.6;
      }
      &__state {
        display: flex;
        flex-direction: column;
        i {
          font-size: 13px;
        }
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 130.69%;
          color: var(--colors-black);
          opacity: 0.3;
        }

        .ant-btn {
          border: none;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 16px;

          span {
            opacity: 1;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 125.19%;
            color: var(--colors-litberry);
            margin-right: 14px;
          }
        }

        .ant-btn:hover,
        .ant-btn:focus,
        .ant-btn:active {
          border: none;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 16px;
          span {
            opacity: 1;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 125.19%;
            color: var(--colors-midberry);
          }
        }
      }
    }
  }

  &__form {
    padding: 0 31px 30px 27px;
    .ant-divider-horizontal {
      margin: 5px 0;
    }
    .ant-form-item-label > label {
      width: 70px;
      height: 22px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      text-align: left;
      font-size: 12px;
      line-height: 125.19%;
      color: var(--colors-pureberry);
      border-right: 0.2px solid var(--colors-black) 40;
    }

    .ant-form-item {
      margin: 0;
      margin-bottom: 0 !important;
      display: flex;
      align-items: center;
    }

    .ant-input {
      border: none;
      height: 22px;
      font-size: 11px;
      &:disabled {
        background: white;
      }
    }

    .ant-input::placeholder {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 130.69%;
      color: var(--colors-black);
      opacity: 0.45;
    }

    .ant-input:focus,
    .ant-input-focused {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &Buttons {
      display: flex;
      justify-content: flex-end;
      .primary {
        background: var(--colors-litberry);
        border-radius: 5px;
        width: 85px;
        height: 40px;
        border: none;
        padding: 0;
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .primary:hover,
      .primary:focus,
      .primary:active {
        background: var(--colors-midberry);
        border-radius: 5px;
        width: 85px;
        height: 40px;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          color: var(--colors-white);
        }
      }

      .secundary {
        background: var(--colors-whatsapp);
        border-radius: 5px;
        width: 85px;
        height: 37px;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .secundary:hover,
      .secundary:focus,
      .secundary:active {
        border: none;
        background: var(--colors-whatsapp);
        border-radius: 5px;
        width: 85px;
        height: 37px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          color: var(--colors-midberry);
        }
      }
    }
  }

  &__buttons {
    .ant-divider-horizontal {
      margin: 0;
      border: 1px solid #4814dc1a;
    }
    .ant-btn {
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 63px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: var(--colors-litberry);
      }
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active,
    .ant-btn.active {
      background: var(--colors-litberry);
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 63px;
      box-shadow: none;
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: var(--colors-white);
      }
    }
    @media (max-width: 768px) {
      justify-content: flex-start !important;
      padding: 0 20px;
      button {
        width: 133px !important;
      }
    }
  }

  &__menu {
    padding: 0 !important;
    width: 169px;
    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding: 0;
    }

    .ant-btn {
      background: none;
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 41px;
      box-shadow: none;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: var(--colors-litberry);
      }
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: var(--colors-midberry);
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 41px;
      box-shadow: none;
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: var(--colors-white);
      }
    }
  }
  @media (max-width: $medium) {
    width: 100%;
    box-shadow: none;
    padding: 0 20px;
    border-radius: 15px;
    background: var(--colors-white);
    &__profile,
    &__product_description,
    &__request {
      padding: 20px 0 !important;
    }
    &__request {
      border-top: 1px solid #e6e6e6;
      padding-bottom: 15px !important;
    }
    .sidebar-modal__profileLetter {
      color: var(--colors-white) !important;
    }
    .button-solicitud {
      display: none !important;
    }
    &__request {
      background: var(--colors-white);
    }
    &__requestInfo__state {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: transparent;
        border: 0px solid #d9d9d9;
        padding: 0;
        border-radius: 2px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .ant-select-arrow {
        opacity: 1;
        span,
        .anticon {
          color: #5d30dd !important;
          opacity: 1;
        }
      }
      span.ant-select-selection-item {
        opacity: 1;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 150.19%;
        color: #5d30dd !important;
      }
    }
    &__buttons {
      display: none;
      justify-content: center;
      .ant-divider-horizontal {
        margin: 0;
        border: 0px solid transparent;
        display: none;
      }
      .ant-btn-block {
        width: 90%;
        background: var(--colors-litberry);
        border-radius: 5px !important ;
        width: 133px !important;
        max-width: none;
        height: 50px;
        span {
          color: var(--colors-white) !important;
        }
      }
      .ant-btn {
        box-shadow: none;
      }
    }
    &__profile {
      padding: 20px 25px;
      background: var(--colors-white);
      &Letter,
      p {
        color: var(--colors-pureberry);
      }
      .ant-btn span {
        color: var(--colors-pureberry);
      }
    }
    &__form {
      display: none;
    }
  }
}

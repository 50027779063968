.CardBranch {
  padding: 20px 25px 40px;
  background: #ffffff;
  border-radius: 10px;
	min-height: 800px;
	&__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.small-detail {
			text-transform: uppercase;
		}
		p, .h2 {
			margin-bottom: 0;
		}
	}
	.tabs {
		margin-top: 10px;
		.btn-tab {
			font-family: 'Lato';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			color: #340AAB;
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding: 0;
			margin-right: 20px;
			opacity: 0.4;
			&--active {
				opacity: 1;
			}
		}
	}
	.apexcharts-legend-text {
		position: relative;
		font-size: 14px;
		font-family: 'Lato' !important;
		font-style: normal;
		font-weight: 700 !important;
		font-size: 20px !important;
		line-height: 24px;
		letter-spacing: -0.02em;
		color: #4B3A7C !important;
		text-transform: capitalize;
	}
}

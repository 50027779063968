.container {
  background-color: #f7f7f7;
}

.breadcrumb {
  &.ant-breadcrumb {
    padding: 0 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    span {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.02em;
      color: var(--colors-black);
      opacity: 0.5;
    }
  }
}
.ant-layout {
  &.section-dashboard {
    background: none !important;
    @media only screen and (max-width: $large) {
      height: calc(100% - 86px);
      padding-bottom: 50px;
    }

    .site-layout {
      background: none !important;
    }
  }
}
.section-dashboard {
  &__close-sidebar {
    position: absolute;
    top: 106px;
    left: 18px;
    z-index: 9;
    cursor: pointer;
    i {
      margin-right: 8px;
      font-size: 20px;
    }
    img {
      max-width: 22px;
      margin-right: 8px;
    }
    figure {
      margin-bottom: 0;
      margin-top: 20px;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        margin-right: 5px;
        color: var(--colors-litberry);
        transition: opacity 0.2s linear;
        text-transform: capitalize;
      }
    }
    &Close {
      span {
        opacity: 0;
      }
    }
    @media (max-width: $medium) {
      display: none;
    }
  }
}
.sidebar {
  &__menuItem {
    .subMenu-icon {
      margin-right: 10px;
      margin-top: -5px;
      width: 17px;
      object-fit: contain;
      height: 17px;
    }
    &--active {
      background: var(--colors-midberry);
      width: 100%;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
      color: var(--colors-white) !important;
      border-top-right-radius: 3px;
      position: relative !important;
      border-bottom-right-radius: 3px;
    }
    :hover {
      color: var(--colors-litberry) !important;
    }
  }
  &.ant-layout-sider {
    background: var(--colors-white);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
    font-family: "Lato";
    padding: 15px 0;
    border-radius: 0 10px 10px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    min-height: calc(100vh - 150px);
    @media screen and (max-width: 768px) {
      display: none;
    }
    &.ant-layout-sider-collapsed {
      width: 0 !important;
      min-width: 0 !important;
      padding: 20px 0;
      flex: 0 !important;
      overflow: hidden;

      @media screen and (max-width: 3840px) {
        margin-right: 0;
      }
      @media screen and (max-width: 1440px) {
        margin-right: 15px;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 21px;
    margin-bottom: 50px;
    &--none {
      opacity: 0 !important;
    }
    &__state {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      text-align: center;
      justify-content: flex-start;
      width: 100%;
      margin-top: -3px;
      padding: 0 20px;
      font-family: Roboto;
      position: relative;
      z-index: 3000;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--colors-pureberry);
      img {
        margin-right: 15px;
      }
      svg {
        margin-top: -1px;
        margin-right: 10px;
      }
      a:hover {
        color: var(--colors-litberry);
      }
    }
  }
  &__date {
    margin-bottom: 20px;
    .list-states {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .text {
        margin-right: 10px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        display: flex;
        align-items: center;
      }
      .circle {
        width: 7px;
        height: 7px;
        border-radius: 7px;
        margin-right: 5px;
        &--maturities {
          background: #eb5757;
        }
        &--vigor {
          background: #ffbb0d;
        }
        &--today {
          background: #5d30dd;
        }
      }
      .maturities {
        color: #eb5757;
      }
      .vigor {
        color: #ffbb0d;
      }
      .today {
        color: #5d30dd;
      }
    }
    .DayPicker-Month {
      margin-left: 3px;
    }
    .DayPicker-Caption {
      display: table-caption;
      margin-bottom: 0.5em;
      padding: 0 0.5em;
      text-align: left;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: var(--colors-pureberry);
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
    }
    .DayPicker-Weekday {
      display: table-cell;
      padding: 0;
      color: #8b9898;
      text-align: center;
      font-size: 0.875em;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 9px;
      text-align: center;
      color: #b2b2b2;
      margin-top: 25px;
    }
    .DayPicker-Day {
      display: table-cell;
      padding: 0px;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 10px;
      text-align: center;
      color: var(--colors-pureberry);
      cursor: pointer;
      height: 30px;
    }

    .DayPicker-Day-calendar {
      border-radius: 50%;
      position: relative;
      padding: 6.5px;
      margin: 0 1.3px;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: transparent;
    }
    .DayPicker--vigor {
      background: #ffbb0d;
      color: #ffffff;
    }
    .DayPicker--gradient {
      background: linear-gradient(90deg, #eb5757 50%, #ffbb0d 50%);
      color: #ffffff;
    }
    .DayPicker--today {
      background: #5d30dd;
      color: #ffffff;
    }

    .DayPicker--expiration {
      position: relative;
      background: #eb5757;
      color: #ffffff;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: transparent !important;
    }
    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: transparent !important;
      color: #ffffff;
      .DayPicker-Day-calendar {
        background-color: #7f14fc;
        border-radius: 50%;
      }
    }
    &Day {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-litberry);
      margin-bottom: 5px;
      padding-left: 16px;
    }
    .ant-picker {
      width: 100%;
    }
    .calendar-sidebar .ant-picker-date-panel .ant-picker-content th {
      width: 26px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      line-height: 7px;
      text-align: center;
      color: rgba(0, 0, 0, 0.543337);
    }
    .calendar-sidebar .ant-picker-content td {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 7px;
      text-align: center;
      height: 24px;
      max-height: 24px;
    }
    .calendar-sidebar .ant-picker-cell-in-view {
      color: rgba(0, 0, 0, 0.867782);
    }
    .calendar-sidebar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background: var(--colors-litberry) !important;
      border-radius: 50%;
    }
    .calendar-sidebar .ant-picker-content tr {
      height: 24px;
      max-height: 24px;
    }
    .calendar-sidebar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
      display: none !important;
    }
    .calendar-sidebar .ant-select-selector {
      border: none !important;
    }
    .calendar-sidebar .ant-select-selector span.ant-select-selection-item {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
    }
    .calendar-sidebar .ant-picker-calendar-header {
      flex-direction: row-reverse;
      padding: 12px 0 !important;
    }
    .calendar-sidebar .ant-picker-calendar-header .ant-picker-calendar-month-select {
      margin-left: 0;
    }
    .site-calendar-demo-card {
      background: var(--colors-white);
      border-radius: 0 0 20px 20px;
      overflow: hidden;
      margin: 16px 21px;

      table {
        height: auto;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid var(--colors-pureberry) !important;
      border-radius: 50%;
    }
  }
  &__data {
    padding: 16px;
    &__request {
      margin-bottom: 20px;
      &Title {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: var(--colors-black);
        opacity: 0.3;
        margin-bottom: 5px;
      }
      &Hour {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: var(--colors-black);
        opacity: 0.5;
        margin-bottom: 10px;
      }
      &Actions {
        a {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          &:first-child {
            color: #219653;
            margin-right: 17px;
          }
          &:last-child {
            color: #eb5757;
          }
        }
        &--seemore {
          a {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: var(--colors-litberry) !important;
          }
        }
      }
    }
  }

  &__menu {
    border-right: 0 !important;
    &Item {
      font-size: 12.5px;
      border-top: 1px solid #efefef;
      font-weight: 600;
      color: var(--colors-litberry);
      margin: 0px !important;
      padding: 28px 32px !important;
      height: auto !important;
      line-height: 0 !important;
      cursor: pointer;

      span.anticon.anticon-right.ant-collapse-arrow {
        position: absolute !important;
        right: 0 !important;
        left: auto !important;
        margin-top: 3px;
        color: var(--colors-frozenberry) !important;
      }

      .ant-collapse {
        width: 136%;
        margin-left: -32px;
        margin-top: -28px;
        min-height: 57px;
        &-header {
          height: 57px;
          display: flex;
          align-items: center;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          padding: 19px 28px !important;
          line-height: 17px !important;
          color: var(--colors-litberry) !important;
        }
      }
      .ant-collapse-content-box {
        padding: 0 !important;
        ul {
          li {
            margin-bottom: 0;
            padding-left: 30px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: var(--colors-pureberry);
            height: 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            border-top: 0.5px solid #e6e6e6;
          }
        }
      }
      .ant-collapse-item.ant-collapse-item-active {
        background: var(--colors-midberry);
        .ant-collapse-header {
          line-height: 17px !important;
          color: var(--colors-white) !important;
        }
      }
      &.ant-menu-item-selected {
        background-color: #6524e5 !important;
        color: white !important;
        &::after {
          display: none;
        }
      }
      &:hover {
        color: var(--colors-litberry);
      }
    }
  }
  &__logout {
    margin: 28px 32px 0;
    display: block;
    font-size: 12.5px;
    color: var(--colors-litberry);
    font-weight: 600;
    img {
      vertical-align: sub;
      margin-right: 16px;
    }
  }
  @media (max-width: $medium) {
    &.ant-layout-sider {
      position: absolute;
      margin-top: 64px;
      z-index: 3;
      max-width: 100% !important;
    }
  }
}

.home {
  &__head-completed {
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--colors-white);
    border-radius: 10px;
    gap: 20px;
    margin-bottom: 10px;
    @media (max-width: $s-medium) {
      flex-direction: column;
      padding: 8px;

      p {
        text-align: center;
      }
    }
    p {
      margin-bottom: 0;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-pureberry);
    }
    button {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background-color: transparent;
      color: #5d30dd;
      border: none;
      box-shadow: none;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: #5d30dd;
        border: none;
        box-shadow: none;
      }
      img {
        margin-right: 5px;
      }
    }
  }
  &__nav {
    display: flex;
    .link {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: #340aab;
      margin-right: 30px;
      margin-bottom: 15px;
    }
  }
  .alert-close {
    display: none !important;
  }
  @keyframes elementHidden {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  &__edit-profile {
    width: 100%;
    height: 70px;
    left: 264px;
    padding: 10px 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 4px;
    gap: 20px;
    p {
      margin-bottom: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
    .button {
      img {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 10px 20px;
      border-radius: 10px;
      margin: 0 auto;
      width: calc(100% - 40px);
      p {
        margin-bottom: 0px;
      }
    }
  }
  @media screen and (max-width: 3840px) {
    margin: 0 24px;
  }
  @media screen and (max-width: 768px) {
    &__content-edit-profile {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 1440px) {
    margin: 0 29px 0 36px;
  }
  &-section-responsive-date {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--colors-white);
      padding: 20px;
      h3 {
        font-family: Roboto;
        font-style: normal;
        margin: 0 !important;
        font-weight: bold;
        font-size: 15px;
        line-height: 150%;
        color: #5d30dd;
      }
      .date {
        h4 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          text-align: right;
          color: #5d30dd;
          margin: 0 !important;
        }
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          text-align: right;
          color: var(--colors-pureberry);
          margin: 0 !important;
        }
      }
    }
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 160px;

    &__reports {
      width: 50%;
      height: 100%;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 20px 0 0 0;
      margin-right: 3px;
      background: white;
      .ant-row {
        .ant-col {
          @media (max-width: $medium) {
            max-width: 26% !important;
          }
        }
        min-height: 65%;
        justify-content: space-around !important;
        text-align: center;
      }
      .divider {
        background-color: #4a3a7c10;
        width: 1px;
        @media (max-width: $medium) {
          height: 90px;
          margin-top: -5px;
        }
      }
      .ant-col {
        border-bottom: 1px solid #4a3a7c10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30% !important;
        flex: 30% !important;

        @media (max-width: $medium) {
          border-bottom: 0px solid #4a3a7c10;
        }
      }

      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: var(--colors-pureberry);
        margin: 0;
        margin-bottom: 20px;
      }

      span {
        font-family: Lato;
        font-style: normal;
        font-weight: 300;
        font-size: 38px;
        line-height: 1.2;
        color: var(--colors-litberry);
        margin-bottom: 11px;
      }

      .ant-btn {
        background: var(--colors-litberry);
        border-radius: 5px;
        width: 149px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }

      &Buttons {
        min-height: 35%;
        flex: 1;
        align-self: center;
        display: flex;
        align-items: center;
        button.ant-btn {
          background: var(--colors-frozenberry);
          max-width: 138px;
          margin: auto;
        }
        .ant-tooltip-disabled-compatible-wrapper {
          margin: auto;
        }
      }
    }

    &__page {
      width: 50%;
      height: 100%;
      background-color: var(--colors-white);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 0px 20px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 33px;
      margin-left: 3px;

      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        margin-bottom: 11px;
      }

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin-bottom: 11px;
      }

      &Buttons {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;

        span.primary.ant-tooltip-disabled-compatible-wrapper {
          background: var(--colors-oldberry) !important;
        }

        .primary {
          background: var(--colors-litberry);
          border-radius: 5px;
          border: none;
          display: flex !important;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 149px;
          margin: 0;

          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);

          button {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: var(--colors-white);
            border: none;
            background: none;
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          img {
            margin-right: 6px;
          }
        }

        .secundary {
          background: var(--colors-frozenberry);
          border-radius: 5px;
          border: none;
          display: flex !important;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 149px;
          margin: 0;
          margin-left: 8px;

          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);

          img {
            margin-right: 6px;
          }
        }
      }
    }
  }
  @media (max-width: $medium) {
    margin: 0px;
    overflow-x: hidden;
    &__header {
      flex-direction: column;
      height: auto;
      &__page {
        .primary,
        .secundary {
          width: 50% !important;
        }
      }
      &__page,
      &__reports {
        width: 100%;
        margin-top: 0px;
        box-shadow: none;
        padding: 15px;
        padding-top: 0px;
        border-radius: 5px !important;

        .home__header__reportsButtons {
          margin-top: 10px;
          .primary {
            button {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

.alert-notification {
  background: #ffffff;
  box-shadow: 11px 13px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 23px;
  position: absolute;
  right: 20px;
  width: 439.8px;
  height: 130.3px;
  z-index: 3000;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    &-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 13px;
      }
      h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 15px;
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
        margin-top: 2px;
      }
    }
    .btn-transparent {
      border: none;
    }
  }
  p {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    right: 5px;
    width: 98%;
    z-index: 10;
  }
}

.modal-calendar-dates {
  left: 20px !important;
  margin: 0 0 !important;
  top: 379px !important;
  width: 255px !important;
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header,
  .ant-modal-footer,
  .ant-modal-close-x {
    display: none;
  }
  .site-calendar-demo-card__conetent-dates {
    background-color: var(--colors-darkberry);
    padding: 15px;
    width: 255px;
    border-radius: 10px;
    left: 5px;
    top: 265px;
    z-index: 3000;
    max-height: 500px;
    overflow: auto;
    .item {
      margin-bottom: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid #60567b;
      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0px solid #60567b;
      }
      &__head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &--circle {
          margin-right: 5px;
          width: 7px;
          height: 7px;
          border-radius: 7px;
        }
        .disconnected {
          background: #eb5757;
        }
        .vigor {
          background: #ffbb0d;
        }
        &--name {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 0;
          color: #ffffff;
        }
      }
      &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--content {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .title {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 15px;
            color: #ffffff;
            margin-bottom: 0;
          }
          &--circle {
            margin-right: 5px;
            width: 26px;
            height: 26px;
            border-radius: 26px;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .btn-see-more {
          padding: 0 !important;
          background-color: transparent;
          border: none;
          box-shadow: none;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
          .arrow {
            margin-left: 5px;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}

.modal-meneric-editable {
  .open_modal,
  .open_modal:hover,
  .open_modal:active,
  .open_modal:focus {
    background-color: transparent;
    border-color: transparent;
    color: var(--colors-white);
    padding: 0;
  }
}
.modal-meneric-editable__modal {
  width: 888.96px !important;
  border-radius: 15px;
  min-height: 504px;
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    border-radius: 23px;
  }
  .anticon {
    color: var(--colors-white);
    font-size: 20px;
  }
  .ant-modal-body {
    background: var(--colors-12);
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    height: 100%;
    min-height: 504px;
    padding: 50px;
    display: flex;
  }
  .box-left {
    width: 70%;
    height: 100%;
    min-height: 450px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    .cont-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .btn--succes {
        background: var(--colors-frozenberry);
        border-color: var(--colors-frozenberry);
        margin-top: 30px;
        color: var(--colors-white);
      }
      .btn--cancel {
        background: var(--colors-white);
        border-color: var(--colors-white);
        margin-top: 10px;
        color: var(--colors-litberry);
      }
      .btn {
        width: 185.22px;
        border-radius: 5px;
        height: 50px;
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .box-right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 60px;
    color: var(--colors-white);
  }
  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150.19%;
    color: var(--colors-white);
    margin: 10px 0;
    max-width: 400px;
    &--two {
      margin-top: 60px !important;
    }
    .link {
      color: var(--colors-frozenberry);
      text-decoration: underline;
    }
  }
}
.modal-warnning {
  width: 700px !important;
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .anticon {
    color: var(--colors-pureberry);
  }
  .ant-modal-body {
    display: flex;
    justify-content: space-between;
    .cont-modal {
      width: 100%;
    }
  }
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    .cont-modal {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      .btns {
        margin-right: 30px;
      }
      .ant-checkbox {
        transform: translate(0px, -3px);
        &-inner {
          width: 11px;
          height: 11px;
        }
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fff;
        border-color: #B2B2B2;
      }
      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #5D30DD;
        transform: rotate(45deg) scale(0.8) translate(-50%, -50%);
        top: 33%;
        left: 6.5%;
      }
      .msj-check {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: var(--colors-pureberry);
      }
    }
    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
    button {
      margin: 0 10px;
      width: 63.7px;
      height: 51.65px;
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--colors-white);
    }
    .yes {
      background: #EB5757;
    }
    .no {
      background: #27AE60;
    }
  }

  .ant-modal-header, .ant-modal-footer {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .ant-modal-body {
      .cont-modal {
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}

.content-ReportsPia {
  margin-left: 15PX;
  margin-right: 15PX;
}
.ReportsPia {
  margin: 0 auto;
  background: #ffffff;
  max-width: 1280px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  .ant-collapse {
    background-color: #ffffff;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    padding: 20px !important;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #5D30DD;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    display: table;
    clear: both;
    content: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 33px;
    font-size: 23px;
    right: 16px;
    left: auto;
    transform: rotate(180deg);
    transition: 0.5s;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
    padding-top: 0px !important;
  }
  .ant-collapse-item.ant-collapse-item-active {
    .down {
      top: 18px !important;
      transition: 0.5s;
      transform: rotate(0deg) !important;
    }
  }
}
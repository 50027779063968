.onBoarding-responsive {
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;

  .ant-row {
    height: 100%;
  }

  &__navigation {
    width: 100%;
    background: #fff;
    background-size: 304px 358px;
    background-repeat: no-repeat;
    background-position-y: 190px;

    h3 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 30px;
      color: var(--colors-pureberry);
      margin: 0;
      @media only screen and (max-height: 667px) {
        font-size: 20px;
      }
    }
    .name {
      text-transform: capitalize;
    }
    p {
      margin: 10px 0;
      margin-bottom: 30px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
      width: 98%;

      @media only screen and (max-height: 667px) {
        margin: 10px 0;
        font-size: 10px;
      }
    }
    &__navigator {
      display: flex;
      flex-direction: column;
      padding: 30px 20px;
      padding-bottom: 30px;
      background-color: #ffffff;
      margin-top: -33px;

      div {
        width: 100%;
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        justify-items: flex-start;
        align-items: center;
        border-radius: 5px;

        @media only screen and (max-height: 667px) {
          height: 50px;
        }
      }
      .ant-btn-link {
        text-align: center;
        margin-top: 10px;

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: underline;
          color: var(--colors-litberry);
          margin: 0;
        }
      }

      .step-items {
        background: #fff;
        border: 0px solid var(--colors-litberry);
        box-shadow: none;
        border-radius: 0px;
        margin-bottom: 15px;
        .leaf {
          width: 12px;
          height: 14px;
          object-fit: contain;
        }

        .ant-typography {
          margin: 0;  
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--colors-pureberry);
          color: var(--colors-pureberry);
          margin-left: 10px;
        }

        p {
          display: none;
        }

        &__item {
          background: var(--colors-white);
          opacity: 0.35;
          border: 1.5px solid var(--colors-litberry);
          box-sizing: border-box;
          border-radius: 50%;
          margin-left: 21px;
          width: 22px;
          height: 22px;
          display: none;
        }
      }
    }

    &__start {
      background: var(--colors-white);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 10px 10px 0 0;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
      height: auto;
      padding: 30px 20px;
      padding-bottom: 33px;
      margin-top: -20px;

      h1 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: var(--colors-litberry);
        margin-bottom: 29px;
      }

      .ant-btn {
        width: 100%;
        height: 50px;
        background: var(--colors-frozenberry);
        border-color: var(--colors-frozenberry);
        border-radius: 5px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: var(--colors-white);
      }
      .link {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--colors-litberry);
        margin-top: 29px;
        margin-bottom: 0px;
      }
    }
  }
  &__steps {
    height: 100%;

    .ant-row {
      height: initial;
    }

    &Step {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      overflow-x: hidden;
      background-color: #ffffff;
      margin-top: -33px;
      .line-purple {
        margin-top: -13px;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--colors-litberry);
        margin-left: 85px;
        display: none;
      }
      .slick-dots {
        display: none !important;
      }
      .slick-slide {
        text-align: center !important;
        button {
          width: auto !important;
          margin: 0 auto !important;
        }
      }
      &__scroll {
        display: block;
        padding-bottom: 15px;
        padding-top: 25px;
        &::-webkit-scrollbar {
          width: 0;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: transparent;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: transparent;
        } 
        .onBoarding-responsive__stepsStep--active , .onBoarding-responsive__stepsStep--disabled {
          margin: 0px;
          margin-right: 25px;
          padding: 0 5px;
          min-width: auto;
          position: relative;
          z-index: 15;
          width: auto !important;
          background-color: transparent;
        }
        .onBoarding-responsive__stepsStep--disabled {
          background-color: transparent;
        }
      }

      .separator {
        width: 37px;
        height: 1px;
      }

      &--active {
        width: 143px;
        height: 34px;
        background: transparent;
        border: none;
        box-shadow: none;
        border-bottom: 2px solid #340AAB;
        border-radius: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:focus, &:active, &:hover {
          border-bottom: 2px solid #340AAB;
        }

        p {
          margin: 0;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #340AAB;
          opacity: 1 !important;
        }

        div {
          width: 22.2px;
          height: 22.2px;
          background: var(--colors-white);
          border-radius: 50%;
          margin-right: 17px;
        }
      }
      &--disabled {
        width: 143px;
        height: 34px;
        border: none;
        box-shadow: none;
        border: 0px solid var(--colors-litberry);
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        p {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          opacity: 0.3;
          color: var(--colors-pureberry);
          margin: 0;
        }

        div {
          width: 22.2px;
          height: 22.2px;
          opacity: 0.35;
          border: 1.5px solid var(--colors-litberry);
          box-sizing: border-box;
          border-radius: 50%;
          margin-right: 17px;
        }
      }
    }
    &Wrapper {
      padding: 30px 28px;
      width: 100%;
      background: var(--colors-white);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
      border-radius: 10px 10px 0px 0px;
      @media (max-width: 768px) {
        padding-bottom: 80px;
        margin-bottom: 50px;
        min-height: calc(100vh - 200px);
      }
      &__step {
        height: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
          display: block;
          height: auto;
          margin-bottom: 40px;
        }
      }

      &__nextStep {
        height: 15%;
        display: flex;
        justify-content: center;
        position: absolute;
        width: calc(100% - 54px);
        bottom: 0;

        .ant-btn {
          width: 100%;
          height: 50px;
          background: #35D8D0;
          border-radius: 5px;

          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: var(--colors-white);
        }

        .ant-btn:active,
        .ant-btn:hover,
        .ant-btn:focus {
          border: 2px solid #35D8D0;
          background: #35D8D0;
          color: var(--colors-white);
          border-radius: 5px;
        }
        @media screen and (max-width: 768px) {
          height: auto;
          margin: 20px 0;
        }
      }

      &__omitStep {
        height: 10%;
        display: flex;
        justify-content: center;

        .ant-btn {
          p {
            font-family: Roboto;
            font-style: italic;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            text-decoration-line: underline;
            color: var(--colors-litberry);
          }
        }
      }
    }
  }
}

.GmbCodeError {
	padding: 22px;
	.goBack, .goBack:hover , .goBack:active {
		position: absolute;
		left: 40px;
    background: transparent;
    border-color: transparent;
		.anticon {
			color: var(--colors-frozenberry);
			font-size: 20px;
		}
	}
  &__content {
		padding: 30px 50px;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		h4 {
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 150%;
			color: var(--colors-pureberry);
		}
	}
	&--card {
		background: var(--colors-white);
		box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
		padding: 50px;
		height: 370.3px;
		width: 100%;
		margin-top: 10px;
		display: flex;
		align-items: center;
		button {
			width: 180px;
			margin-top: 15px;
		}
		.right {
			width: 40%;
		}
		.left {
			width: 60%;
			.card_deal {
				background: var(--colors-white);
				box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				padding: 20px;
				width: 256.04px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 247.67px;
				p {
					margin: 0;
				}
				h4 {
					margin-bottom: 20px;
				}
				.imgs {
					display: flex;
					margin-top: 20px;
					span {
						margin-left: 10px;
						font-family: Roboto;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						line-height: 150.19%;
						color: var(--colors-pureberry);
					}
				}
			}
		}
		h3 {
			font-family: Lato;
			font-style: normal;
			font-weight: 900;
			font-size: 30px;
			line-height: 36px;
			color: var(--colors-pureberry);
		}
		p {
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 150%;
			max-width: 279.01px;
			color: var(--colors-pureberry);
		}
	}
}
.GmbCodeSuccess {
	.right {
		width: 50%;
	}
	.left {
		width: 50%;
	}
}
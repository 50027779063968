.recent-leads {
  width: 100%;
  height: 100%;
  padding: 20px 20px 37px 45px;
  margin: 15px 0px;
  border-radius: 0 0 20px 20px;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

  .ant-table-tbody {
    .ant-table-cell {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: var(--colors-pureberry);
      }
    }
  }
  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 26px;
    margin-bottom: 37px;
    margin-top: 22px;
    &__title {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      display: flex;
      align-items: center;
      margin: 0;
      img {
        margin-right: 15px;
      }
      .icon-b {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colors-pureberry);
        margin-right: 15px;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        i {
          &:before {
            color: var(--colors-white);
          }
        }
      }
    }
    
    .ant-btn {
      background: var(--colors-litberry);
      border-radius: 5px;
      width: 94px;
      height: 40px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
      }
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: var(--colors-midberry);
      border-radius: 5px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 94px;
      height: 40px;

      span {
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-white);
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    opacity: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--colors-litberry);
  }
  .ant-tabs-tab {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-litberry);
    opacity: 0.55;
    margin-bottom: 10px;
  }
  .ant-tabs-tab-disabled {
    color: var(--colors-litberry) !important;
    opacity: 0.55;
  }
  .ant-tabs-tab:hover {
    color: var(--colors-litberry);
    opacity: 1;
  }
  .ant-tabs-ink-bar {
    background: var(--colors-pureberry);
    opacity: 0.75;
    height: 1px !important;
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: 1px solid var(--colors-litberry);
      opacity: 0.25;
      border: 0.5px solid var(--colors-litberry);
    }
  }
  .table-overflow {
    .table-responsive {
      display: none;
    }
    @media (max-width: $medium) {
      width: 100%;
      padding-left: 5px;
      overflow: auto;
      .table-leads {
        display: none;
      }
      .table-responsive {
        display: block;
        .body-semall {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 130.69%;
          color: #828282;
        }
        .Button-Opciones {
          height: 100%;
          border: none;
          background-color: transparent;
          padding: 10px;
        }
        .conter-card {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0px;
        }
        .card-leads {
          width: 100%;
          margin-right: 10px;
          margin-top: 10px;
          margin-bottom: 10px;
          height: 64.2px;
          border: none;
          background: #FFFFFF;
          box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 5px;
          &--icon {
            width: 52px;
            height: 54.2px;
            background: #F7F7F7;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &--text {
            width: 70%;
            padding: 0 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h4 {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 120.19%;
              color: var(--colors-pureberry);
              text-align: left;
              white-space: pre-wrap;
            }
            p {
              text-align: left;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 130.69%;
              color: #000000;
              opacity: 0.3;
              margin: 0 !important;
            }
          }
          .arrow-green {
            margin: auto;
          }
          .btn-arrow-green {
            border: none !important;
            background-color: transparent;
            height: 100%;
          }
        }
      }
      .table-responsive {
        display: block;
      }
      &::-webkit-scrollbar {
        width: 8px; /* Tamaño del scroll en vertical */
        height: 8px; /* Tamaño del scroll en horizontal */
      }
    }
  }
  .ant-empty-image {
    display: none;
  }
  .table-leads {
    font-family: Roboto !important;
    .ant-table-cell {
      min-height: 300px;
      border-bottom: 0px solid #f0f0f0;
    }
    .ant-empty.ant-empty-normal {
      display: none;
    }
    
    .table-cell--line {
      background-color: var(--colors-pureberry);
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .ant-table-thead > tr > th {
      color: rgba(0, 0, 0, 0.35);
      font-weight: 500;
      text-align: left;
      background: var(--colors-white);
      border-bottom: none;
      font-size: 12px;
      line-height: 14px;
      &:nth-of-type(1) {
        padding: 16px 16px 16px 0;
      }
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    .ant-table tfoot > tr > td,
    .ant-table tfoot > tr > th {
      position: relative;
      padding: 16px 0;
      overflow-wrap: break-word;
    }

    .ant-table-cell {
      a {
        margin-left: 8px;
      }
    }
    .table-cell {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: var(--colors-black);
      &--name {
        img {
          margin-right: 10px;
        }
      }
      &--email {
        text-decoration-line: underline;
        color: var(--colors-highlight-azul);
      }
    }
    .custom-trigger-btn {
      background: var(--colors-litberry);
      color: #fff;
      width: 95px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      font-family: Lato;
      span {
        margin-left: 15px;
      }
    }
    .ant-pagination-item {
      border-radius: 100%;
      border-color: rgba(93, 48, 221, 0.5);
      a {
        color: var(--colors-pureberry);
      }
    }
    .ant-pagination-item-active {
      background: var(--colors-litberry);
      opacity: 0.85;
      a {
        color: white;
      }
    }
    .ant-pagination-item-link {
      border-radius: 100%;
      border-color: rgba(93, 48, 221, 0.5);
    }
    .button-action {
      background: var(--colors-litberry);
      width: 95px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: var(--colors-white);
      &:first-child {
        margin-right: 13px;
      }
    }
  }
  .actions-head {
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 20px 0;

    &__content {
      display: flex;
    }
    &__select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &Label {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--colors-black);
        opacity: 0.35;
        margin-right: 10px;
      }
      .ant-select-selector {
        border: 0.5px solid var(--colors-pureberry) !important;
        box-sizing: border-box;
        border-radius: 2px !important;
        height: 20px !important;
        input {
          height: 20px !important;
        }
        span {
          line-height: 20px !important;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-black);
        }
      }
      .ant-select-arrow {
        svg {
          fill: var(--colors-pureberry);
        }
      }
    }
    &__search {
      display: flex;
      align-items: center;
      figure {
        margin-bottom: 0;
        margin-left: 10px;
      }
      &Input {
        border: 0.5px solid var(--colors-pureberry) !important;
        box-sizing: border-box;
        border-radius: 2px !important;
        height: 20px !important;
      }
    }
  }
  &__leadType,
  .ant-btn {
    &--selected {
      border-color: #40a9ff !important;
      color: #40a9ff !important;
    }

    &--notSelected {
      border-color: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.65) !important;
    }
  }
  @media (max-width: $medium) {
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 50px;
    padding-bottom: 70px;
    &__head {
      margin-right: 10px;
    }
		
    .ant-table-wrapper {
      min-width: 1000px;
    }
  }
}
.leads-details__modal {
  @media (max-width: $medium) {
    .ant-modal-header {
      border-bottom: 0px solid #f0f0f0;
    }
  }
  .btn-close-modal {
    display: none;
  }
}
@media (max-width: $medium) {
  .leads-details__modal {
    left: 0 !important;
    width: 100% !important;
		top: 50px !important;
		height: 100% !important;
    max-width: 100% !important;
    .ant-modal-title{
      margin-left: 30px;
    }
    .btn-close-modal {
      background-color: transparent;
      border-color: transparent;
      position: absolute;
      box-shadow: none;
      display: unset;
      top: 13.5px;
      z-index: 1000;
      .anticon {
        color: var(--colors-frozenberry);
      }
    }
		.ant-modal-content {
			height: auto !important;
    }
    .leads-details__header {
      background: var(--colors-white);
      display: none;
    }
    .ant-modal-header {
      padding: 17px 20px;
      @media (max-width: $medium) {
        background: #f8f8f8;
      }
      .ant-modal-title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--colors-pureberry);
      }
    }
    .leads-details {
      &__wrapper {
        flex-direction: column;
        &Sidebar {
					width: 100%;
					.sidebar-modal__buttons {
						margin-top: 20px;
					}
        }
        &Container {
					padding-left: 0;
					width: 100%;
          margin-top: 150px;
          @media (max-width: $medium) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
.recent-leads-detail {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 26px;

  &__button {
    background: var(--colors-litberry);
    border-radius: 5px;
    width: 94px;
    height: 40px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-white);
  }

  &__button:hover {
    background: var(--colors-midberry);
    border-radius: 5px;
    width: 94px;
    height: 40px;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-white);
  }
}

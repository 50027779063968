.ReportsSummary {
  &--content {
    padding: 20px;
    background: #1C0E42;
    border-radius: 0px 0px 10px 10px;
    .table-metricks-medium {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      
      .apexcharts-legend.apexcharts-align-center.position-bottom {
        display: none;
      }
      .apexcharts-toolbar {
        display: none;
      }
      .chart-one {
        .apexcharts-toolbar {
          display: none;
        }
      }
      .box-table {
        width: 65%;
      }
      .box-number {
        width: 20%;
        display: flex;
        height: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #2c314e;
        img {
          margin: 10px 0;
        }
        &-small {
          width: 15%;
        }
        .body-bold ,.body-regular,.sub-title{
          color: #ffffff !important;
        }
        .body-regular,.sub-title{
          margin-bottom: 0;
        }

        .number {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 72px;
          line-height: 86px;
          display: flex;
          align-items: center;
          background: var(--colors-smoothieberry);
          -webkit-background-clip: text;
          max-width: 100% !important;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0;
          margin-top: -7px;
        }
      }
    }
    .table-metricks-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      .content-chart {
        width: 32%;
        margin-top: 10px;
        height: auto;
        background-color: #ffffff;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        position: relative;
        .item-select {
          position: absolute;
          left: 20px;
          top: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          z-index: 1000;
          .body-bold {
            margin-bottom: 0;
            margin-right: 10px;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: #fff;
            border: 0px solid #d9d9d9;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
            padding-right: 18px;
            color: var(--colors-pureberry);
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            margin-top: 5px;
          }
          .ant-select-arrow {
            color: var(--colors-pureberry);
          }
        }
        .tower {
          width: 200px;
          display: flex;
          flex-direction: column;
          border-left: 1px solid #eeeeee;
          align-items: center;
          justify-content: center;
          .body-bold {
            margin-bottom: 3px;
          }
          &-table {
            width: 75px;
            height: 145px;
            border-top-right-radius: 5px;
            .up {
              background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
              height: 85.5%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-top-right-radius: 3px;
              border-top-left-radius: 3px;
              p ,span{
                text-align: center;
                color: #FFFFFF;
                margin: 0;
              }
              p {
                font-size: 8px;
              }
              span {
                font-size: 6px;
              }
            }
            .down {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #1C0E42;
              height: 13.5%;
              width: 100%;
              flex-direction: column;
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;
              p ,span{
                margin: 0;
                text-align: center;
                color: #FFFFFF;
              }
              p {
                font-size: 8px;
              }
              span {
                font-size: 6px;
              }
            }
          }
          .body-bold {
            text-align: center;
          }
        }
        .chart-point {
          width: 100% !important;
        }
        .chartPoint,.donut {
          width: 100%;
        }
        .donut {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .chartPoint,.chartbar {
          margin-top: 40px;
        }
        .chartPoint,.donut {
          .apexcharts-toolbar {
            display: none;
          }
        }
        .donut {
          
        }
      }
    }
    &--head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .body-regular, .h3 {
        color: #FFFFFF;
        margin-bottom: 0;
      }
      .constnt--select {
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: transparent;
          border: none;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 150%;
          color: #FFFFFF;
          &:hover, &:active, &:focus {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
  &--table {
    padding: 20px;
    padding-top: 50px;
  }
  .generate-report {
    width: 100%;
    border-top: 1px solid #d8d8d8;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    .button {
      margin: 0 5px !important;
    }
  }
}
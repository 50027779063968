.MaterialSocialNetworks {
  background: var(--colors-white);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px 45px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__container {
    width: 100%;
    display: flex;
  }
  .circle {
    border-radius: 50%;
    border: 2px solid var(--colors-litberry);
    width: 20px;
    margin-right: 10px;
    height: 20px;
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    .triangle-right {
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-left: 7px solid var(--colors-litberry);
      border-bottom: 3px solid transparent;
    }
  }
  .btn-see-more-article {
	  background: transparent;
	  border-color: transparent;
	  font-family: Lato;
	  font-style: normal;
	  font-weight: bold;
	  font-size: 14px;
	  line-height: 17px;
	  color: var(--colors-litberry);
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 243.15px;
	  img {
		width: 20px;
		margin-right: 10px;
		height: 20px;
		object-fit: contain;
	  }
  }

  .material {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      margin-bottom: 8px;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
  }
  .networks {
    width: 50%;
    display: flex;
    justify-content: center;
    &__card {
      background: #f7f7f7;
      border-radius: 5px;
      height: 94px;
      margin: 0 20px;
      width: 94px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        margin: 10px 0 0 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 111%;
        color: var(--colors-pureberry);
      }
    }
  }
  @media screen and (max-width: 768px) {
	  padding: 15px;
	  &__container {
		flex-direction: column;
	}
    .material {
      width: 100%;
    }
    .networks {
      width: 100%;
      justify-content: space-around;
      &__card {
        margin: 10px 5px;
      }
    }
  }
}

.full-height {
  height: 100%;
}
button {
  text-shadow: none !important;
}

.ant-modal-mask {
  backdrop-filter: blur(20px);
}

.DayPicker-NavButton--prev {
  background-image: url("../../assets/icon/prev.svg") !important;
  left: 0 !important;
  top: 12px !important;
}
.DayPicker-NavButton--next {
  right: 0 !important;
  top: 12px !important;
  background-image: url("../../assets/icon/next.svg") !important;
}

.ant-menu-title-content {
  overflow: visible !important;
  .box-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  .luna {
    margin-left: 10px;
  }
}
.card-alert-content {
  text-align: left !important;
}
.ant-dropdown-menu-title-content {
  text-align: center;
}

.ant-dropdown-placement-bottomRight {
  .menu-edit {
    display: flex;
    flex-direction: column;
    background-color: var(--colors-litberry);
    padding: 10px;
    a {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-white);
      margin: 5px 0;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.link-dash {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.message--empty {
  font-family: Lato;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 19px !important;
  line-height: 150% !important;
  background: linear-gradient(50deg, #00dbd0 0%, #7b3bf9 100%);
  -webkit-background-clip: text;
  max-width: 100% !important;
  -webkit-text-fill-color: transparent;
}
.form-item {
  .input-form-generic {
    background: var(--colors-white);
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    &--textarea {
      height: 177px;
    }
  }
  .select-button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--colors-white);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .button-radio {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-pureberry);
      background-color: transparent;
      border-radius: 0;
      border: 0px solid transparent;
      border-top: 1px solid #e5e5e5;
      span {
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        /* display: -webkit-box; */
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .check-svg {
        display: none;
      }
      &--0 {
        border-top: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
      &--active {
        background: var(--colors-midberry);
        color: var(--colors-white);
        .check-svg {
          display: unset;
          path {
            fill: var(--colors-frozenberry);
          }
        }
      }
      &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}
.icon-check-svg {
  margin-right: 15px;
  path {
    fill: var(--colors-white);
  }
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active,
button[disabled] {
  color: var(--colors-white) !important;
  background: var(--colors-oldberry) !important;
  border-color: var(--colors-oldberry) !important;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-custom {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--colors-white);
  border-radius: 5px;
  height: 50px;
  border: none;
  appearance: none;
  &--primary {
    background: var(--colors-litberry);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  }
  &--secundary {
    background: var(--colors-frozenberry);
    &--desisable {
      color: var(--colors-white);
      background: var(--colors-oldberry);
      border-color: var(--colors-oldberry);
    }
  }
  &--preview {
    background: white;
    color: var(--colors-litberry) !important;
    width: 100%;
  }
  &--white {
    background: white;
    color: var(--colors-litberry);
    &--desisable {
      color: var(--colors-white);
      background: var(--colors-oldberry);
      border-color: var(--colors-oldberry);
    }
  }
}
.input-custom {
  background: var(--colors-white);
  border-radius: 2px !important;
  height: 40px;
  border: none !important;
  color: #bfbfbf !important;
  input {
    font-family: "Lato";
  }
  .ant-input-prefix {
    margin-right: 13px;
  }
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 10px !important;
  line-height: 130.69%;
  color: #eb5757;
}

body {
  font-family: "Lato";
}
.container {
  max-width: 1440px;
  margin: 0 auto;
}
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
.has-txt-end {
  text-align: end;
}
.has-margin-right-15 {
  margin-right: 15px;
}
.button--fixed {
  display: none;
}
.ant-layout-content {
  background: #eeeeee;
}
.button {
  min-width: 160px;
  height: 50px !important;
  margin: 0 2px !important;
  font-family: Lato;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center;
  border-radius: 5px !important;
  &--primary {
    background: var(--colors-litberry) !important;
    color: var(--colors-white) !important;
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--secundary {
    border-radius: 5px !important;
    border: none !important;
    background: var(--colors-frozenberry) !important;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--colors-white) !important;
  }
  &--tertiary {
    background: var(--colors-white) !important;
    border: 2px solid var(--colors-litberry) !important;
    color: var(--colors-litberry) !important;
    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active,
    button[disabled] {
      color: var(--colors-white) !important;
      background: var(--colors-oldberry) !important;
      border-color: var(--colors-oldberry) !important;
      text-shadow: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
    }
  }
  &--fourth {
    background: var(--colors-midberry);
  }
  &--secundary:hover,
  &--primary:hover,
  &--tertiary:hover {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box !important;
  }
}

@media screen and (max-width: 960px) {
  .header {
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .header {
    &__information {
      a {
        display: none;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .button--fixed {
      width: 100%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-white);
      text-align: left;
      padding-left: 49px;
      border: none;
      height: 70.85px;
      background: var(--colors-whatsapp);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      display: block;
      position: relative;
    }
  }
}
.ant-message {
  .ant-message-notice-content {
    max-width: 550px;
    width: 100%;
    padding: 0 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 130.69%;
    text-align: center;
    color: var(--colors-white);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15) !important;
  }
  .ant-message-custom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 60px;
    width: 100%;
    max-width: 900px;
    span {
      font-size: 17px;
      line-height: 19px;
    }
    .anticon {
      &::before {
        content: "";
        position: absolute;
        background: white;
        width: 90%;
        height: 90%;
        top: 50%;
        left: 50%;
        display: block;
        z-index: 0;
        border-radius: 100%;
        transform: translate(-50%, -50%);
      }
      svg {
        position: relative;
      }
    }
  }
  .ant-message-success {
    background: #47d8d0;
    padding-left: 20px;
    .anticon {
      color: #47d8d0;
      font-size: 37px;
    }
  }
  .ant-message-error {
    background: #eb5757;
    .anticon {
      color: #eb5757;
      font-size: 24px;
    }
  }
}
.ant-form-item-has-error {
  .ant-form-item-explain,
  .ant-form-item-split {
    background: var(--colors-white);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 30px;
    width: 90%;
    margin: -5px auto 0;
    padding-bottom: 5px;
  }
  .input-custom {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  }
}

.ant-layout-content {
  background: none;
}

.modal-loader {
  .ant-modal-content {
    background-color: var(--colors-white) 00 !important;
    box-shadow: none !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
}
input::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: var(--colors-black);
  opacity: 0.4;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
  justify-content: flex-start;
  div {
    margin: 10px 0;
  }
}

.button--secondary {
  background: var(--colors-frozenberry) !important;
  border-radius: 5px !important;
  font-family: Lato !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: var(--colors-white) !important;
  height: 50px !important;
}

//card generic
.cont-card {
  max-width: 460px;
  min-height: 70px;
  @media (max-width: $medium) {
    width: 100%;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    min-height: 70px;
    .button--secondary {
      border: none !important;
      height: 40px !important;
      align-items: center;
      justify-content: center;
    }
    a {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: var(--colors-litberry);
    }
  }
}
textarea {
  resize: none !important;
}
.icon-insurance {
  margin-right: 5px;
}
.button-card-secundary {
  display: flex !important;
  font-size: 15px !important;
  align-items: center;
  img {
    margin-top: 1px;
  }
}
.link-disabled {
}
.card {
  width: 100%;
  height: 254px;
  position: relative;
  border-radius: 10px;
  display: flex;
  margin-right: 7px;
  max-width: 458.84px;
  background-repeat: no-repeat;
  background-size: cover;
  .ShareButton {
    position: absolute;
    z-index: 200;
    right: -7px;
    top: 6px;
    width: 40px;
    .secundary--active {
      margin-left: -150px;
    }
    .icons {
      margin-left: -175px;
      background-color: #f5f5f57d;
      border-radius: 5px !important;
      margin-top: 2px;
    }
    button {
      background-color: transparent;
      span {
        display: none;
      }
    }
  }
  .content-transparent {
    width: 100%;
    height: 100%;
    background: var(--colors-gradient-image);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    border-radius: 10px;
  }
  &__background {
    width: 100%;
    height: 254px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    border-radius: 5px;
  }
  &__content {
    z-index: 1;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 254px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55));
    border-radius: 5px;

    &Header {
      padding: 18px 20px;
      height: 20%;
      img {
        height: 24px;
        width: 81px;
        object-fit: contain;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        width: 100% !important;
        color: var(--colors-white);
      }
    }

    &Body {
      height: 80%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      img {
        max-width: 17px;
      }

      &--active {
        display: flex;
        flex-direction: column;

        &__insurances {
          width: 100%;
          height: 85%;
          display: flex;
          align-items: flex-end;
          flex-direction: row;
          padding: 0 15px 10px 19px;

          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: var(--colors-white);
            margin: 0;
            width: 80px;
          }
          .insurance {
            width: 26px;
            height: 26px;
            background: var(--colors-white);
            border-radius: 50%;
            display: flex;
            margin-right: 10px;
            justify-content: center;
            align-items: center;
          }
        }
        &__visibility {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: 15%;
          background: var(--colors-pureberry);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          padding: 0 15px 0 19px;
          border-radius: 0 0 5px 5px;

          img {
            cursor: pointer;
          }

          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: var(--colors-white);
            margin: 0;
          }
        }
      }

      &--disabled {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        &__insurances {
          display: flex;
          flex-direction: row;
          padding: 16px 19px;

          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: var(--colors-white);
            margin: 0;
            width: 80px;
          }

          .insurance {
            width: 26px;
            height: 26px;
            background: var(--colors-white);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }
        &__visibility {
          display: flex;
          flex-direction: row;
          padding: 8px 15px;

          img {
            cursor: pointer;
          }

          p {
            display: none;
          }
        }
      }
    }
  }
}
.ant-table-tbody > tr > td {
  font-family: Roboto;
}

.card-alert--berry {
  width: 550px !important;
}
.card-alert {
  width: 400px;
  padding: 30px;
  padding-top: 15px;
  border-radius: 10px;
  &--berry {
    width: 550px;
  }
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 150%;
    background: linear-gradient(
      90deg,
      rgba(93, 51, 228, 0.74) 1.3%,
      rgba(8, 205, 210, 0.74) 75.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .description {
    max-width: 350px;
    width: 300px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: var(--colors-black);
    opacity: 0.5;
    white-space: normal;
  }
}
.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
  display: none;
}

.title-blanck {
  color: red;
}

.nav-bar-footer-res {
  display: none;
  .rbd-bko94l240a4-cw {
    padding: 0 !important;
  }
  .notification-button-slider {
    position: relative;
    .circle {
      position: absolute;
      background: #eb5757;
      width: 17px;
      height: 17px;
      border-radius: 17px;
      top: -7px;
      left: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        color: #ffffff;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    bottom: 0;
    z-index: 3000;
    height: 52px;
    background: var(--colors-litberry);
    width: 100%;
    .header--item {
      display: flex;
    }
    .custom-trigger-btn {
      position: relative;
      .alert {
        position: absolute;
        background: #EB5757;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 0px;
        right: -4px;
      }
    }
    a {
      cursor: pointer;
    }
    .border-status {
      border: 2px solid #25d366;
      border-radius: 50px;
    }
    .user {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      &-img {
        width: 28px;
        height: 28px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}
.ant-menu-vertical {
  .title-section {
    height: 40px;
    background-color: #f6f6f6;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    width: 90%;
    p {
      margin-bottom: 0;
    }
  }
}
.button-mode-dark {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-right: -15px;
  svg {
    margin-top: 2px;
    margin-left: 10px;
  }
}
.ant-message {
  z-index: 4000 !important;
}
.eUGVYL {
  margin-top: 60px !important;
}
.empty-table {
  .ant-empty-image svg {
    display: none;
  }
  .text-emty {
    font-family: Lato;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--colors-pureberry);
    margin: 0;
    margin-bottom: 0px;
    text-align: left;
    width: 100%;
    font-weight: 900 !important;
    font-size: 19px !important;
    line-height: 150% !important;
    background: linear-gradient(50deg, #00dbd0 0%, #7b3bf9 100%);
    -webkit-background-clip: text;
    max-width: 100% !important;
    -webkit-text-fill-color: transparent;
    display: flex;
  }
}
iframe {
  button,
  .kmKZTV {
    bottom: 60px !important;
  }
  button.sc-htpNat.sc-1w3tvxe-1.cmFjCL.sc-EHOje.bsmmQV {
    bottom: 60px !important;
  }
}
.cmFjCL.cmFjCL.cmFjCL {
  bottom: 60px !important;
}
button.sc-htpNat.sc-1w3tvxe-1.cmFjCL.sc-EHOje.bsmmQV {
  bottom: 60px !important;
}
@media (max-width: 768px) {
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
    border-radius: 0px 0px 0px 0px;
    position: relative;
    box-shadow: none;
    border: none;
  }
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto !important;
    min-height: 40px !important;
    line-height: 40px !important;
    margin-top: 10px !important;
  }
  .title-name {
    text-align: center;
    margin-bottom: 0 !important;
    margin-top: 0px;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff !important;
  }
  .item-title-menu {
    height: 43px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #f7f7f7;
    padding-left: 20px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 150.19%;
      color: var(--colors-pureberry);
      margin-bottom: 0;
    }
  }

  #zendesk-button-iframe {
    margin-bottom: 40px;
  }
}
iframe {
  @media (max-width: 768px) {
    bottom: 60px !important;
  }
}
#home {
  iframe {
    @media (max-width: 768px) {
      bottom: 0px !important;
    }
  }
  margin-bottom: -39px !important;
}

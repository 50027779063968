.FeedBack {
  min-height: 100vh;
  padding-top: 35px;
  margin-top: -35px;
  background: #f6f6f6;
  position: relative;
  .apexcharts-toolbar {
    display: none !important;
  }
  .container {
    background: #f6f6f6;
    padding: 0 20px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 0;
    .column {
      width: 40%;
      &-left {
        width: 60%;
      }
    }
  }
  .h1-gradient {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 105%;
    background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 10px;
  }
  &--dark {
    .Policies {
      .box-big-before {
        background-color: #1c0e42 !important;
        border: none;
      }
      .box-mini {
        .button {
          border: 1px solid #e4e4e4 !important;
          background-color: #1c0e42 !important;
          .body-bold {
            color: #ffffff;
          }
          .image {
            box-shadow: none;
          }
        }
      }
    }
    .btn-center {
      .button-seemore {
        color: #00dbd0 !important;
      }
    }
    background-color: #1c0e42;
    .SectionPolicy,
    .SectionPolicy__responsive {
      background: #1c0e42 !important;
    }
    .filter-absolute {
      border: 1px solid #35d8d0;
    }
    .container {
      background-color: #1c0e42;
      .HeaderFeedBack {
        border: 1px solid #35d8d0;
      }
      .mobile-h2 {
        color: $white !important;
      }
      .small-detail {
        span {
          color: $white !important;
        }
      }
      .button-transparent {
        color: #35d8d0 !important;
      }
      .SectionPolicy {
        .ant-tabs-tab-btn {
          color: #35d8d0;
        }
        .ant-tabs-ink-bar {
          background: #35d8d0;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #35d8d0;
        }
        .circle {
          background: #4b3a7c !important;
          .body-detail {
            color: #35d8d0;
          }
        }
        .image {
          box-shadow: 2px 1px 5px #000000;
        }
        .CardInsurance__content__head {
          .body-bold {
            color: #ffffff;
          }
        }
      }
      .SectionCommissions,
      .SectionPolicy,
      .SectionFeedBack {
        border: 1px solid #35d8d0;
        background: #1c0e42 !important;
        .box-left {
          border-right: 1px solid #00dbd0;
        }
        .loader {
          p {
            color: #ffffff !important;
          }
        }
        .content-filters {
          .btn {
            background-color: #1c0e42;
            border: 1px solid #f7f7f7;
            color: #ffffff;
            &--active {
              border: 0px solid #f7f7f7;
            }
          }
        }
        .apexcharts-yaxis-texts-g,
        .apexcharts-yaxis-texts-g div {
          .text {
            color: #ffffff !important;
            filter: brightness(5);
          }
          color: #ffffff !important;
          filter: brightness(5);
        }
        .LongGraph tspan,
        title {
          color: #ffffff !important;
          opacity: 1;
          filter: brightness(5);
        }
      }
      .SectionFeedBack {
        .btn-tab {
          color: #00dbd0;
        }
      }
    }
    @media (max-width: $medium) {
      .SectionPolicy,
      .HeaderFeedBack,
      .SectionCommissions {
        border: none !important;
        border-radius: 0;
        .sub-title,
        .small-detail {
          color: #ffffff !important;
        }
        .content-r {
          background: #1c0e42 !important;
          .small-detail,
          .sub-title {
            color: #ffffff;
          }
        }
        &__responsive {
          background: #1c0e42;
        }
      }
      .FeedBack__content {
        background-color: #1c0e42;
      }
    }
  }
  @media (max-width: $medium) {
    // .SectionPolicy, .SectionFeedBack, .SectionCommissions {
    // 	display: none !important;
    // }
    .SectionPolicy__head .button-transparent {
      display: none !important;
    }
    .SectionFeedBack {
      display: none;
    }
    &__content {
      flex-direction: row-reverse;
      padding: 0;
      .column {
        width: 50%;
        &-left {
          width: 50%;
        }
      }
      .SectionPolicy {
        margin: 0;
        padding: 10px 15px;
        min-height: auto;
        .ant-tabs {
          display: none;
        }
        &__head {
          button {
            // display: none;
          }
        }
      }
      .SectionCommissions {
        padding: 10px 15px;
        .box-left,
        .box-r {
          display: none;
        }
      }
    }
    padding: 0px !important;
    .container {
      padding: 0px !important;
      background: #f6f6f6;
    }
  }
}

.SectionFeedBack {
  border-radius: 10px;
  background-color: $colors-white;
	display: flex;
	margin-bottom: 18px;
	.column {
		width: 70%;
		padding: 20px;
		.mobile-h2 {
			margin-bottom: 0;
		}
		&-rigth {
			width: 30%;
			border-left: 1px solid #E5E5E5;
			.mobile-h2 {
				margin-bottom: 5px;
			}
			.small-detail {
				margin-top: 3px;
				&--date {
					text-transform: uppercase;
				}
				span {
					color: #4B3A7C;
				}
			}
			p {
				margin-bottom: 0;
			}
		}
		.btn-tab {
			font-family: 'Lato';
			font-style: normal;
			box-shadow: none;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			color: #5D30DD;
			border: none;
			background-color: transparent;
			margin-top: -4px;
			.number {
				color: #B2B2B2;
				margin-left: 3px;
			}
		}
		&-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active, button[disabled] {
				background: transparent !important;
				color: var(--colors-oldberry) !important;
				text-shadow: none;
				box-shadow: none;
			}
		}
		&-head {
			display: flex;
			justify-content: flex-start;
			
		}
	}
	.conetnt-cards {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		margin-bottom: 5px;
		margin-top: 10px;
		.empty {
			p {
				font-family: 'Lato';
				font-style: normal;
				font-weight: 900;
				font-size: 30px;
				line-height: 36px;
				background: linear-gradient(90deg, #00DBD0 0.01%, #7B3BF9 99.98%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}
	}
	.cardFeedback {
		width: 100%;
		min-height: 91px;
		padding: 10px;
		background: #FFFFFF;
		box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 0;
		&_icon {
			margin-right: 10px;
		}
		p {
			margin: 0 !important;
		}
		.flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 5px;
			.insurances {
				display: flex;
				.circle {
					width: 26px;
					height: 26px;
					background: #FFFFFF;
					box-shadow: 0.3px 0.4px 5px 0.1px #bcbcbc;
					border-radius: 50%;
					margin-left: -10px;
					&:first-child {
						margin: 0;
						margin-left: 10px;
					}
				}
			}
		}
		.btn-arrow {
			border: none;
			background-color: transparent;
			box-shadow: none;
		}
	}
}

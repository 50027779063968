.offices {
  padding: 22px 24px 32px 24px;

  &__card {
    height: 410px;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
    background-image: url(../../../../../../../assets/images/location.jpg) !important;

    .ant-card-body {
      padding: 0 35px 0 40px;
      height: 100%;
    }

    &Header {
      height: 15%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin: 0;
      }
    }

    .button {
      position: absolute;
      top: 13px;
      right: 35px;
      @media screen and (max-width: $medium) {
        right: 10px;
        padding: 0 !important;
      }
      .ant-btn {
        background: var(--colors-frozenberry);
        border-radius: 5px;
        width: 166px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        background: #2cb6af;
        border-radius: 5px;
        width: 166px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }
    }

    &Wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 85%;

      &__content {
        width: 90%;
        height: 100%;
        background: var(--colors-white);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;

        &Search {
          width: 40%;
          box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
          padding: 28px 19px 13px 22px;

          .ant-empty.ant-empty-normal {
            display: none;
          }

          h6 {
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: var(--colors-pureberry);
            margin-left: 3px;
            height: 10%;
          }

          .ant-form {
            height: 20%;
          }

          .ant-input-affix-wrapper {
            height: 40px;
            background: var(--colors-white);
            border: 1px solid #bdbdbd;
            border-radius: 5px;
          }

          .ant-list {
            height: 55%;
            overflow: auto;

            &::-webkit-scrollbar {
              background: #5f5f5f2c;
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #5f5f5f3b;
            }
          }

          .ant-list-item {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: -0.02em;
            color: var(--colors-black);
          }

          &__logo {
            height: 15%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            bottom: 0;
          }
        }

        &Add {
          padding: 104px 0 0 63px;
          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 11.1196px;
            line-height: 13px;
            letter-spacing: -0.02em;
            color: var(--colors-pureberry);
            margin: 0;
          }

          h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 13.3435px;
            line-height: 125.19%;
            color: var(--colors-litberry);
            margin-bottom: 15px;
          }

          .ant-btn {
            background: var(--colors-frozenberry);
            border-radius: 5px;
            width: 143px;
            height: 40px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-white);
            }
          }

          .ant-btn:hover,
          .ant-btn:focus,
          .ant-btn:active {
            background: #2cb6af;
            border-radius: 5px;
            width: 143px;
            height: 40px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-white);
            }
          }
        }
      }
    }

    &Offices {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      height: 80%;
      padding-bottom: 20px;

      &::-webkit-scrollbar {
        background: #5f5f5f2c;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #5f5f5f3b;
      }
    }

    &Office {
      width: 256.04px;
      height: 246px;
      max-width: 256.04px;
      max-height: 246px;
      min-width: 256.04px;
      min-height: 246px;
      background: var(--colors-white);
      box-shadow: 8px 4px 22px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin-right: 10px;
      margin-left: 10px;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;

      &__edit {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 11px;
        height: 15%;
        padding-left: 14px;
        padding-right: 9px;

        .ant-btn {
          padding: 0;
        }
      }

      &__info {
        padding-left: 14px;
        padding-right: 9px;
        height: 55%;
        display: flex;
        flex-direction: column;

        h3 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--colors-litberry);
          margin-bottom: 23px;
        }

        &Address {
          max-height: 74px;
          overflow: auto;

          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: var(--colors-pureberry);
            text-transform: uppercase;
            margin: 0;
          }

          &::-webkit-scrollbar {
            background: #5f5f5f2c;
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #5f5f5f3b;
          }
        }
      }

      &__verification {
        display: flex;
        justify-content: space-between;
        height: 15%;
        padding-left: 14px;
        padding-right: 9px;
        &__action {
          .validate {
            color: var(--colors-frozenberry);
            text-decoration: underline;
          }
        }
        img {
          width: 15px;
          height: 15px;
        }
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          color: var(--colors-pureberry);
          margin-left: 10px;
        }
      }

      .ant-divider-horizontal {
        margin-top: 16px;
        margin-bottom: 22px;
      }

      &__predetermined {
        height: 15%;
        display: flex;
        justify-content: center;

        &--visible {
          display: flex;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: var(--colors-pureberry);
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-left: 14px;
          padding-right: 13px;
          width: 100%;
          height: 100%;
          border-top: 1px solid #0000001a;
          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 7.96478px;
            line-height: 10px;
            letter-spacing: -0.02em;
            color: var(--colors-white);
            margin: 0;
          }
        }
        .icon-eyes {
          font-size: 13px;
        }
        .icon-visible {
          &::before {
            color: var(--colors-white);
          }
        }

        &--notVisible {
          display: flex;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: white;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-left: 14px;
          padding-right: 13px;
          width: 100%;
          height: 100%;
          border-top: 1px solid #0000001a;
          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 7.96478px;
            line-height: 10px;
            letter-spacing: -0.02em;
            color: var(--colors-pureberry);
            margin: 0;
          }
        }
      }
      &__switch {
        display: flex;
        align-items: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          line-height: 130.69%;
          color: var(--colors-pureberry);
          opacity: 0.2;
          margin-left: 9px;
          margin-right: 9px;
        }

        &--selected {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          line-height: 130.69%;
          color: var(--colors-litberry);
          margin-left: 9px;
          margin-right: 9px;
        }

        &--deselected {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          line-height: 130.69%;
          color: var(--colors-pureberry);
          margin-left: 9px;
          margin-right: 9px;
        }

        .switch-wrap {
          cursor: pointer;
          background: #f7f7f7;
          padding: 0;
          width: 37px;
          height: 19px;
          border-radius: 20px;
          input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
          }
        }
        .switch {
          height: 100%;
          display: grid;
          grid-template-columns: 0fr 1fr 1fr;
          transition: 0.2s;
          &::after {
            content: "";
            border-radius: 50%;
            background: var(--colors-pureberry);
            grid-column: 2;
            transition: background 0.2s;
          }
        }
        input:checked {
          + .switch {
            grid-template-columns: 1fr 1fr 0fr;
            &::after {
              background: var(--colors-litberry);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
		padding: 0 !important;
    margin-top: 25px;
    &__card {
      height: auto;
    }
    &__cardHeader {
      height: 15%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-left: 20px;
      h4 {
        margin-bottom: 60px;
        margin-top: 10px;
      }
    }
    &__cardWrapper {
      height: auto;
      &__content {
        flex-direction: column;
        &Search {
          width: 100%;
          min-height: 300px;
        }
        &Add {
          padding: 13px;
        }
      }
    }
    .ant-card-body {
      padding: 10px;
    }
  }
}

.ConnectionsPIA {
  background: #ffffff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05);
  margin-left: 50px;
  padding: 50px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  .msj-empty {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .insures {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 30%;
      max-width: 300px;
      .line {
        display: flex;
        width: 100%;
        justify-content: space-around;
      }
      .line-1 {
        .icon--1 {
          margin-top: -5px;
        }
        .icon--2 {
          margin-top: 5px;
        }
        .icon--3 {
          margin-top: -7px;
        }
      }
      .line-2 {
        .icon--1 {
          margin-top: 15px;
        }
        .icon--2 {
          margin-top: -5px;
          margin-left: 30px;
        }
      }
      .line-3 {
        .icon--3 {
          margin-top: -15px;
          margin-left: -20px;
        }
        .icon--1 {
          margin-top: 5px;
          margin-left: 20px;
        }
        .icon--2 {
          margin-top: 15px;
          margin-left: 30px;
        }
      }
      .line-4 {
        .icon--2 {
          margin-top: -10px;
          margin-left: -10px;
        }
        .icon--3 {
          margin-top: 5px;
          margin-left: -10px;
        }
      }
      .line-5 {
        .icon--1 {
          margin-left: -20px;
          margin-top: -5px;
        }
        .icon--2 {
          margin-top: 20px;
        }
        .icon--3 {
          margin-top: 25px;
        }
      }
      .icon {
        background: #ffffff;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 10px;
        width: 26px;
        height: 26px;
        &--1 {
          animation: latidos1 6s infinite;
        }
        &--2 {
          animation: latidos2 5.5s infinite;
        }
        &--3 {
          animation: latidos3 6s infinite;
        }
      }

      @keyframes latidos1 {
        20% {
          transform: none;
        }
        50% {
          transform: scale(1.2);
        }
        70% {
          transform: scale(1.1);
        }
        100% {
          transform: none;
        }
      }
      @keyframes latidos2 {
        20% {
          transform: none;
        }
        30% {
          transform: scale(1.2);
        }
        60% {
          transform: scale(1.1);
        }
        100% {
          transform: none;
        }
      }
      @keyframes latidos3 {
        20% {
          transform: scale(1.2);
        }
        50% {
          transform: scale(1.1);
        }
        70% {
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }
    .text {
      width: 60%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 200px;
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .section-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 150.19%;
      color: var(--colors-pureberry);
      margin-bottom: 0px;
    }
    .cont-btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .button-filter {
        border: none;
        background-color: transparent;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        margin: 0 10px;
        margin-top: -7px;
        color: var(--colors-pureberry);
        opacity: 0.5;
        padding: 0 15px;
        margin-top: 0px;
        box-shadow: none;
        &--active {
          color: #5d30dd;
          opacity: 1;
        }
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  &--head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-items: 100%;
    margin-top: 25px;
    margin-bottom: 30px;
    position: relative;
    .line-divider {
      max-width: 241px;
      width: 50%;
      height: 1px;
      background-color: #a59dbe;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .step {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &--circle {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 1px solid #a59dbe;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        padding: 15px;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 150.19%;
          color: #a59dbe;
        }
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 150.19%;
        color: #a59dbe;
        margin-bottom: 0;
      }
      &--active {
        p {
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .step--circle {
          border: none;
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
          span {
            color: #ffffff;
          }
        }
      }
      @media (max-width: 768px) {
        display: none;
        &--active {
          display: flex;
        }
      }
    }
    .btn {
      background-color: transparent;
      border: none;
      padding: 0;
      height: 10px;
      position: absolute;
      height: 24px;
      right: 0;
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
    }
    .filter {
      width: 15px;
      height: 9.6px;
      box-shadow: none;
      object-fit: contain;
    }
    .filter-menu {
      position: absolute;
      left: -123px;
      z-index: 100;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 130px;
      button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 0;
        background-color: #f6f6f6;
        border-bottom: 1px solid #c5c5c5;
        font-family: Lato;
        font-style: normal;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5d30dd;
        &:nth-last-child(1) {
          border-bottom: 0px solid #c5c5c5;
        }
      }
      .button--active {
        background-color: #340aab;
        color: #fff;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    &-row {
      flex-direction: row;
    }
  }
  &__body {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    border-radius: 20px;
    transition: 1s;
    

    .button-pia {
      border: none;
      background: linear-gradient(45deg, #f5f5f5 0%, #ffffff 100%);
      width: 33.3%;
      padding: 0 !important;
      height: 166px;
      &:nth-child(2) {
        background: linear-gradient(45deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(3) {
        background: linear-gradient(225deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(4) {
        background: linear-gradient(45deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(5) {
        background: linear-gradient(315deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(6) {
        background: linear-gradient(225deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(7) {
        background: linear-gradient(45deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(8) {
        background: linear-gradient(315deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(9) {
        background: linear-gradient(225deg, #f5f5f5 0%, #ffffff 100%);
      }
      &:nth-child(10) {
        background: linear-gradient(45deg, #f5f5f5 0%, #ffffff 100%);
      }
      .cont {
        width: 100%;
        height: 50%;
        transition: 0.5s;
        &-top {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          transition: 0.5s;
        }
        &bottom {
          padding-top: 5px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          transition: 0.5s;
        }
      }
      .img-brand {
        max-height: 44px;
        max-width: 90px;
      }
      span {
        margin-top: 10px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
      }
      .conect {
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .yellow {
        color: #ffbb0d;
      }
      .gray {
        color: var(--colors-oldberry);
      }
      .cont {
        .warning {
          margin-right: 10px;
          margin-top: 10px;
        }
        .warning-name {
          margin-right: 5px;
          margin-top: -2px;
          width: 14px;
          height: 14px;
        }
      }
      &:hover {
        background: var(--colors-litberry) !important;
        span {
          color: var(--colors-white) !important;
        }
        .conect {
          -webkit-background-clip: unset;
          background: transparent;
          -webkit-text-fill-color: var(--colors-white) !important;
        }
      }
      &--active {
        background: var(--colors-midberry) !important;
        span {
          color: var(--colors-white) !important;
        }
        .conect {
          -webkit-background-clip: unset;
          background: transparent;
          -webkit-text-fill-color: var(--colors-white) !important;
        }
      }
      .img-brand--color {
        display: flex;
      }
      .img-brand--white {
        display: none;
      }
      &:hover {
        .img-brand--white {
          display: flex;
        }
        .img-brand--color {
          display: none;
        }
      }
    }
    &--active {
      width: 60%;
      transition: 1s;
    }
  }
  .form-container {
    width: 40%;
  }
  &__form {
    width: 100%;
    padding: 20px;
    margin-top: 35px;
    .button-transparent {
      background-color: transparent;
      border: none;
      padding: 0;
    }
    .form {
      border: 1px solid #340aab;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 30px 20px;
      padding-right: 15px;
      padding-bottom: 0;

      &--analisis {
        padding-bottom: 30px;
        .h1 {
          padding-bottom: 20px;
          border-bottom: 1px solid #f7f7f7;
          margin-bottom: 20px;
        }
        .cont-switch {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 23px;
          span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: #b2b2b2;
            margin-left: 7px;
          }
          a {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            margin-bottom: 0;
            color: #5d30dd;
            &:active,
            &:hover {
              color: #5d30dd;
            }
          }
        }
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #340aab !important;
        border-color: #340aab !important;
      }
      .ant-switch-checked {
        background-color: #340aab !important;
        border: 0px solid #b2b2b2;
        .ant-switch-handle {
          &::before {
            background-color: #35d8d0;
          }
        }
      }
      .ant-switch-handle::before {
      }
      .ant-switch {
        background-color: #ffffff;
        border: 1px solid #b2b2b2;
        min-width: 37px;
        height: 14px;
        &-handle {
          &::before {
            background-color: #b2b2b2;
          }
        }
      }
      .ant-switch-handle {
        top: -3px;
        left: 0px;
      }
      .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 16px - 2px);
      }
      &-head {
        display: flex;
        justify-content: space-between;
        &--left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        p {
          margin-bottom: 0 !important;
        }
        .image {
          max-width: 89px;
          width: auto;
          height: 30.79px;
          margin-left: 20px;
          object-fit: contain;
        }
      }
      .banner {
        width: 100%;
        height: auto;
      }
      .content-form {
        width: 100%;
        padding-right: 5px;
        padding-bottom: 30px;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
          width: 10px;
        }

        &::-webkit-scrollbar-button:increment,
        .contenedor::-webkit-scrollbar-button {
          display: none;
        }

        &::-webkit-scrollbar:horizontal {
          height: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #797979;
          border-radius: 20px;
          border: 2px solid #f1f2f3;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }
      }
      .closeRed {
        margin-bottom: 5px;
      }
      .header-name {
        margin-top: 20px;
      }
      .name {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #340aab;
        margin-left: 10px;
      }

      .cont-img {
        display: flex;
        padding: 20px 0;
        justify-content: flex-start;
        align-items: center;
        .image {
          width: 89px;
          height: 85px;
          object-fit: contain;
        }
      }
      .h1 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 10px;
        &--yellow {
          color: #ffbb0d !important;
        }
        &--red {
          color: #eb5757 !important;
        }
      }
      .button-primary,
      .button-tertiary {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .button-tertiary {
        height: 68px !important;
        margin-top: 20px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .i {
          margin-right: 10px;
        }
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #5d30dd;
        }
        .logo {
          height: 20px;
          max-width: 120px;
          margin-left: 10px;
          object-fit: contain;
        }
      }
      .body {
        margin-bottom: 20px;
      }
      .account {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__box {
          display: flex;
          align-items: center;
          margin-top: 5px;
          border-top: 1px solid #f7f7f7;
          padding-top: 5px;
          padding-bottom: 5px;
          button {
            margin-left: 20px;
            .edit {
              margin-top: -3px;
            }
          }
          p {
            margin-bottom: 0 !important;
            display: inline-block;
            max-width: 130px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: Ellipsis;
          }
          &__account {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            margin-left: 10px;
            font-size: 15px;
            line-height: 150.19%;
            background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &--yellow {
          p {
            color: #ffbb0d !important;
            background: transparent;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: #ffbb0d;
          }
        }
        &--red {
          p {
            color: #eb5757 !important;
            background: transparent;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: #eb5757;
          }
        }
      }
      .ant-form {
        margin-top: 30px;
        padding-bottom: 30px;
        .ant-checkbox-wrapper {
          span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 15px;
            color: var(--colors-pureberry);
          }
        }
        &-item {
          margin-bottom: 15px;
          .ant-input {
            &::placeholder {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 150%;
              color: #b2b2b2;
              opacity: 1;
            }
          }
          .ant-input-affix-wrapper {
            height: 50px;
            border: 1px solid #b2b2b2;
            border-radius: 5px;
          }
          .anticon {
            color: #b2b2b2 !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
    border-radius: 0 !important;
    padding: 60px 20px;
    .content {
      flex-direction: column;
    }
    .msj-empty {
      flex-direction: column;
      align-items: center;
      .insures {
        .icon {
          width: 18px;
          height: 18px;
        }
      }
      .text {
        padding-left: 0px;
        height: auto;
        margin-bottom: 30px;
        width: 100%;
        h1 {
          font-size: 15px;
          line-height: 150%;
        }
      }
      .insures {
        width: 100%;
        padding-left: 30px;
        padding-bottom: 50px;
      }
    }
    .form-container {
      width: 100%;
      background-color: var(--colors-white);
      position: absolute;
      top: 255px;
      z-index: 300;
    }
    &__body {
      &--active {
        width: 100%;
      }
      .button-pia {
        width: 50% !important;
      }
      
    }
  }
  .form-item-tooltip {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .form-tooltip-information {
      }
    }
  }
}

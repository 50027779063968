.Policy__content {
  display: flex;
  justify-content: space-between;
  .box {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    .sub-title {
      padding-bottom: 10px;
      border-bottom: 1px solid #eeecf2;
    }
  }
  &--column-left {
    width: 30%;
    .item {
      margin: 40px 0;
      .body-bold {
        margin-bottom: 0px;
      }
    }
  }
  &--column-right {
    width: 68%;
    .item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 50px;
      border-bottom: 1px solid #eeecf2;
      .column {
        width: 200px;
      }
      &:last-child {
        border-bottom: 0px solid #eeecf2;
      }
      .body-regular {
        text-transform: capitalize;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    &--column-left {
      width: 100%;
    }
    &--column-right {
      width: 100%;
      .item {
        flex-direction: column;
        .column {
          margin-bottom: 50px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.LongGraph {
	padding: 20px;
	background: #ffffff;
	border-radius: 10px;
	min-height: 500px;
	svg, span, p,div, #SvgjsText1056, text  {
	}
	tspan {
		font-family: inherit;
		font-family: 'Lato';
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 15px;
		opacity: 0.5;
		color: #b2b2b2;
		text-transform: capitalize;
	}
}
.DonutGraph {
	.list-items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow: hidden;
		align-items: flex-start;
		width: 100%;
		.item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 50%;
			margin-bottom: 15px;
			p {
				font-family: "Lato" !important;
				font-style: normal;
				font-weight: 700 !important;
				font-size: 20px !important;
				line-height: 24px;
				letter-spacing: -0.02em;
				color: #4B3A7C !important;
				text-transform: capitalize;
				margin-bottom: 0;
				max-width: 80%;
			}
		}
		.circle {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-right: 10px;
		}
	}
	&__chart {
		margin-top: 20px;
		.apexcharts-legend {
			display: none !important;
		}
	}
	&__dates {
		.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
			flex-direction: row !important;
			right: 15px !important;
			flex-wrap: wrap;
			justify-content: center;
			overflow: hidden;
			position: relative !important;

			.apexcharts-legend-series {
				width: 40%;
				text-align: center;
			}
		}
		.apexcharts-inner {
			display: none !important;
		}
	}
}
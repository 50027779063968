.padding-header {
  height: 51px;
  width: 100%;
}

.header {
  background: var(--colors-litberry) !important;
  height: 51px !important;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3001;
  &--item {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $s-medium) {
      display: none;
      .link {
        display: none;
      }
    }
    .custom-trigger-btn {
      width: 38px;
      position: relative;
      .alert {
        position: absolute;
        background: #EB5757;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 10px;
        right: 5px;
      }
    }
    .link {
      margin: 0 10px;
      text-align: left;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
      color: #ffffff;
      &:hover,
      &:active {
        color: #ffffff;
      }
    }
  }
  .logo-berry-admin {
    img {
      width: 89px;
      height: 25.9px;
    }
  }

  .skip-step {
    padding: 0;
    color: $colors-white;
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #ffffff;
    p {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 769px) {
    .ant-row {
      flex-flow: nowrap;
    }
  }
  @media screen and (max-width: 3840px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1440px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 35px !important;
    line-height: 51px !important;
  }

  &__container {
    @media screen and (max-width: 3840px) {
      width: 100% !important;
      max-width: 1440px !important;
      padding: 0 24px;
    }

    @media screen and (max-width: 1440px) {
      padding: 0;
    }
  }

  .header-sidemenu {
    @media screen and (max-width: 3840px) {
      &--collapsed {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 1440px) {
      margin-left: 0;
      &--collapsed {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 769px) {
      .ant-col-xs-20 {
        flex: auto;
        max-width: auto;
      }
      .ant-col-xs-4 {
        flex: auto;
        max-width: 200px;
      }
    }
  }

  &__brand {
    .icon-help {
      margin-top: -8px;
    }

    a {
      margin-right: 15px;
    }

    .button-home-edit {
      @media screen and (max-width: 769px) {
      }
    }

    .button-house-edit {
      @media screen and (max-width: 769px) {
        display: none;
      }
    }

    .icon-logo {
      margin-right: 16px;
    }

    @media screen and (max-width: 769px) {
      height: 50px;
      flex: none;
      max-width: initial !important;
      .icon-home-head {
        margin-top: -1px;
      }
      .icon-logo {
        width: 90.57px;
        height: auto;
        margin-right: 5px;
      }
    }
  }

  &__actions {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;

    .btn-custom {
      width: 160px;
      height: 40px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-right: 23px;

      button {
        border: none;
        background: none;
        font-family: "Lato";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-white);
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
      &--preview {
        span {
          display: unset;
        }
        img {
          display: none;
        }
      }
      @media screen and (max-width: 769px) {
        &--secundary {
          max-width: 110px;
        }
        &--preview {
          width: 50px !important;
          span {
            display: none;
          }
          img {
            display: unset;
          }
        }
        margin-right: 5px;
      }
    }

    .save {
      position: relative;
      &__not-save {
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        top: 12px;
        left: 10px;
      }

      &__save {
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 50%;
        top: 12px;
        left: 10px;
      }
    }
    @media screen and (max-width: 769px) {
      flex: 100%;
      max-width: 100%;
      .button-menu-avatar {
        display: none !important;
      }
    }
  }

  .custom--notifications {
    @media screen and (max-width: 769px) {
      // display: none !important;
    }
  }

  .custom-trigger-btn {
    text-align: center;
    min-width: 38px;
    transition: background 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px !important;
    margin-right: 10px;
    margin-left: 22px;
    padding: 0 5px;
    &--settings,
    &--setting {
      display: none !important;
    }
    &.ant-dropdown-open {
      background-color: var(--colors-midberry);
    }
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 768px) {
      height: 100%;
    }
    @media screen and (max-width: 768px) {
      // display: none;
      margin-right: 0px;
      &--menu,
      &--setting {
        display: none;
      }
    }
  }

  .custom--notifications {
    position: relative;
    .circle-notify {
      width: 17px;
      height: 17px;
      background: #eb5757;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6px;
      right: 2px;
      .number-notify {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: #ffffff;
        margin: 0;
      }
    }
  }

  &__dropdown-custom {
    min-width: 174px !important;
    .ant-dropdown-menu {
      background-color: var(--colors-white);
      margin-top: -5px;
      border-radius: 5px;
      border-top-right-radius: 0;
      font-family: "Lato";
      font-weight: bold !important;
    }
    .item-title-menu {
      height: 43px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #f7f7f7;
      width: 156.71px;
      margin-left: 10px;
      padding: 0 10px;
      margin-bottom: 20px;
      margin-top: 20px;
      p {
        margin-bottom: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 150.19%;
        color: var(--colors-pureberry);
      }
    }
    &__item {
      .ant-btn {
        padding: 0;
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Lato;
        font-style: normal;
        font-size: 14px !important;
        line-height: 17px !important;
        color: var(--colors-pureberry) !important;
        padding: 5px 15px !important;
        font-weight: bold !important;
      }

      &State {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 150.19%;
        color: var(--colors-pureberry);
        display: flex;
        justify-content: center;
        align-items: center;
        &--label {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: var(--colors-pureberry);
          text-align: center;
        }
        &__circle {
          background: #27ae60;
          width: 10px;
          height: 10px;
          display: block;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
      &:hover {
        background: none !important;
      }
      .header-logout {
        margin-right: 5px;
        margin-top: -2px;
      }
      &--logOut {
        margin-top: 10px !important;
        margin-bottom: 5px !important;
      }
    }
    &Notification {
      background-color: var(--colors-midberry);
      font-family: "Lato";
      font-weight: bold !important;
      min-width: 400px !important;
      color: #fff !important;
      font-size: 12px !important;
      .notifications-menu {
        padding: 25px 25px 10px;
        &__head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          &Title {
            margin-bottom: 0;
          }
          &Action {
            text-decoration: underline;
            margin-bottom: 0;
          }
        }
        &__body {
          padding-top: 15px;
          margin-bottom: 35px;
          &__notification {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 23px;
            &Detail {
              &__title {
                margin-bottom: 0;
                small {
                  font-weight: 300;
                }
              }
              &__descrip {
                margin-bottom: 0;
                font-weight: lighter;
              }
            }
            &Action {
              background: var(--colors-white);
              border: 1px solid var(--colors-litberry);
              box-sizing: border-box;
              border-radius: 5px;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              align-items: center;
              color: var(--colors-litberry);
              width: 94px;
              height: 40px;
            }
          }
        }
        &__footer {
          text-align: center;
          font-size: 13px;
          text-decoration: underline;
        }
      }
    }
    @media screen and (max-width: 769px) {
      min-width: 100% !important;
      .ant-dropdown-menu {
        padding: 40px 0;
        padding-bottom: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      .item-title-menu {
        width: 95% !important;
      }
    }
  }

  @media screen and (max-width: 769px) {
    padding: 10px 20px !important;
    .title-name {
      text-align: center;
    }
  }
}

.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  background-blend-mode: normal;
  border: 1px solid var(--colors-white);
}

.menu {
  margin-left: 5px;
}

.without-notifications {
  width: 400px;
  min-height: 122px;
  background: var(--colors-white);
  padding: 17px 22px;
  box-shadow: 11px 13px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: calc(100vh - 60px);
  overflow: auto;

  .button-see-more {
    border: none;
    border-top: 1px solid #d9d9d9;
    width: 100%;
    padding-top: 20px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #5d30dd;
    .icon {
      margin-top: 1px;
      margin-left: 5px;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 15px;
    background: #555;
  }

  .item-notification {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    margin-bottom: 15px;
    .button {
      width: 109px !important;
      min-width: 109px !important;
      padding: 10px !important;
    }
    &--title {
      margin-bottom: 0px;
    }
    &--text {
      margin-bottom: 0px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 150.19%;
      color: var(--colors-pureberry);
      margin-bottom: 0;
    }
    img {
      margin-top: -4px;
      margin-right: 5px;
    }

    a,
    a:hover {
      text-decoration-line: underline;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #5d30dd;
    }
  }
  .ant-divider-horizontal {
    margin: 17px 0;
  }
  &__body {
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #b2b2b2;
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

.ant-dropdown-placement-bottomRight {
  li.ant-dropdown-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .body-bold {
      margin-top: 20px;
    }
  }
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.header__dropdown-custom__item {
    align-items: flex-start !important;
    padding: 13px 0;
  }
}
.header__dropdown-pia-menu {
  width: 400px;
  top: 52px !important;
  border-radius: 0px 0px 10px 10px;

  &__item {
    padding: 0;
    p,
    h4 {
      margin-bottom: 0;
    }
    .ant-dropdown-menu-title-content {
      width: 100%;
    }
    .item-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      a {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #5d30dd;
        img {
          margin-right: 5px;
          margin-top: -3px;
        }
      }
    }
    .card-insurance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 27px;
      margin-bottom: 10px;
      padding: 0 10px;
      .rtg {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
      }
      .lft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .circle {
        background: #ffffff;
        display: flex;
        justify-content: center;
        width: 26px;
        height: 26px;
        box-shadow: 11px 13px 14px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin-right: 10px;
        img {
          max-width: 17px;
        }
      }
      .section-info {
        .body-bold-gradient {
          &--connected {
            background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          &--inProgress {
            color: #ffbb0d;
          }
          &--disconnected {
            color: #eb5757;
          }
        }
        .items {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          .account-circle {
            border-radius: 50%;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22.75px;
            height: 22.97px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            margin-top: 6px;
            .type {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 10.4512px;
              line-height: 150.19%;
              color: #4b3a7c;
            }
            &--connected {
              border: 1px solid #25d366;
            }
            &--process {
              border: 1px solid #ffbb0d;
            }
            &--invalid {
              border: 1px solid #eb5757;
            }
            .alret-hover {
              display: none;
              position: absolute;
              width: 154px;
              height: 58px;
              background: rgba(28, 14, 66, 0.8);
              border-radius: 5px;
              padding: 10px;
              top: 20px;
              left: 0px;
              justify-content: space-between;
              align-items: center;
              transition: 0.7s;
              z-index: 2;
              .button-transparent {
                width: auto;
                padding: 0;
                background-color: transparent;
                border: none;
                box-shadow: none;
              }
              .body-bold,
              .small-detail {
                color: #ffffff;
              }
              .title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .status {
                  background-color: #25d366;
                  border-radius: 50%;
                  width: 8.23px;
                  height: 8.23px;
                  margin-left: 5px;
                }
              }
            }
            &:hover {
              .alret-hover {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  .ant-dropdown-menu {
    padding: 20px 10px;
    border-radius: 0px 0px 10px 10px;
  }
  @media (max-width: $medium) {
    width: 100%;
    left: 0 !important;
    top: auto !important;
    bottom: 52px !important;
    position: fixed !important;
  }
}

.PolicySummaryPia {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  &--content {
    width: 49.5%;
  }
  @media (max-width: $medium) {
    flex-direction: column;
    padding-bottom: 100px;
    &--content {
      width: 100%;
    }
  }
}
.PolicySummaryPia-card-content {
  margin-top: 15px;
  background: #ffffff !important;
  border-radius: 10px;
  padding: 20px;
  &--none {
    display: none;
  }
  &--dark-mode {
    background: #1c0e42 !important;
    h4,
    h4,
    h1,
    h2,
    h3,
    .sub-title {
      color: #ffffff !important;
    }
    .content-gray,
    .button-placa {
      background-color: var(--colors-pureberry) !important;
      border: none;
    }
    .SectionSummaryPia--table .ant-tabs-tab h1,
    .SectionSummaryPia--table .ant-tabs-tab .h1 {
      color: #35d8d0 !important;
    }
    .button-placa {
      color: #ffffff !important;
    }
    .SectionSummaryPia--table .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn h1,
    .SectionSummaryPia--table .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .h1 {
      color: #35d8d0 !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 2px solid #35d8d0;
      z-index: 2000;
    }
  }
}

.cont-items-head-minimize {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  transition: 1s;
  @media (max-width: $medium){
    padding: 0 20px;
  }
}
.content-dark-mode {
  transition: 1s;
  .sections-minimize {
    .btn-section {
      background: #1c0e42;
      color: #ffffff;
      &:hover,
      &:active,
      &:focus {
        background: #1c0e42;
        color: #ffffff;
      }
    }
  }
}

.sections-minimize {
  display: flex;
  margin-right: 20px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  .btn-section {
    width: auto;
    height: 57px;
    border-radius: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    background: #ffffff;
    color: var(--colors-pureberry);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background: #ffffff;
      color: var(--colors-pureberry);
    }
    svg {
      margin-left: 15px;
    }
  }
}

.PolicySummary {
  &Pia {
    &--content {
      padding: 0 !important;
    }
  }
  .highlight {
    background-color: #5d30dd !important;
    color: #ffffff !important;
  }
  .btutton-transparent {
    padding: 0 !important;
    border: none;
    background-color: transparent;
    height: 14px;
    width: 14px;
    &::before,
    &:hover,
    &:active {
      border: none;
      background-color: transparent;
    }
  }
  &--head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--title {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .select__status--global {
      margin-left: 10px;
    }
    .row-status {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .button-like {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    img {
      height: 14px;
      width: 16px;
      min-width: 14px;
      margin-left: 15px;
    }
    .body-bold {
      margin-left: 5px;
      margin-bottom: 0;
      color: #35d8d0 !important;
    }
    .cancelada {
      color: #b2b2b2 !important;
    }
    .vencida {
      color: #eb5757 !important;
    }
    .vigor {
      color: #ffbb0d !important;
    }
    .pagada {
      color: #35d8d0 !important;
    }
    .Endosos {
      .body-bold {
        color: #ffbb0d;
      }
    }
  }
}

.products-editing {
  padding: 22px 23px 0px 24px;

  &__card {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    .ant-card-body {
      padding: 24px 0 24px 24px;
    }

    &Wrapper {
      padding: 13px 0 16px 2px;

      &__option {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          margin: 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          display: flex;
          align-items: center;
          color: #333333;
          opacity: 0.4;
        }

        img {
          width: 16px;
          height: 16px;
          margin-left: 15px;
        }
      }
    }
  }
  .eye-res {
    display: none;
  }
  @media screen and (max-width: 768px) {
		padding: 0 !important;
    margin-top: 25px;
    .eye-res {
      display: block;
    }
    .eye-des {
      display: none;
    }
    .ant-card-body {
      flex-direction: column;
      .card-products__wrapper {
        flex-direction: column;
        height: auto; 
        .card-products__lines {
          width: 100%;
          padding-right:0px;
          display: flex;
          flex-direction: column;
          padding: 0;
          .product-line--selected, .product-line--disabled {
            width: 100%;
          }
        }
      }
      .product-lines__product-line {
        height: auto;
        overflow: auto;
      }
      .card-products__layouts {
        width: 100%;
      }
    }
  }
}

.products-editing {
  padding: 22px 23px 0px 24px;
}

.card-products {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);

  .ant-card-body {
    padding: 14px 11px 24px 14px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__lines {
    width: 100%;
    padding-right: 0px;
  }
  &__layouts {
    width: 65%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-wrap: wrap;
    background: var(--colors-white);
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px 23px;
  }
}

.header {
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 13px;
    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      margin: 0;
    }
    @media (max-width:768px) {
      h4 {
        padding: 5px;
        padding-top: 0;
        border-bottom: 0.2px solid rgba(189, 189, 189, 0.35);
      }
    }

    .ant-divider-horizontal {
      width: 133px;
      min-width: 133px;
      display: none;
    }
  }
}

.layouts-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 13px;

  .button {
    padding: 0 !important;
  }

  h4 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--colors-pureberry);
    margin-bottom: 4px;
  }

  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: var(--colors-black);
    opacity: 0.5;
  }

  .outline {
    background: var(--colors-white);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 130px;
    height: 40px;
    border: 2px solid var(--colors-litberry);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-black);
    }
  }

  .outline:hover,
  .outline:focus,
  .outline:active {
    background: var(--colors-white);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 2px solid var(--colors-midberry);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;

    span {
      margin: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-black);
    }
  }
}

.layout {
  &--active {
    border: 2px solid var(--colors-litberry);
    max-width: 48.7%;
    min-width: 250px;
    height: 112px;
    background: var(--colors-white);
    box-sizing: border-box;
    box-shadow: 2.97301px 2.97301px 5.94601px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 11px;
    padding: 0;

    img {
      width: 177px;
      height: 76px;
    }

    .container-small {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .container-small input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark-small {
      position: absolute;
      bottom: 0px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: #f7f7f766;
      border: 1.5px solid var(--colors-litberry);
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 3840px) {
        right: 108px;
      }
      @media screen and (max-width: 1440px) {
        right: 88px;
      }
      @media screen and (max-width: $medium) {
        right: 100px;
      }
    }

    .container-small:hover input ~ .checkmark {
      background-color: var(--colors-white);
    }

    .container-small input:checked ~ .checkmark {
      background-color: var(--colors-white);
      border: 1.5px solid var(--colors-litberry)66;
      opacity: 1;
    }

    .checkmark-small:after {
      content: "";
      display: none;
    }

    .container-small input:checked ~ .checkmark-small:after {
      display: block;
    }

    .container-small .checkmark-small:after {
      left: 0;
      bottom: 0;
      border-radius: 50%;
      width: 6px;
      height: 6px;  
      background: var(--colors-midberry);
    }
    @media screen and (max-width: $medium) {
      max-width: 100%;
      margin-right: 0 !important;
    }
  }

  &--inactive {
    border: none;
    max-width: 48.7%;
    min-width: 250px;
    height: 112px;
    background: var(--colors-white);
    border: 1px solid var(--colors-oldberry);
    box-sizing: border-box;
    box-shadow: 2.97301px 2.97301px 5.94601px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 11px;
    padding: 0;

    img {
      width: 177px;
      height: 76px;
    }

    .container-small {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .container-small input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark-small {
      position: absolute;
      bottom: 0px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: #f7f7f766;
      border: 1.5px solid var(--colors-litberry);

      @media screen and (max-width: 3840px) {
        right: 108px;
      }
      @media screen and (max-width: 1440px) {
        right: 88px;
      }
      @media screen and (max-width: $medium) {
        right: 100px;
      }
    }

    .container-small:hover input ~ .checkmark {
      background-color: var(--colors-white);
    }

    .container-small input:checked ~ .checkmark {
      background-color: var(--colors-white);
      border: 1.5px solid var(--colors-litberry)66;
      opacity: 1;
    }

    .checkmark-small:after {
      content: "";
      display: none;
    }

    .container-small input:checked ~ .checkmark-small:after {
      display: block;
    }

    .container-small .checkmark-small:after {
      left: 0;
      bottom: 0;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-top: 1px;
      background: var(--colors-midberry);
    }
    @media screen and (max-width: $medium) {
      max-width: 100%;
      margin-right: 0 !important;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 36px;
  padding-right: 25px;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    padding: 0;
    margin-top: 20px;
  } 
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: var(--colors-pureberry);
    margin: 0;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .secundary {
      background: var(--colors-frozenberry);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 186px;
      height: 50px;
      border: 2px solid var(--colors-frozenberry);
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-white);
      }
    }

    .secundary:hover,
    .secundary:focus,
    .secundary:active {
      background: var(--colors-frozenberry);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: 2px solid var(--colors-frozenberry);
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-white);
      }
    }
  }
}

.product-lines {
  &__header {
    margin-bottom: 24px;
    padding-top: 0px;

    h4 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: var(--colors-pureberry);
      margin-bottom: 4px;
    }
    .text-responsive {
      display: none;
    }
    @media (max-width: 768px) {
      margin-bottom: 5px;
      
      .text-responsive {
        display: block;
        p {
          text-align: center;
        }
      }
      .small-detail {
        margin-bottom: 30px;
        margin-top: 5px;
      }
      h4 {
        text-align: left;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 15px;
      }
      .important {
        margin-bottom: 0;
        text-align: center !important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #5D30DD;
        opacity: 0.5;
      }
      .title-des {
        display: none;
      }
    }

    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: var(--colors-black);
      opacity: 0.5;
    }
  }

  &__product-line {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .icon-menu {
      font-size: 9px;
    }

    .product-line {
      flex-direction: column !important;
      height: 148px;
      width: 124.8px;
      background: #FFFFFF;
      box-shadow: -10px 7px 22px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      position: relative;
      @media (max-width:1308px) {
        width: 15%;
        min-width: 80px;
      }
      .product-line--selected__view, .product-line--disabled__view {
        display: none;
      }
      &--disabled__lines, &--selected__lines {
        text-align: right;
        position: absolute;
        z-index: 200;
        right: 13px;
        top: 112px;
        width: 20px;
      }
      &--selected__lines {
        .icon-menu:before {
          color: #FFFFFF;
          @media (max-width: 768px) {
            color: #5D30DD;
          }

        } 
      }
      .product-line--disabled__product, .product-line--selected__product {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        @media (max-width:1300px) {
          flex-direction: column;
          img {
            margin: 0 !important;
          }
        }
        @media (max-width:780px) {
          flex-direction: row;
          img {
            margin: 10px !important;
            margin-left: 0 !important;
          }
        }
        img {
          margin-right: 10px;
        }
        p {
          margin-bottom: 0;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #340AAB;
        }
      }
      .button {
        font-family: 'Lato' !important;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        position: absolute;
        bottom: 0;
        min-width: auto !important;
        width: 100%;
        img {
          position: absolute;
          left: 15px;
          top: 20px;
        }
        @media (max-width: 1308px) {
          span {
            display: none;
          }
        }
        &-purple {
          color: #FFFFFF;
          background: #340AAB;
          border-radius: 5px;
          border: none;
          img {
            filter: brightness(136);
            top: 22px;
          }
        }
        &-white {
          color: #5D30DD;
          background: #FFFFFF;
          border-radius: 5px;
          border: 1px solid #5D30DD;
        }
      }
      @media (max-width: 768px) {
        .button {
          display: none;
        }
        .product-line--selected__view,.product-line--disabled__view {
          display: flex;
        }
        &--selected {
          height: 50px;
          width: 80%;
          display: flex;
          flex-direction: row !important;
          &__view {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            background: #F7F7F7;
            @media (max-width: $medium) {
              background: #F7F7F7;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            
            img {
              cursor: pointer;
            }
          }
          &__product {
            width: 60% !important;
            display: flex !important;
            border-bottom: 1px solid #0000001a !important;
            align-items: center !important;
            margin-top: 0 !important;
            @media (max-width: $medium) {
              border: 1px solid #5D30DD;
              border-radius: 5px;
            }
  
            img {
            @media (max-width: $medium) {
              filter: brightness(10);
            }
              margin-right: 10px;
            }
  
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-litberry);
              margin: 0;
            }
          }
          &__lines {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #0000001a;
            width: 20%;
            position: relative;
            top: auto;
            right: auto;
            @media (max-width: $medium) {
              background: #F7F7F7;
              border-bottom: 0px solid #0000001a;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #fff;
              border-color: #B2B2B2;
              border: 0.75px solid #B2B2B2;
              box-sizing: border-box;
              border-radius: 2px;
            }
          }
          @media (max-width: $medium) {
            &__product {
              background: #340AAB;
              p {
                color: #ffffff !important;
              }
            }
          }
        }
        &--selected , &--disabled {
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #fff;
            border-color: #B2B2B2;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: #5D30DD !important;  
          }
          @media (max-width: $medium) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
  
        &--disabled {
          height: 50px;
          width: 80%;
          display: flex;
          flex-direction: row !important;
  
          &__view {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            background: #F7F7F7;
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #fff;
              border-color: #B2B2B2;
            }
            @media (max-width: $medium) {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
  
            img {
              cursor: pointer;
            }
          }
          &__product {
            width: 60%;
            border-bottom: 1px solid #0000001a;
            background: var(--colors-white);
            display: flex;
            align-items: center;
            margin-top: 0 !important;
  
            img {
              cursor: pointer;
              margin-right: 10px;
            }
  
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-litberry);
              margin: 0;
            }
            @media (max-width: $medium) {
              border: 1px solid #5D30DD;
              border-radius: 5px;
            }
          }
          &__lines {
            
            background: var(--colors-white);

            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #0000001a;
            width: 20%;
            position: relative;
            top: auto;
            right: auto;
            
            @media (max-width: $medium) {
              background: #F7F7F7;
              border-bottom: 0px solid #0000001a;
            }
          }
        }        
      }
    }
  }
}

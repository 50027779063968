.select__status--global {
  .body-bold {
    margin-bottom: 0;
    text-transform: capitalize;
    margin-left: 10px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    .column {
      width: 123px;
      height: 73px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .body-bold--ramo {
        text-transform: capitalize;
      }
      .title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 4px;
        color: #b2b2b2;
      }
     
    }
  }
  .row-status, .option-row-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-top: -1px;
      margin-right: 7px;
      object-fit: contain;
    }
    .vigor {
      color: #ffbb0d;
    }
    .cancelada {
      color: #b2b2b2;
    }
    .vencida {
      color: #eb5757;
    }
    .pagada {
      color: #35d8d0;
    }
  }
  .body-bold {
    margin-bottom: 0px;
  }
  .ant-select-selector {
    min-width: 120px !important;
    background: #ffffff !important;
    border: 1px solid #f7f7f7 !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
    height: 37px !important;
    padding-top: 3px !important;
  }
}

.option-row-status {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  img {
    width: 14px !important;
    margin-right: 10px;
  }
  .body-bold {
    margin-bottom: 0;
  }
}

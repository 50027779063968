.HeaderFilters {
  background: #ffffff;
  border-radius: 10px;
  padding: 18px 15px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .section-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .h2 {
        margin-right: 30px;
      }
    }
		.h2 {
			font-size: 25px;
		}
    .tabs {
    }
		.filters {
			gap: 4px;
			display: flex;
			justify-content: flex-end;
		}

    .range-date {
      margin-bottom: 5px;
      border-radius: 5px;
      border: 1px solid #f7f7f7;
      max-width: 200px;
      .ant-picker-range-separator {
        border: none !important;
        padding: 0 !important;
      }
      .ant-picker-active-bar,.ant-picker-suffix {
        border: none !important;
        display: none;
      }
      input {
        font-family: 'Lato';
        border: none !important;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        color: #4B3A7C;
        &::placeholder {
          opacity: 1;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #4B3A7C;
        }
      }
      .ant-picker.ant-picker-range {
        border: none !important;
        padding: 5px 10px;
        border-radius: 5px;
        height: 37px;
        .ant-picker-clear {
          display: none !important;
        }
      }
      &-active {
        background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%) !important;
        .anticon {
          color: #ffffff !important;
        }
        input {
          color: #ffffff !important;
        }
        .ant-picker.ant-picker-range {
          background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%) !important;
        }
      }
    }


    .tab {
      height: 37px;
      background: #ffffff;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #4b3a7c;
      border: 1px solid #f7f7f7;
      border-radius: 5px;
			img {
				margin-right: 5px;
				margin-top: -4px;
			}
			&--active {
				background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7F14FC 56.11%);
				border-radius: 5px;
				color: #ffffff;
				img {
					filter: brightness(100);
				}
			}
    }
    .tabs-tab {
      background-color: transparent;
      border: none;
      font-family: "Lato";
      box-shadow: none;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #340aab;
      opacity: 0.5;
      padding: 0;
      margin-right: 20px;
      &--active {
        opacity: 1;
      }
      &--down {
        opacity: 1;
        margin-right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    .section-title {
      width: 100%;
      justify-content: space-between !important;
      button {
        margin-top: -20px;
      }
    }
  }
}

.experience {
  padding: 22px 23px 0px 24px;
  .message--empty {
    margin-top: 20px !important;
    text-align: center;
    width: 100% !important;
  }
  .ant-card-body {
    padding-bottom: 34px;
  } 
  &-card {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);

    &__edit {
      width: 97%;
      position: absolute;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      i {
        font-size: 11px;
        &::before {
          color: var(--colors-white);
        }
      }
      a {
        background: var(--colors-litberry);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      a {
        margin-right: 22px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .experience-card__edit--res {
      display: none;
      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        button {
          background: var(--colors-frozenberry);
          height: 50px !important;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          width: calc(100% - 40px);
          color: var(--colors-white);
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &Titles {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h4 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          color: var(--colors-pureberry);
          margin: 0;
        }

        .ant-divider-horizontal {
          width: 133px;
          min-width: 133px;
          display: none;
        }
      }
    }

    &__sponsor {
      padding: 0;
      white-space: nowrap;
      margin-top: 32px;
      .items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          margin-right: 50px;
          height: 20px;
          max-width: 85px;
          width: auto;
          max-width: 86px;
        }
        @media (max-width: 768px) {
          padding: 0 10px;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
          
          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: transparent;
          }
          
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: transparent;
          }
        }
      }
      .slick-arrow {
        display: none !important;
      }
      .slick-slide {
        .item {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
        img {
          margin: 0 auto;
          margin-bottom: 20px;
          height: 20px;
          max-width: 85px;
          width: auto !important;
        }
      }
      .slick-dots li button:before {
        font-family: "slick";
        font-size: 0px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        content: "•";
        text-align: center;
        border: 1px solid var(--colors-litberry);
        opacity: 1;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-radius: 50%;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: black;
        background: var(--colors-litberry);
    }

      img {
        margin-right: 70px;
        margin-bottom: 20px;
      }

      &::-webkit-scrollbar {
        background: #5f5f5f2c;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #5f5f5f3b;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 25px;
    &-card {
      &__sponsor {
        &::-webkit-scrollbar {
          background: #5f5f5f2c;
          height: 0px;
        }
      }
      &__edit--res {
        padding-right: 0px;
      }
      &__edit--res {
        margin-top: 20px;
        button {
          background: #5D30DD !important;
          img {
            margin-right: 10px !important;
          }
        }
      }
      border-radius: 15px !important;
      .ant-card-body {
        padding: 24px 0;
        padding-left: 0px;
      }
    }
  }
}

.content {
    width: 100%;
    .alice-carousel__wrapper {
        width: 91%;
        li {
            width: 100% !important;
        }
    }
    .alice-carousel__dots {
        margin: 0;
        padding: 0;
        margin-left: 8px;
        list-style: none;
        text-align: left;
    }

    .alice-carousel__dots-item {
        width: 9px;
        height: 9px;
        border: 1px solid var(--colors-white);
        background: none;
    }
    .alice-carousel__dots-item:hover,
    .alice-carousel__dots-item.__active {
        background: var(--colors-white) !important;
    }

    &__info {
        img {
            width: 98%;
            height: 256px;
            margin-bottom: 34px;
            border-radius: 20px;
        }

        h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.02em;
            color: var(--colors-white);
            margin-bottom: 10px;
        }

        p {
            width: 100%;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: var(--colors-white);
            opacity: 0.75;
            margin-bottom: 34px;
        }
    }

    &__button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 46px;
        padding-right: 33px;
        .secundary {
            background: var(--colors-frozenberry);
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            width: 185px;
            height: 51px;
            border: 2px solid var(--colors-frozenberry);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin: 0;
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: var(--colors-white);
            }
        }

        .secundary:hover,
        .secundary:focus,
        .secundary:active {
            background: var(--colors-frozenberry);
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            border: 2px solid var(--colors-frozenberry);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 185px;
            height: 51px;

            span {
                margin: 0;
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: var(--colors-white);
            }
        }
    }
    @media screen and (max-width: 768px) { 
        &-tutorial {
            display: block !important;
            margin-top: 30px;
        }
    }
}

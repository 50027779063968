.minimal-marketplace {
  width: 100%;
  height: 100%;
  padding: 20px 20px 37px 45px;
  margin: 15px 0px;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

  .cont-card {
    margin: 4px;
    width: auto;
    .btns {
      min-height: 70px !important;
    }
    .card {
      min-width: 260px;
      width: 260px;
      margin-left: 10px;
      height: 210px;
      &__background {
        height: 209px;
      }
      &__content {
        height: 210px;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    margin-right: 26px;
    margin-top: 22px;

    .titleWrapper {
      flex: 1;
      &__title {
        flex-direction: row;
        font-family: "Lato";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        display: flex;
        align-items: center;
        margin: 0;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--colors-pureberry);
          margin-right: 15px;
          border-radius: 50%;
          height: 34px;
          width: 34px;
          .anticon {
            color: var(--colors-white);
            font-size: 20px;
          }
        }
        img {
          margin-right: 15px;
        }
      }
    }

    .moreWrapper {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &__more {
        justify-content: flex-end;
      }

      .ant-btn {
        background: var(--colors-litberry);
        border-radius: 5px;
        width: 94px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin: 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }
    }
  }

  &__list {
    overflow: auto;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    padding-bottom: 10px;
    .image-information {
      width: 100%;
      height: 160px;
      position: relative;
      border-radius: 10px;
      display: flex;
      margin-right: 7px;
      &__background {
        width: 219px;
        height: 160px;
        position: absolute;
        z-index: 1;
        border-radius: 10px;
        border-radius: 5px;
      }
      &__content {
        z-index: 99;
        width: 219px;
        height: 160px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(
          0deg,
          rgba(74, 58, 124, 0.308),
          rgba(74, 58, 124, 0.301)
        );
        border-radius: 5px;
        &Visible {
          height: 15%;
          background: var(--colors-pureberry);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 5px 5px 0 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 19px;
          span {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 8px;
            line-height: 10px;
            letter-spacing: -0.02em;
            color: var(--colors-white);
          }

          a {
            display: flex;
            align-items: center;
          }
        }

        &NotVisible {
          height: 15%;
          background: none;
          border-radius: 5px 5px 0 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 11px;
          a {
            margin-top: 2px;
          }
        }

        &Category {
          height: 65%;
          padding: 0 19px;
          h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: var(--colors-white);
            margin-top: 8px;
          }
        }

        &Link {
          height: 20%;
          padding: 0 19px;
          .ant-btn {
            background: none;
            padding: 0;
            border-radius: 5px;
            height: 18px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            span {
              margin: 0;
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-white);
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      background: #5f5f5f2c;
      height: 6px;
      @media (max-width: $medium) {
        height: 0px;
      }
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #5f5f5f3b;
    }
  }
  @media (max-width: $medium) {
    padding: 0px;
    &__header {
      margin-right: 0px;
      padding: 20px;
      padding-bottom: 0;
    }
  }
}

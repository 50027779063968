.my-account {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
}
.my-account__content {
  max-width: 1300px;
  background-color: var(--colors-white);
  width: 100%;
  padding: 50px 70px;
  .btns-section-account {
    .button {
      height: 40px !important;
      width: 96px !important;
      min-width: 96px !important;
      padding: unset !important;
    }
  }
  &--title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: var(--colors-pureberry);
  }
  &--section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    border-bottom: 1px solid #f2f2f2;
    padding: 30px 0;
    .section-start {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 90%;
      .add {
        padding: 0;
        margin-top: 10px;
        border: none;
        background-color: transparent;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #5D30DD;
      }
      .content {
        margin-left: 50px;
        .form {
          display: flex;
          margin-bottom: 13px;
          .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
            .ant-form-item-control-input {
              .ant-picker {
                border-color: #EB5757;
                box-shadow: 0px 0px 2px 1px #EB5757;
              }
            }
          }
          .ant-form-item-explain.ant-form-item-explain-connected {
            display: none;
          }
          .ant-form-item {
            margin: 0 !important;
          }
          &-inputs {
            display: flex;
            gap: 5px;
            .ant-picker {
              width: 90px;
              height: 49px;
              background: #FFFFFF;
              border: 1px solid #BDBDBD;
              border-radius: 5px;
            }
          }
          .buttons {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-left: 20px;
            .btn {
              width: 43px;
              height: 43px;
              border: 1px solid #340AAB;
              border-radius: 5px;
              color: #5D30DD;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 150%;
              &--active {
                color: #FFFFFF !important;
                background-color: #340AAB !important;
              }
            }
          }
        }
      }
      @media (max-width: $medium) {
        flex-direction: column;
        width: 100%;
        .content {
          margin-left: 0;
          display: flex;
          flex-direction: column;
        }
        .form {
          width: 100%;
          flex-direction: column;
          &-inputs {
            margin-bottom: 20px;
            margin-top: 10px;
            .ant-picker {
              width: 48% !important;
            }
          }
          .buttons {
            margin-left: 0 !important;
          }
        }
      }
    }
    &--pass {
      align-items: flex-start;
    }
    .input-wahtsaap {
      height: 40px;
      border-radius: 5px;
      background-color: var(--colors-white) !important;
      width: 280px !important;
      display: flex;
      flex-direction: row-reverse;
      svg {
        margin-right: 10px;
      }
    }
    .center {
      display: flex;
      width: 80%;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 50px;

      .input-endPoint {
        width: 280px !important;
      }

      &-row {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-res {
          @media screen and (max-width: 768px) {
            flex-direction: column;
            label {
              margin: 20px 0;
            }
          }
        }
      }

      .ant-input-affix-wrapper {
        background: var(--colors-white);
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        border-radius: 5px;
        width: 280px;
        height: 40px;
      }

      h4 {
        margin-top: 7px;
      }

      .redes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .red {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }

      .form-password {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        display: none;
        &--active {
          display: flex !important;
        }
        .btns {
          display: flex;
          .button {
            height: 40px !important;
            width: 96px !important;
            min-width: 96px !important;
            padding: unset !important;
          }
        }
        .inputs {
          display: flex;
          flex-direction: column;
        }
        .input-password {
          margin-bottom: 20px;
          border: 1px solid #bdbdbd;
          box-sizing: border-box;
          border-radius: 5px;
          img {
            margin-right: 5px;
          }
        }
      }
    }
    h4 {
      font-family: Roboto;
      width: 100%;
      max-width: 200px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150.19%;
      color: var(--colors-pureberry);
    }
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: var(--colors-pureberry);
      margin: 20px 0;
    }
    .send {
      &--inactive {
        display: none !important;
      }
    }
    .send,
    .send:hover,
    .send:active,
    .send:focus {
      img {
        margin-right: 10px;
        margin-top: -2px;
      }
      padding-left: 0;
      background: transparent;
      border-color: transparent;
      border-radius: 5px;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--colors-litberry);
      height: 40px;
    }
    .btn-edit,
    .btn-edit:hover,
    .btn-edit:active,
    .btn-edit:focus {
      background: var(--colors-litberry);
      height: 40px;
      width: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    margin-top: 70px;
    background-color: transparent;
    &--title {
      margin-left: 50px;
      margin-top: -50px;
    }
    &--section {
      align-items: flex-start;
      flex-direction: column;
      background-color: var(--colors-white);
      margin: 20px 0px;
      padding: 30px;
      height: auto;
      position: relative;
      .btn-edit {
        width: 30px;
        height: 30px;
        img {
          width: 11px;
          height: 11px;
        }
      }
      &--end {
        margin-bottom: 50px;
      }
      .center--row {
        flex-direction: column !important;
      }
      .btn-edit {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      &--pass {
        .center {
          .form-password .btns {
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .ant-input-affix-wrapper {
            width: 100% !important;
          }
          .form-password--active {
            flex-direction: column;
            .inputs {
              width: 100%;
            }
          }
        }
      }
      .center {
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
        flex-direction: column;
        align-items: flex-start;
        h6 {
          margin: 0 !important;
        }
        .redes {
          flex-direction: column;
          align-items: flex-start;

        }
        .input-wahtsaap {
          width: 100% !important;
          margin-top: 10px;
        }
        .btns-section-account {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .ant-input-affix-wrapper {
          width: 100%;
          margin-top: 10px;
        }
      }
      .input-wahtsaap {
        width: 200px !important;
      }
    }
  }
}

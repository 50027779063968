.productLines-responsive {
  height: 100%;
  .ant-row {
    height: 100% !important;
    width: 500px;
  }

  &__header {
    margin-bottom: 2em;
  }

  &__title {
    text-align: center;
    font-size: 2em;

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 1em;

    p {
      font-family: Roboto;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: var(--colors-black);
      opacity: 0.5;
      margin: 0;
    }
  }
  
  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: var(--colors-litberry);
      text-align: center;
    }
  }

  &__list {
    height: 65%;
    margin-right: 34px;
    overflow: auto;

    ::-webkit-scrollbar {
      height: 5px;
      display: none;
    }

    &__draggable {
      height: 87% !important;
    }

    &__droppable {
      height: 90% !important;
    }

    div {
      height: 37px;
      margin: 10px 0px;
    }
    &__product_line {
      background: var(--colors-litberry);
      border-radius: 5px;
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: var(--colors-white);
    }
  }

  &__list::-webkit-scrollbar {
    width: 8px; /* Tamaño del scroll en vertical */
    height: 8px; /* Tamaño del scroll en horizontal */
    display: none; /* Ocultar scroll */
  }

  &__places {
    height: 65%;
    margin-left: 34px;
    &__draggable {
      height: 87% !important;
    }
    &__droppable {
      height: 90% !important;

      &Item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: -0.02em;
          color: var(--colors-litberry);
          margin: 0;
        }

        .item {
          width: 143px;
          height: 37px;
          border: 1px dashed var(--colors-litberry);
          box-sizing: border-box;
          border-radius: 5px;
          margin-left: 5px;
        }
      }
    }

    div {
      width: 120px;
      height: 37px;
      margin: 10px 0px;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.02em;
      text-align: center;
      color: var(--colors-black);
    }
    &__product_line {
      border: 1px dashed var(--colors-litberry);
      border-radius: 5px;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.content-wrapper-res{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  .productLines__list {
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.CardTotals {
  background: #ffffff;
  border-radius: 10px;
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
    .divider {
      height: 111px;
      width: 1px;
      background-color: #F7F7F7;
    }
    .small-detail {
      font-size: 9px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    .h1-gradient {
      font-family: "Lato";
      font-style: normal;
      font-weight: 300;
      font-size: 64px;
      line-height: 105%;
      margin-bottom: 0;
      background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .row--absolut {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .item {
        width: 70%;
        @media (max-width: 768px) {
          width: 90%;
          
        }
      }
      .row-column {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 140px;
      }
    }
  }
  .LongGraph {
    width: 100%;
    min-height: auto;
    .row {
      width: 100%;
      justify-content: center;
    }
  }
  .loader-small {
    padding: 25px;
  }
  .apexcharts-canvas {
    position: relative;
    user-select: none;
    width: 100% !important;
  }
  .chartPoint {
    padding-top: 0 !important;
    .row {
      padding: 0;
    }
  }
  .row {
    .body-bold {
      text-transform: capitalize;
    }
  }
  .row--absolut {
    .loader-small {
      padding: 0px !important;
    }
  }
  .loader {
    display: none !important;
  }
  &__head {
    padding: 20px;
    padding-bottom: 0;
    .types {
      p {
        margin-bottom: 0;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      button {
        width: 32px;
        height: 32px;
        padding: 0;
        border: none;
        background: #FFFFFF;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        svg {
          fill:none;
          path {
            fill: #5D30DD !important;
          }
        }
      }
      .btn-active {
        background: #340AAB;
        svg {
          fill: #ffffff !important;
          filter: brightness(1000);
        }
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .h1-mobile {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 25px;
      line-height: 30px;
      color: #4b3a7c;
      margin-bottom: 0;
    }
    .circle {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #340aab;
      border-radius: 50px;
    }
    
  }
	.LongGraph {
		min-height: 200px;
		height: 310px;
    padding: 20px;
    padding-left: 0;
    background: transparent;
		.row {
			border-bottom: 0px solid #e5e5e5 !important;
			display: block !important;
		}
    .apexcharts-xaxis-title {
      display: none;
    }
	}
  .content-filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 5px;
		padding: 20px;
    .btn {
      width: 31px;
      height: 25px;
      background: #ffffff;
      border: 1px solid #f7f7f7;
      border-radius: 5px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4b3a7c;
      &--active {
        background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
        border-radius: 5px;
        color: #ffffff;
      }
    }
  }
  .graphs {
    &--false {
      display: none;
    }
    &--active {
      display: block;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    &-foo {
      border-bottom: 0px;
      padding: 0;
			border-bottom: 1px solid #e5e5e5;
    }
    &-data {
      padding: 15px 20px;
      width: 50%;
      &:last-child {
        border-left: 1px solid #e5e5e5;
      }
    }
    &-column {
      display: flex;
      justify-content: center;
    }
    p {
      margin-bottom: 0;
    }
    .circle {
      width: 28px;
      height: 28px;
      box-shadow: 1px 1px 3px -1px #b9b9b9;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
  }
  @media (max-width: 768px) {
    width: 100% !important;
    .h1-gradient {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
    }
    &__content {
      align-items: flex-start;
      .divider {
        display: none;
      }
    }
  }
}

.DetailProduct {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .body-bold {
      margin-bottom: 0;
      margin-top: 4px;
      text-transform: capitalize;
      margin-left: 10px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    @media (max-width: $medium) {
      .button {
        min-width: 110px !important;
        padding: 10px !important;
      }
    }
  }
  &__content {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      .column {
        width: 123px;
        height: 73px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .body-bold--ramo {
          text-transform: capitalize;
        }
        .title {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 4px;
          color: #b2b2b2;
        }
        .row-status {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-top: -1px;
            margin-right: 7px;
            object-fit: contain;
          }
          .vigor {
            color: #ffbb0d;
          }
          .cancelada {
            color: #b2b2b2;
          }
          .vencida {
            color: #eb5757;
          }
          .pagada {
            color: #35d8d0;
          }
        }
        .body-bold {
          margin-bottom: 0px;
        }
      }
    }
  }
  .ant-select-selector {
    min-width: 120px !important;
    background: #ffffff !important;
    border: 1px solid #f7f7f7 !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
    height: 37px !important;
    padding-top: 3px !important;
  }
}


.option-row-status {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 14px !important;
    margin-right: 10px;
  }
  .body-bold {
    margin-bottom: 0;
  }
}

.sidebar {
    width: 100%;
    height: 100%;
    background: #fdfdfd;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 15px 15px 0;
    &__date {
        margin-top: 40px;
    }
    .lines-bussines {
        margin-top: 60px;
        margin-bottom: 30px;
        margin-left: 20px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--colors-pureberry);
    }
    &__head {
    }

    &__header {
        padding: 23px 22px 6px;
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: var(--colors-litberry);
            margin-bottom: 23px;
        }

        .ant-slider {
            margin: 8px 0;
        }
        .ant-slider-step {
            background: var(--colors-litberry);
            opacity: 0.2;
            border-radius: 5px;
            height: 3px;
        }
        .ant-slider-track,
        .ant-slider:hover .ant-slider-track {
            background: var(--colors-midberry);
            border-radius: 5px;
            height: 3px;
        }
        .ant-slider-handle,
        .ant-slider-handle:not(.ant-tooltip-open) {
            width: 13px;
            height: 13px;
            background: var(--colors-frozenberry);
            border: 0.2px solid var(--colors-white);
            box-sizing: border-box;
        }

        .ant-slider-handle.ant-tooltip-open {
            border: 0.2px solid var(--colors-white);
        }

        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 9px;
            color: var(--colors-pureberry);
            margin: 0;
            span {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 9px;
                color: var(--colors-litberry);
                opacity: 0.4;
            }
        }
    }

    .ant-divider-horizontal {
        margin: 12px 0;
    }

    &__menu {
        padding: 23px 0px 26px;

        i {
            font-size: 15px;
            margin-right: 10px;
        }

        h6 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150.19%;
            color: var(--colors-midberry);
            margin-left: 23px;
            margin-bottom: 18px;
        }

        .item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            &--active {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                margin-bottom: 19px;
                margin-left: 28px;
                div {
                    width: 6.99px;
                    height: 6.99px;
                    border-radius: 50%;
                    background: var(--colors-midberry);
                    border: 0.2px solid var(--colors-white);
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 150%;
                    color: var(--colors-midberry);
                    margin: 0;
                    margin-left: 6px;
                }
            }
            &--disabled {
                cursor: pointer;
                margin-bottom: 19px;
                margin-left: 41px;
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 150%;
                    color: var(--colors-pureberry);
                    margin: 0;
                }
            }
        }
    }

    &__leads {
        padding: 0 0 0 23px;
        h6 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 150.19%;
            color: var(--colors-litberry);
        }
    }
    @media screen and (max-width: 768px) {
       
        &--tuto {
            width: 60%;
            position: absolute;
            height: auto;
            z-index: 200;
            top: 0;
            transform: translateX(-260px);
            transition: 1s;
            
            &__header {
                padding-top: 70px;
            }
        }
        &--open {
            transform: translateX(0px);
            transition: 1s;
        }
    }
}
.tutorial__modal {
    @media screen and (max-width: 768px) {
        max-width: calc(100vw - 0px) !important;
        margin: 8px auto;
        top: 0px !important;
    } 
}
@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (min-width: $small) {
      @content;
    }
  } @else if $breakpoint == "medium" {
    @media (min-width: $medium) {
      @content;
    }
  } @else if $breakpoint == "large" {
    @media (min-width: $large) {
      @content;
    }
  }
}
@mixin font-size($values) {
  $val: "";
  @if $values != null {
    $unit: if(type-of($values) == "number", unit($values), false);

    @if $unit == px or $unit == "" {
      $val: #{calc($values / ($values * 0 + $rem-value))}rem;
    } @else if $unit == rem {
      $val: $values;
    }
  }

  font-size: $val;
}

.container-modal__history {
  background: white;
  margin: 32px auto;
  max-width: 800px;
  min-height: 480px;
  max-height: 540px;
  flex-direction: column;
  display: flex;

  .message--empty {
    width: 100%;
    margin-top: 40px;
  }
  .ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
    display: none;
  }
  .ant-picker {
    width: 96%;
    border: 1px solid #5b2fd8 !important;
  }
  .anticon {
    color: #5b2fd8;
  }
  .activity-colum {
    width: 50%;
  }
  .ant-table-tbody {
    svg {
      vertical-align: middle;
      margin-right: 24px;
      path {
        fill: var(--colors-litberry);
        stroke: var(--colors-litberry);
      }
    }
  }
  .ant-empty-image {
    svg {
      vertical-align: middle;
      margin-right: 0px;
      path {
        fill: rgba(112, 112, 112, 0.25);
        stroke: rgba(112, 112, 112, 0.25);
      }
    }
  }
  svg {
    vertical-align: middle;
    margin-right: 24px;
  }
  &TopBar {
    color: white;
    background: var(--colors-litberry);
    border-radius: 8px 8px 0 0;
    padding: 24px 32px;
    color: white;
    button {
      display: none;
      background-color: transparent;
      border: none;
      padding-left: 0 !important;
    }
    @media (max-width: $medium) {
      button {
        display: flex;
      }
      .ant-col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 35px;
      }
      background-color: #f8f8f8;
      border-radius: 0;
      padding: 20px;
      h3 {
        color: var(--colors-pureberry);
        margin-top: -3px;
      }
    }
  }
  @media (max-width: $medium) {
    .icon-edit {
      position: fixed;
      bottom: 100px;
      right: 20px;
      background-color: var(--colors-frozenberry);
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      z-index: 1;
      &::before {
        display: none;
      }
      span {
        margin-top: -3px;
      }
    }
  }

  &Pencil {
    cursor: pointer;
  }
  &Title {
    color: white;
    font-size: 21px;
    line-height: 1;
    margin: 0;
  }
  &Table {
    .ant-table-thead > tr > th {
      background: white;
      color: var(--colors-litberry);
      font-weight: bold;
    }
    tr {
      font-size: 12px;
    }
    &.withoutHead .ant-table-thead {
      display: none;
    }
    &Action {
      position: absolute;
      right: 20px;
      i {
        height: 3px;
        width: 3px;
        display: block;
        background: #7f7f7f;
        margin-bottom: 6px;
        border-radius: 50%;
        last:last-child() {
          margin-bottom: 0;
        }
      }
    }
    &Dropdown {
      padding: 0 !important;
      box-shadow: none !important;
      background: #f8f8f8 !important;

      .item {
        padding: 0 !important;
        .ant-menu-vertical .ant-menu-item,
        .ant-menu-vertical-left .ant-menu-item,
        .ant-menu-vertical-right .ant-menu-item,
        .ant-menu-inline .ant-menu-item,
        .ant-menu-vertical .ant-menu-submenu-title,
        .ant-menu-vertical-left .ant-menu-submenu-title,
        .ant-menu-vertical-right .ant-menu-submenu-title,
        .ant-menu-inline .ant-menu-submenu-title {
          padding: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        .ant-menu-vertical .ant-menu-item:not(:last-child),
        .ant-menu-vertical-left .ant-menu-item:not(:last-child),
        .ant-menu-vertical-right .ant-menu-item:not(:last-child),
        .ant-menu-inline .ant-menu-item:not(:last-child) {
          margin-bottom: 0px !important;
        }
        button {
          width: 100%;
          height: 100%;
        }
      }
      & li {
        border-bottom: 1px solid #e6e6e6;
      }
      & button {
        background: none;
        border: none;
        padding: 14px !important;
        line-height: 1 !important;
        min-width: 140px !important;
        font-size: 12px;
        color: var(--colors-litberry) !important;
        font-weight: 500;
        text-align: start;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        &:hover {
          background: var(--colors-litberry) !important;
          color: white !important;
        }
      }
    }
    @media (max-width: $medium) {
      display: none;
    }
  }
  &Content {
    padding: 40px;
    position: relative;
    flex: 1;
    .card-item {
      display: none;
      @media (max-width: $medium) {
        display: flex;
        flex-direction: column;
        .item {
          flex-direction: row;
          display: flex;
          align-items: center;
          .select {
            margin: 0px;
            margin-top: 15px;
            .button-points {
              border-color: transparent;
              background-color: transparent;
              padding: 0 10px;
              img {
                width: 4px;
              }
            }
          }
        }
        &-history {
          margin: 20px 0;
          margin-bottom: 0px;
          width: 100%;
          height: 82px;
          background: var(--colors-white);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          padding: 10px 11px;
          max-width: 91%;
          border: none;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .anticon {
            margin-left: 0px;
            svg {
              margin-right: 0 !important;
            }
            color: var(--colors-frozenberry) !important;
          }
          .icon-purple {
            margin: 10px;
            margin-top: -19px;
            width: 11px;
            height: auto;
          }
          .card-text {
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
            width: 60%;
            p {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 130.69%;
              color: #000000;
              opacity: 0.3;
              margin-top: 4px;
              margin-bottom: 0;
            }
            h4 {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 145%;
              color: var(--colors-pureberry);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin-top: 7px;
              margin-bottom: 0;
              white-space: break-spaces;
            }
          }

          .icon {
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f7f7f7;
            border-radius: 5px;
          }
        }
      }
    }
    .ant-table-content {
      overflow-x: scroll;
      max-height: 400px;
    }
    svg {
      max-width: 17px;
    }
    @media (max-width: $medium) {
      padding: 30px 15px;
      border-radius: 10px;
      background-color: #ffffff;
      padding-bottom: 100px;
      position: initial;
      .ant-input-group {
        display: flex;
        flex-direction: row-reverse;
      }
      .ant-input {
        height: 50px;
        padding-left: 50px;
      }
      .ant-input-group > .ant-input:first-child,
      .ant-input-group-addon:first-child {
        box-shadow: 4px 4px 8px rgb(0 0 0 / 5%);
        border-radius: 5px;
      }
      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        height: 30px;
        width: 40px;
        background-color: transparent;
        border-radius: 5px;
        border: none;
        padding-bottom: 4px;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-top: 10px;
        box-shadow: none;
        .anticon {
          margin-top: -3px;
          font-size: 20px;
        }
      }
      .ant-input-affix-wrapper {
        height: 50px;
        background: var(--colors-white);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 0;
        svg {
          margin-right: 0;
          margin-left: 0px;
          margin: 0 10px;
        }
      }
    }
  }
  &Divider {
    padding-top: 20px !important;
    color: var(--colors-pureberry);
    opacity: 0.35;
    &::after,
    &::before {
      border-top: 1px solid var(--colors-pureberry) !important;
      opacity: 0.45 !important;
    }
    span {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0px;
    background-color: var(--colors-white);
    width: 100%;
    h3 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: var(--colors-pureberry);
    }
  }
}

.cont-datepicker {
  @media (max-width: $medium) {
    display: none;
  }
}
.content-msj {
  @media (max-width:768px) {
    margin-top: 37px;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 100px;
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow: auto;
    position: relative;
    .msj {
      background: #FFFFFF;
      padding: 16px 20px 5px 20px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-top: 0px;
      margin-bottom: 30px;
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin-bottom: 0px;
      }
      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 130.69%;
        color: #000000;
        opacity: 0.3;
      }
    }
    .activity-message {
      position: fixed;
      background: #f6f6f6;
      width: 100%;
      left: 0;
      height: 90px;
      bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 14px;
      margin-top: 50px;
      textarea {
        height: 50px;
        min-height: 50px;
        background: #ffffff;
        border: 1.5px solid rgba(52, 10, 171, 0.55);
        border-radius: 25.2475px;
        padding: 12px 50px 11px 22px !important;
      }
      .activity-notesButton {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--colors-frozenberry);
        position: absolute;
        right: 20px;
        top: 25px;
        border: none;

      }
    }
  }
}
.title-new-interaction {
  display: none;
  @media (max-width:768px) {
    display: flex;
    padding: 20px;
    padding-bottom: 15px;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 3000;
    background: #f6f6f6;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  button {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    padding-left: 0;
    margin-top: -5px;
    img {
      margin-top: -2px;
    }
  }
  h2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--colors-pureberry);
  }
}
.head {
  display: none;
  .circle {
    background: var(--colors-pureberry);
    height: 42px;
    width: 42px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 11px;
      object-fit: contain;
      width: auto;
    }
  }
  .text {
    max-width: 75%;
    margin-left: 24px;
    h6 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
      margin-bottom: 0;
    }
    h5 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      color: #1b1b1b;
      opacity: 0.6;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 100%;
    padding-top: 85px;
  }
}

.activity {
  position: absolute;
  height: calc(100% - 25px);
  width: 100%;
  top: 20px;
  opacity: 0;
  max-width: 680px;
  right: 20px;
  z-index: 3000;

  .empty-notes {
    height: 100%;
    width: 100%;
    background-color: var(--colors-white);
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    padding: 50px;
    align-items: center;
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: 900 !important;
      font-size: 19px !important;
      line-height: 150% !important;
      background: linear-gradient(90deg, #00dbd0 0.01%, #7b3bf9 99.98%);
      -webkit-background-clip: text;
      max-width: 100% !important;
      -webkit-text-fill-color: transparent;
    }
  }
  &-notes {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    padding: 12px;
    @media (max-width: 768px) {
      display: none;
    }
    &Date {
      margin-left: 40px;
    }
    .note {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .close {
        background-color: transparent;
        border-color: transparent;
        width: 20px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        .anticon {
          font-size: 17px;
          color: #eb5757;
        }
      }
      .button-edit {
        border-radius: 50%;
        background-color: #5b2fd8;
        border-color: #5b2fd8;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        position: absolute;
        right: 30px;
      }
    }

    &Message {
      position: relative;
    }

    &Button.ant-btn {
      height: 30px;
      width: 30px;
      background-color: var(--colors-frozenberry);
      position: absolute;
      right: 13px;
      top: 15px;
      border: none;
      border-radius: 4px;
      padding: 0;
      &:focus,
      &:hover {
        text-decoration: none;
        background: var(--colors-frozenberry);
      }
    }
    h3 {
      max-width: 238px;
      border-bottom: 1px solid #4a3a7c34;
      padding-bottom: 4px;
      margin-bottom: 8px;
    }
    p {
      font-weight: normal;
      font-size: 10px;
      line-height: 130.69%;
      color: #4a3a7ce8;
      max-width: 320px;
    }
    &Date {
      font-style: italic;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.3);
      textarea {
        margin: 0 !important;
        border-radius: 0 0 10px 10px;
        border: 1px solid rgb(189, 189, 189);
        min-height: 60px;
        padding-right: 60px;
        line-height: 15px;
        font-size: 10px;
      }
    }
  }
  &-details {
    &.ant-col {
      box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.1);
      display: flex;
    }
    &Message {
      flex: 1;
      padding: 2px 10px 2px 10px;
      .buttons {
        position: relative;
        display: none;
        margin-top: 15px;
        @media (max-width: 768px) {
          display: flex;
        }
      }
      .detail-note {
        background: var(--colors-white);
        border: 1.5px solid rgba(52, 10, 171, 0.55) !important;
        border-radius: 25.2475px !important;
        border-radius: 50px !important;
        height: 50px;
        min-height: 50px;
      }
      .activity-notesButton.ant-btn {
        position: absolute;
        top: 4px;
        border-radius: 50px;
        width: 40.93px;
        padding-bottom: 5px;
        height: 40.93px;
        right: 7px;
      }
    }
    h4 {
      color: var(--colors-litberry);
      font-size: 14px;
      margin: 14px 0 6px;
      border-bottom: 1px solid #00000020;
      @media (max-width: 768px) {
        border-bottom: 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18.0888px;
        line-height: 27px;
        display: flex;
        align-items: center;
        color: var(--colors-pureberry);
      }
    }
    textarea {
      height: 140px;
      background: var(--colors-backberry);
      border-radius: 5px;
      border: none;
      @media (max-width: 768px) {
        height: 174px;
        background: #FFFFFF;
        border: 1px solid #BDBDBD !important;
        border-radius: 5px !important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 15px !important;
        line-height: 150% !important;
        color: #BDBDBD !important;
      }
    }
  }
  &-buttonDate {
    font-size: 10px;
    color: var(--colors-pureberry);
    line-height: 2;
    min-width: 160px;
    background: white;
    border: #bdbdbd solid 1px;
    border-radius: 4px;
    font-weight: bold;
    height: 30px;
    img {
      vertical-align: middle;
      &:nth-child(1) {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }

  &-content {
    background: var(--colors-backberry);
    border-radius: 10px 0 0 10px;
    border: 1px solid var(--colors-litberry);
    margin: auto;
    max-width: 680px;
    height: 100%;
    flex: 1;
    box-shadow: 10px 10px 10px -10px #00000020;

    .DayPicker {
      display: none;
    }
    .ant-picker-input {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .borderButton {
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: "";
      position: absolute;
      width: 90%;
      bottom: 0;
      max-width: 250px;
      border-bottom: 1px solid #4a3a7c34;
    }
  }
  &-title {
    font-size: 14px;
    font-weight: 500;
    color: var(--colors-litberry);
    max-width: 280px;
    padding-bottom: 0;
    margin: 0;
    line-height: 1;
  }

  &-buttons {
    text-align: center;
    margin-bottom: 10px;
    min-width: auto !important;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    button {
      width: 100%;
      margin: 5px 5px 10px;
      max-width: 95px;
      border: none;
      border-radius: 5px;
      height: 40px;
      padding: 10px !important;
      &.save {
        background: var(--colors-frozenberry);
        color: white;
      }
      &.cancel {
        background: white;
        color: var(--colors-litberry);
        border: 1px solid var(--colors-litberry);
      }
    }
  }
  textarea {
    width: 100%;
    margin: 0;
    font-size: 12px;
    padding: 10px 50px 10px 10px;
    font-size: 10px;
    line-height: 130.69%;
    color: #4a3a7ce8;
    border: 1px #bdbdbd5d solid;
    min-height: 60px;
    border-radius: 0 0 5px 5px;
  }
  &-select.ant-select {
    max-width: 300px;
    width: 100%;
    div.ant-select-selector {
      border: none;
      margin: 10px 0;
      font-size: 12px;
    }
  }
  &-details {
    width: 100%;
    background: white;
    border-left: 1px solid var(--colors-litberry);
    border-radius: 10px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    &Content {
      flex: 1;
      table {
        max-height: 150px;
      }
    }
    &Type {
      .email {
        @media (max-width: 768px) {
          border-bottom-left-radius: 15px !important;
          border-right: 1px solid var(--colors-midberry);
        }
      }
      .phone {
        @media (max-width: 768px) {
          border-left: 1px solid var(--colors-midberry);
          border-bottom-right-radius: 15px !important;
        }
      }
      .whatsapp {
        border: none;
      }
      button.ant-btn {
        background: var(--colors-litberry);
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 0;
        &.active {
          background: var(--colors-midberry);
        }
      }
      .ant-col {
        
        &:first-child {
          border-left: none;
        }
      }
    }
    &Title {
      max-width: 98%;
      margin: 16px auto 4px;
      width: 200px;
      padding: 4px 0;
      font-weight: bold;
      font-size: 12px;
      text-align: center;
      color: var(--colors-midberry);
    }
  }
  .calendar-sidebar {
    max-width: 200px;
    margin: auto;
  }
  .calendar-sidebar .ant-picker-date-panel .ant-picker-content th {
    width: 26px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 7px;
    text-align: center;
    color: rgba(0, 0, 0, 0.543337);
  }
  .calendar-sidebar .ant-picker-content td {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 7px;
    text-align: center;
    height: 18px;
    max-height: 18px;
  }
  .calendar-sidebar .ant-picker-cell-in-view {
    color: rgba(0, 0, 0, 0.867782);
  }
  .calendar-sidebar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--colors-litberry) !important;
    border-radius: 50%;
    height: 16px;
    max-height: 16px;
    line-height: 16px;
    min-width: 16px;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    height: 18px;
    max-height: 18px;
    line-height: 18px;
  }
  .calendar-sidebar .ant-picker-content tr {
    height: 18px;
    max-height: 18px;
  }
  .calendar-sidebar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none !important;
  }
  .calendar-sidebar .ant-select-selector {
    border: none !important;
  }
  .calendar-sidebar .ant-select-selector span.ant-select-selection-item {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--colors-pureberry);
  }
  .calendar-sidebar .ant-picker-calendar-header {
    flex-direction: row-reverse;
    padding: 12px 0 !important;
  }
  .calendar-sidebar .ant-picker-calendar-header .ant-picker-calendar-month-select {
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    right: 0;
    height: auto;
    .activity-content {
      border: 0px;
      border-radius: 0;
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: #5D30DD !important;
        color: #ffffff;
      }
      .DayPicker {
        width: 100%;
        display: none;
        &-Day-calendar.DayPicker--today {
          color: #009688;
        }
        
        &-Month {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 90%;
        }
        .DayPicker-NavButton {
          display: none;
        }
        &-Caption div {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700 !important;
          font-size: 18.0888px !important;
          line-height: 27px; 
          color: var(--colors-pureberry);
        }
        &-Weekday, abbr {
          padding-bottom: 16px !important;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 11.0842px;
          line-height: 13px;
          color: rgba(0, 0, 0, 0.543337);
        }
        .DayPicker-Day {
          padding: 0 !important;
        }
        .DayPicker-Day div {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 11.0842px;
          line-height: 13px;
          text-align: center;
          padding: 13px;
          color: rgba(0, 0, 0, 0.867782);
        }
        .DayPicker-Day-calendar.DayPicker--active {
          height: 95%;
          width: 100%;
          background: #5D30DD !important;
          padding: 13px 7px;
          border-radius: 50%;
          color: #ffffff !important;
        }
        @media (max-width: $medium) {
          display: inline-block;
        }
      }
      .ant-col {
        position: initial;
      }
      .activity-notes {
        h3 {
          display: none;
        }
      }
      .activity-notesMessage {
        background-color: var(--colors-white);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;

        textarea {
          background: var(--colors-white);
          border: 1.5px solid rgba(52, 10, 171, 0.55);
          border-radius: 25.2475px;
          border-radius: 50px;
          height: 50px;
          min-height: 50px;
          display: none;
        }
        .activity-notesButton.ant-btn {
          display: none;
          position: absolute;
          top: 4px;
          border-radius: 50px;
          width: 40.93px;
          padding-bottom: 5px;
          height: 40.93px;
        }
      }
      .ant-row {
        flex-direction: column;
        .ant-col-16 {
          padding: 0px !important;
          width: 100%;
          background-color: var(--colors-white);
          max-width: 100%;
          min-height: 350px;
          height: auto;
        }
        .ant-col-8 {
          flex: 100%;
          max-width: 100%;
        }
        .activity-details {
          border-left: 0px solid var(--colors-litberry);
          border-radius: 0;
          background-color: #f6f6f6;
          .ant-col-8 {
            flex: auto;
            max-width: 100%;
          }
          .activity-buttons {
            margin-top: 24px;
            button {
              width: 50% !important;
              min-width: 47% !important;
              height: 50px !important;
              
            }
          }
          .activity-detailsType {
            flex-direction: row !important;
          }
        }
      }
    }
  }
  &-edit {

  }
}

.modal-warnning-delete {
  width: 700px !important;
  border: 10px;
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    background: var(--colors-warningberry);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    .buttons-modal {
      margin-right: 20px;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
    button {
      width: 63.7px;
      height: 51.65px;
      margin: 0 10px;
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--colors-white);
    }
    .yes {
      background: #27ae60;
    }
    .no {
      background: #eb5757;
    }
  }
}

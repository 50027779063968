.experience-editing {
  padding: 22px 23px 10px 24px;

  &__card {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

    .ant-card-body {
      padding: 0;
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 18px;
      padding: 20px 22px 0 24px;

      i {
        font-size: 11px;
        &::before {
          color: var(--colors-white);
        }
      }
      a {
        background: var(--colors-litberry);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .insurance {
        display: flex;
        justify-content: flex-start;
        height: 34px;
      }

      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-litberry);
        margin: 0 12px;
        width: 84%;
        @media (max-width: 768px) {
          width: 70%;
        }
      }
    }

    &Context {
      display: flex;
      flex-direction: column;
      padding: 0 24px 0 24px;

      &__wrapper {
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px 0px 20px 20px;
        width: 100%;
        @media (max-width: 768px) {
          display: none;
        }

        h4 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: var(--colors-pureberry);
        }

        &__droppable {
          background: white;
          border: none;
          display: flex;
          width: 100%;
          height: 80px;
          overflow: auto;
          flex-direction: row;
          align-items: center;

          .experience-editing__cardContext__content__listInsuranceCarriers {
            margin-bottom: 0px;
          }
          .experience-editing__cardContext__content__listInsuranceCarriers.auto {
            background-color: #340AAB;
            border-color: #340AAB;
            img {
              filter: brightness(45);
            }
          }

          &Item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            .item {
              width: 117.72px;
              height: 52px;
              background-image: url("../../../../../../../assets/images/dashed.png");
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;

              &--selected {
                border: 2px solid var(--colors-litberry);
                border-radius: 5px;
                width: 117.72px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          &::-webkit-scrollbar {
            background: #5f5f5f2c;
            height: 7px;
            cursor: pointer;
          }
          &::-webkit-scrollbar-thumb {
            cursor: pointer;
            border-radius: 10px;
            background-color: #5f5f5f3b;
          }
        }
      }

      &__content {
        padding: 26px 0 0 48px;
        .SliderInsuranceCarriers {
          display: none;
        }
        @media screen and (max-width: 768px) {
          padding: 0px;
          h4, .experience-editing__cardContext__content__list {
            display: none;
          }
          .SliderInsuranceCarriers {
            display: block;
            .slick-arrow {
              display: none !important;
            }
            .slick-dots li button:before {
              font-family: "slick";
              font-size: 0px;
              line-height: 20px;
              position: absolute;
              top: 10px;
              left: 0;
              width: 8px;
              height: 8px;
              content: "•";
              text-align: center;
              border: 1px solid var(--colors-litberry);
              opacity: 1;
              color: black;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              border-radius: 50%;
            }
            .slick-dots li.slick-active button:before {
              opacity: 1;
              color: black;
              background: var(--colors-litberry);
            }
            .btn {
              height: 50px;
              border: 1.5px solid #5D30DD;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 95% !important;
              margin: 4.5px 0;
              &--active {
                background: #340AAB;
                border-color: #340AAB;
              }
              img {
                height: 21px;
                width: auto;
                margin: 0 auto;
                max-width: 80px;
              }
            }
          }
        }
        h4 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: var(--colors-pureberry);
          margin: 0;
        }

        &__list {
          background: white;
          border: none;
          display: flex;
          align-items: center;
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 24px;
          @media screen and (max-width: 768px) {
            justify-content: center;
          }
          
          &InsuranceCarriers {
            border: 2px solid var(--colors-litberry);
            border-radius: 5px;
            min-width: 117.72px;
            margin-right: 10px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
            &--selected {
              background-color: var(--colors-litberry);
              border-radius: 5px;
              width: 117.72px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            @media screen and (max-width: 768px) { 
              min-width: 100px;
            }
          }
          
        }
      }
    }

    &Buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 91.31px;
      margin-top: 34px;
      background: #f7f7f7;
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      @media (max-width: 768px) {
        margin-top: 50px;
        justify-content: space-around;
        button {
          margin: 0 !important;
          width: 48% !important;
        }
      }

      .outline {
        width: 163.72px;
        height: 51px;
        background: var(--colors-white);
        border: 2px solid var(--colors-litberry);
        box-sizing: border-box;
        border-radius: 5px;

        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-black);
        margin-right: 6px;
      }

      .filled {
        width: 163.72px;
        height: 51px;
        background: var(--colors-frozenberry);
        border: 2px solid var(--colors-frozenberry);
        box-sizing: border-box;
        border-radius: 5px;

        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-white);
        margin-left: 6px;
      }
    }
  }
  @media screen and (max-width: 768px) {
		padding: 0 !important;
    margin-top: 50px;
    &-editing__card {
      border-radius: 15px !important;
    }
  }
}

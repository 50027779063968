.HeaderFeedBack {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c0e42;
  border-radius: 10px;
  padding: 20px;
  .box {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &-two {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
    .box-item {
      display: flex;
      width: 45%;
      align-items: center;
      justify-content: center;
      &-two {
        width: 45%;
      }
    }
    .card-policies {
      padding: 20px;
      gap: 10px;
      height: 78px;
      background: #342856;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      display: flex;

      &__head {
        display: flex;
        justify-content: space-evenly;
        gap: 10px;
        padding: 0;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .circle {
            margin-bottom: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #ffbb0d;
            border: 1px solid #ffbb0d;
            &--conectado {
              background: #25d366;
              border: 1px solid #25d366;
            }
            &--vencida {
              background: #ffbb0d;
              border: 1px solid #ffbb0d;
            }
            &--proceso {
              background: transparent;
              border: 1px solid #ffbb0d;
            }
          }
          &-image {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          background-color: transparent;
          border: none;
          box-shadow: none;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #35d8d0;
          img {
            margin-left: 10px;
          }
        }
      }
      &__center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid var(--colors-pureberry);
        span {
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-left: 5px;
            margin-right: 10px;
            &--proceso {
              background: transparent;
              border: 1px solid #ffbb0d;
            }
            &--revisar {
              background: #ffbb0d;
              border: 1px solid #ffbb0d;
            }
            &--conectada {
              background: #25d366;
              border: 1px solid #25d366;
            }
          }
        }
      }
    }
    h1 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 0;
      margin-left: 20px;
    }
    .button-transparent {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0 10px;
      width: 45px;
    }
    input {
      &::placeholder {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        opacity: 1;
        color: #4b3a7c;
      }
    }
    .piaImage {
      width: 59.68px;
      height: 62.36px;
    }
    .ant-input-affix-wrapper {
      height: 50px;
      border-radius: 5px;
    }
    .ant-input-prefix {
      margin-right: 10px;
    }
  }
  .loader-small {
    padding: 0px;
  }
  @media (max-width: $medium) {
    border-radius: 0;
    flex-direction: column;
    padding: 20px 15px;
    .box {
      h1 {
        font-size: 25px;
        margin-left: 20px;
      }
    }
    .box {
      width: 100%;
      &:first-child {
        margin-bottom: 20px;
      }
      .button-transparent {
        display: none;
      }
      .box-item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
      }
      .card-policies {
        width: 183.7px;
        flex-direction: column;
        padding: 15px;
        height: auto;
        &__head {
          width: 100%;
        }
      }
      &-two {
        flex-direction: column;
        width: 100%;
        .card-policies {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 380px) {
    .box-two {
      flex-direction: row;
      .card-policies {
        margin-right: 10px;
        margin-bottom: 0px;
      }
    }
  }
}

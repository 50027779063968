.information {
  padding: 0px 23px 0px 24px;

  &-page-actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 20px;

    .btn-custom--preview {
      width: 50%;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }

    .btn-custom--secundary {
      width: 50%;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__cardProfile--mobile {
    display: none;
  }

  &__cardProfile--desktop {
    display: block;
    width: 100%;
    height: auto;
    min-height: 250px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 2px;
    overflow: hidden;

    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      .button-complete {
        display: none;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          margin-top: 30px;
          width: 90%;
          border-top: 1px solid #f0f0f0;
          padding-top: 15px;
          .subtitle {
            font-family: "Lato";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #5d30dd;
            margin-bottom: 10px;
          }
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto !important;
            width: 100%;
            margin-top: 0px !important;
          }
        }
      }
      .image--circle {
        .circle {
          width: 0 !important;
          height: 0 !important;
          svg {
            width: 0;
            height: 0;
          }
        }
      }
    }

    .ant-card-body {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    .image {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 23px 39px 57px 27px;
      .content-image {
        .detail-level {
          display: none;
        }
      }

      img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-agentLevel {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 17px;

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: 900;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: var(--colors-litberry);
          margin: 3px 4px 0 3px;
        }
      }
      .checked {
        width: 16.88px;
        height: 18.18px;
      }

      .info {
        width: 10.28px;
        height: 10.28px;
      }
    }

    .informationAgent {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .responsive-networks {
        display: none;
        @media (max-width: 768px) {
          display: flex;
          .information__cardProfile--desktop .informationAgent-contact {
            margin-top: 21px;
            width: 100%;
            display: flex;
            justify-content: space-around;
          }
        }
      }
      .desktop-networks {
        @media (max-width: 768px) {
          display: none;
        }
      }

      &-name {
        display: flex;
        width: 100%;
        margin-top: 20px;
        align-items: flex-start;
        img {
          width: 12px;
          height: 12px;
          margin-left: 6px;
        }

        h1 {
          display: flex;
          flex-direction: row;
          font-family: Lato;
          font-style: normal;
          font-weight: 900;
          font-size: 30px;
          line-height: 36px;
          color: var(--colors-pureberry);
          margin: 0;
          @media (max-width: 768px) {
            font-family: "Lato";
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 30px;
          }
        }
        @media (max-width: 768px) {
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: flex-start;
          img {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }

      &-data {
        height: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        margin-top: 10px;
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 130.69%;
          color: var(--colors-black);
          opacity: 0.5;
          margin: 0;
        }
        &__address {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .icon-location {
            margin-right: 7px;
          }
          i {
            font-size: 15px;
            @media (max-width: $medium) {
              font-size: 18px;
              margin-right: 7px;
              margin-top: -10px;
              .icon-location:before {
                color: var(--colors-pureberry);
              }
            }
          }
          @media (max-width: $medium) {
            .icon-location:before {
              color: var(--colors-pureberry);
            }
          }
        }
        @media (max-width: $medium) {
          flex-direction: column;
          align-items: flex-start;
          height: auto;
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: #1b1b1b;
          }
          &__address {
            margin: 0;
            margin-top: 20px;
            align-items: flex-start;
            i {
              margin-top: -3px;
            }
          }
        }
      }

      &-contact {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto;
        margin-top: 22px;
        @media (max-width: 768px) {
          width: 80%;
          justify-content: space-around;
        }
        .iconNet {
          width: 20px;
          height: 20px;
          margin: 0 10px;
        }
        i {
          margin: 0 10px;
          font-size: 20px;
        }
      }

      &-summary {
        margin-top: 18px;
        min-height: 60px;
        overflow: auto;
        display: flex;
        align-items: center;
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 150%;
          color: var(--colors-pureberry);
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
          max-width: 390px;
        }

        a {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 125.19%;
          color: var(--colors-litberry);
          margin-left: 0px;
          margin-top: 45px;
          min-width: 85px;
          @media (max-width: $medium) {
            margin-top: 5px;
          }
        }
        &--active {
          flex-direction: column;
          align-items: flex-start;
          p {
            text-overflow: inherit;
            display: inherit;
            -webkit-line-clamp: inherit; /* number of lines to show */
            -webkit-box-orient: unset;
            max-width: 100%;
            text-align: justify;
            margin-bottom: 10px;
            height: auto;
            overflow-y: auto;
          }
          a {
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .informationAgent ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      display: none;
    }

    .advisers {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .edit {
      width: 20%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin: 20px 22px 0 0;
      i {
        font-size: 11px;
        &::before {
          color: var(--colors-white);
        }
      }
      a {
        background: var(--colors-litberry);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        display: none;
        margin-left: 10px;
      }
      .EditSvg {
        display: unset;
      }
      .EditSvgW {
        display: none;
      }
      img {
        width: 13px;
        height: 12px;
      }
    }
  }

  &__cardSponsor {
    width: 100%;
    height: 63px;
    background: #f7f7f7;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      background: #5f5f5f2c;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #5f5f5f3b;
    }

    img {
      margin-right: 120px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .information {
    &__cardProfile {
      .informationAgent {
        &-name {
          h1 {
            font-size: 28px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1060px) {
  .information {
    &__cardProfile {
      .informationAgent {
        &-name {
          h1 {
            font-size: 24px;
          }
        }

        &-data {
          width: 304px;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .information {
    padding: 0;
    margin-top: -34px;
    .ant-card {
      background-color: transparent;
    }
    &__cardProfile--desktop {
      border-radius: 0;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .image {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        min-height: 290px;
        background: #ffffff;
        border-radius: 0;
        width: 100%;
        justify-content: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0;
        padding-bottom: 20px;
        align-items: center;
        .responsive-cont {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .Avatar {
          margin-left: 35px;
          margin-top: 32px;
          position: relative;
          z-index: 2;
          width: 90px;
          height: 90px;
        }
        .image-agentLevel {
          width: 50%;
          justify-content: flex-start;
          margin-left: 0;
          margin-bottom: 5px;
        }
        .content-image {
          margin-left: 50px;
          .detail-level {
            display: block;
            .button {
              min-width: 100px !important;
              width: calc(100% - 30px);
              max-width: 240px;
              padding: 0px !important;
            }
            p {
              font-family: Lato;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--colors-pureberry);
              margin-bottom: 0;
            }
            .span {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 130.69%;
              color: #000000;
              opacity: 0.35;
            }
            .button {
              margin-top: 10px !important;
            }
          }
        }
        &-agentLevel {
          margin-left: 20px;
        }
      }
      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        .image--circle {
          position: relative;
          width: 121px !important;
          height: 121px;
          display: flex;
          justify-content: center;
          align-items: center;
          .circle {
            display: flex;
            width: auto !important;
            height: auto !important;
          }
          svg {
            position: absolute;
            left: 17px;
            top: 16px;
            width: 121px !important;
            height: 121px !important;
          }
          text {
            display: none !important;
          }
        }
        .edit {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 22px 30px 0;
          .EditSvgW {
            display: unset;
            width: 10px;
            height: 10px;
          }
          .EditSvg {
            display: none;
          }
          a {
            background: var(--colors-frozenberry);
            height: 50px !important;
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            width: auto;
            min-width: 230px;
            color: var(--colors-white);
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: flex;
            }
          }
        }
      }
      .informationAgent {
        width: 100%;
        margin-top: 20px;
        background-color: #ffffff;
        padding: 20px;
        padding-top: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        &-data {
          width: 100%;
        }
        &-summary {
          align-items: flex-start;
          flex-direction: column;
          p {
            width: 100%;
            max-width: 100%;
          }
        }
      }
      .edit {
        background-color: #ffffff;
        margin: 0;
        height: 100px;
        padding-bottom: 30px;
        a {
          background-color: #5d30dd !important;
          width: calc(100% - 40px);
        }
      }
    }

    &__cardProfile--mobile {
      display: block;
      width: 100%;
      height: 526px;
      background: var(--colors-white);
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 2px;
      overflow: hidden;

      .ant-card-body {
        height: 100%;
        padding: 0;
      }

      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 22px 0 30px 0;

        img {
          width: 120px;
          height: 120px;
        }

        &-agentLevel {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 17px;

          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: var(--colors-litberry);
            margin: 3px 4px 0 3px;
          }
        }
        .checked {
          width: 16.88px;
          height: 18.18px;
        }

        .info {
          width: 10.28px;
          height: 10.28px;
        }
      }

      .informationAgent {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &-name {
          img {
            width: 12px;
            height: 12px;
            margin-left: 6px;
          }

          h1 {
            display: flex;
            flex-direction: row;
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 30px;
            color: var(--colors-pureberry);
            margin: 0;
          }
        }

        &-data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 375px;
          margin: 20px 0 17px 0;

          img {
            width: 9.97px;
            height: 14.24px;
            margin: 20;
          }

          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 130.69%;
            color: var(--colors-black);
            opacity: 0.5;
            margin-bottom: 7px;
          }
        }

        &-contact {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          img {
            width: 30px;
            width: 30px;
            margin-right: 53px;
          }
        }

        &-summary {
          margin-top: 10px;

          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 120%;
            color: var(--colors-black);
            margin: 0;
          }

          a {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 125.19%;
            color: var(--colors-pureberry);
            margin: 0;
          }
        }
      }

      .advisers {
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
  .card-alert {
    width: 400px;
    padding: 30px;
    padding-top: 20px;
    border-radius: 10px;
  }
  .ant-dropdown-menu-item {
    white-space: inherit;
  }
}

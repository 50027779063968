.ModalLinkTree {
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
	}
	.ant-modal-content {
    border-radius: 13px;
	}
	.ant-modal-close-x {
		color: var(--colors-litberry);
    font-size: 18px;
	}
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .berry_link {
      width: 96px;
      height: 96px;
	  object-fit: cover;
	  &--active {
		  display: none;
	  }
		}
		.button--linktree {
			width: 100%;
			max-width: 427.23px;
			margin-bottom: 20px;
			height: 66px;
			border: 1px solid var(--colors-oldberry);
			box-sizing: border-box;
			border-radius: 5px;
			display: flex;
			align-items: center;
			position: relative;
			justify-content: center;
			font-family: Lato;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			color: var(--colors-pureberry);
		}
		.cont-img {
			position: absolute;
			width: 46px;
			height: 46px;
			left: 12px;
			background: var(--colors-darkberry);
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			.white {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 26px;
				height: 26px;
				background: var(--colors-white);
				border-radius: 50%;
			}
			img {
				margin: 0 !important;
			}
		}
		.buts {
			margin-top: 40px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			p {
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 150%;
				text-align: left;
				color: var(--colors-pureberry);
				max-width: 427.23px;
				margin: 20px 0;
			}
			.back {
				background-color: transparent;
				border-color: transparent;
				position: absolute;
				left: 20px;
				top: 20px;
				.anticon {
					color: var(--colors-frozenberry);
					font-size: 26px;
				}
			}
			.btn-see-video {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				text-align: center;
				text-decoration-line: underline;
				color: var(--colors-litberry);
				background-color: transparent;
				border-color: transparent;
				margin-bottom: 30px;
				span {
					text-decoration-line: underline;
				}
			}
			.text {
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				text-align: left;
				font-size: 28px;
				margin-left: 22px;
				line-height: 34px;
				background: linear-gradient(90deg, #00DBD0 0.01%, #7B3BF9 99.98%);
				text-transform: uppercase;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			&__content {
				width: 100%;
				margin-top: 20px;
				
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			&--active {
				.buts__content {
					width: 70%;
				}
				.cont-video {
					width: 100%;
					
					.btn-help {
						width: 65.84px;
						height: 65.84px;
						border-radius: 50%;
						background: var(--colors-litberry);
						position: absolute;
						bottom: 40px;
						right: 10px;
					}
					.video {
						width: 100%;
						max-width: 568.68px;
						height: 363.4px;
						border-radius: 10px;
					}
					iframe {
						width: 100% !important;
						margin-top: 0 !important;
					}
				}
				p {
					display: none;
				}
				.text {
					display: none;
				}
				.btn-see-video  {
					display: none;
				}
			}
		}
  }
}

.migrated-greetings {
  &__modal {
    width: 888.96px !important;
    border-radius: 15px;
    min-height: 504px;
    margin-top: -20px !important;

    .ant-modal-header,
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-content {
      border-radius: 23px;
    }
    .anticon {
      color: var(--colors-white);
      font-size: 20px;
    }
    .ant-modal-body {
      background: linear-gradient(
        274.08deg,
        rgba(93, 48, 221, 0.67) -2.97%,
        #7f14fc 56.11%
      ) !important;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      height: 100%;
      min-height: 504px;
      padding: 50px;
      display: flex;
    }
    .box-left {
      width: 70%;
      height: 100%;
      min-height: 450px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      .cont-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .btn--succes {
          background: var(--colors-frozenberry);
          border-color: var(--colors-frozenberry);
          margin-top: 30px;
          color: var(--colors-white);
        }
        .btn--cancel {
          background: var(--colors-white);
          border-color: var(--colors-white);
          margin-top: 10px;
          color: var(--colors-litberry);
        }
        .btn {
          width: 185.22px;
          border-radius: 5px;
          height: 50px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .box-right {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 50px;
      line-height: 60px;
      color: var(--colors-white);
    }
    .text {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 150.19%;
      color: var(--colors-white);
      margin: 10px 0;
      max-width: 400px;
      &--two {
        margin-top: 60px !important;
      }
      .link {
        color: var(--colors-frozenberry);
        text-decoration: underline;
      }
    }
  }
}

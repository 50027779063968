.marketplace {
  background-color: white;
  border-radius: 15px 0px 0px 15px;
  padding: 38px;
  margin-left: 25px;
  min-height: calc(100vh - 150px);
  @media only screen and (min-width: 1441px) {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }

  .alice-carousel__wrapper {
    width: 100%;
    li {
      width: 100% !important;
    }
  }
  &__list {
    display: flex;
    overflow: auto;
    .cont-card {
      .btns {
        padding: 15px 0 !important;
      }
    }
  }
  .alice-carousel__dots {
    margin: 0;
    padding: 0;
    margin-top: 19px;
    list-style: none;
    text-align: center;
  }

  .alice-carousel__dots-item {
    width: 9px;
    height: 9px;
    border: 1px solid var(--colors-litberry);
    background: none;
  }
  .alice-carousel__dots-item:hover,
  .alice-carousel__dots-item.__active {
    background: var(--colors-litberry) !important;
  }

  .alice-carousel__stage {
    width: 25%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    &Title {
      flex: 1 1;
      display: flex;
      align-items: center;
      .line-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colors-pureberry);
        height: 34px;
        width: 34px;
        margin-right: 15px;
        border-radius: 50%;
        img {
          margin: 0 !important;
        }
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--colors-pureberry);
        margin-right: 15px;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        .anticon {
          color: var(--colors-white);
          font-size: 20px;
        }
      }
      h3 {
        margin: 0;
        font-family: "Lato";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
      }
      img {
        margin: 0 28px 0 0;
      }
    }

    .ant-btn {
      padding: 0;
    }

    &Filter {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  &__know-more {
    width: 100%;
    height: 154px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    background: var(--colors-darkberry);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0 24px;
    margin-bottom: 31px;

    &Info {
      width: 34%;
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-white);
        margin-bottom: 12px;
      }

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: var(--colors-white);
        margin-bottom: 11px;
      }
    }

    &Cards {
      width: 66%;
      padding-left: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &--responsive {
        display: none;
      }
      .border-card {
        padding: 1px;
        background: linear-gradient(90deg, #00DBD0 0.01%, #7B3BF9 99.98%);
        margin-left: 20px;
        border-radius: 5px;
      }
      &__card {
        background: var(--colors-darkberry);
        height: 123.28px;
        border-radius: 5px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 3840px) {
          width: 123.28px;
        }

        @media screen and (max-width: 1440px) {
        }

        img {
          margin-bottom: 14px;
        }

        p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          color: var(--colors-white);
          text-align: center;
          margin: 0;
        }
      }
    }
  }

  &__aseguradoras {
    flex-flow: nowrap !important;
    overflow: auto;
    padding-bottom: 0px;
    &::-webkit-scrollbar {
      border-radius: 10px;
      height: 0px;
      background: rgb(236, 236, 236);
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(177, 177, 177);
      border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0px;
    }

    &Title {
      margin: 0 0 20px 0;
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
    }
    .slick-dots {
      bottom: 0px;
      margin-top: 20px;
      position: relative;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots li button:before {
      font-family: "slick";
      font-size: 0px;
      width: 8px;
      height: 8px;
      border: 1px solid var(--colors-litberry);
      opacity: 1;
      border-radius: 50%;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: black;
      background: var(--colors-litberry);
    }
    &__logo {
      background: #f7f7f7;
      border-radius: 5px;
      width: 95% !important;
      max-width: 100%;
      height: 60px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.5s linear;
      img {
        max-width: 70%;
      }
      .icon {
        display: flex;
      }
      .icon-white {
        display: none;
      }
      &:hover {
        background: var(--colors-litberry);
        .icon {
          display: none;
        }
        .icon-white {
          display: flex;
        }
        @media (max-width: $medium) {
          background: #f7f7f7 !important;
        }
      }
      &__selected {
        background: var(--colors-litberry);
        border-radius: 5px;
        width: 95% !important;
        max-width: 100%;
        height: 60px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon-white {
          display: none;
        }
      }
    }
  }
  &__specials {
    margin-bottom: 55px;
    .ShareButton-responsive {
      display: none !important;
    }
    .btn-close {
      display: none !important;
    }
    .icons {
      display: flex !important;
    }
    .slick-arrow {
      display: none !important;
    }
    .cont-card {
      max-width: 100%;
      margin-left: 0px;
      margin-right: 5px;
      width: 100%;
      @media (max-width: $medium) {
      }
      .card {
        max-width: 100%;
      }
      .card__background {
        object-fit: cover;
      }
    }
    &__header {
      display: flex;
      flex-direction: row;
      margin-bottom: 27px;
      &Title {
        flex: 1 1;
        display: flex;
        align-items: center;
        h3 {
          margin: 0;
          font-family: "Lato";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--colors-pureberry);
        }
      }
      &More-button {
        flex: 1 1;
        display: flex;
        justify-content: flex-end;
        button {
          background: var(--colors-litberry);
          border-radius: 5px;
          width: 94px;
          height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }
    }
    .card {
      width: 100%;
      height: 254px;
      position: relative;
      border-radius: 10px;
      display: flex;
      margin-right: 7px;
      &__background {
        width: 100%;
        height: 254px;
        object-fit: cover;
        position: absolute;
        z-index: 1;
        border-radius: 10px;
        border-radius: 5px;
      }
      &__content {
        z-index: 99;
        width: 100%;
        height: 254px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55));
        border-radius: 5px;

        &Header {
          padding: 18px 20px;
          height: 20%;
        }

        &Body {
          height: 80%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          &--active {
            display: flex;
            flex-direction: column;

            &__insurances {
              width: 100%;
              height: 85%;
              display: flex;
              align-items: flex-end;
              flex-direction: row;
              padding: 0 15px 10px 19px;

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
                width: 80px;
              }

              .insurance {
                width: 26px;
                height: 26px;
                background: var(--colors-white);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &__visibility {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              width: 100%;
              height: 15%;
              background: var(--colors-pureberry);
              box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
              padding: 0 15px 0 19px;
              border-radius: 0 0 5px 5px;

              img {
                cursor: pointer;
              }

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
              }
            }
          }

          &--disabled {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            &__insurances {
              display: flex;
              flex-direction: row;
              padding: 16px 19px;

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
                width: 80px;
              }

              .insurance {
                width: 26px;
                height: 26px;
                background: var(--colors-white);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &__visibility {
              display: flex;
              flex-direction: row;
              padding: 8px 15px;

              img {
                cursor: pointer;
              }

              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &__categories {
    margin-top: 15px;
    .cards-category {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 100%;
      @media (max-width: $medium) {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .cont-card {
        width: 259px;
        margin-right: 40px;
      }
    }
    &__header {
      display: flex;
      flex-direction: row;
      margin-bottom: 27px;
      &Title {
        flex: 1 1;
        display: flex;
        align-items: center;
        i {
          margin-right: 15px;
          font-size: 16px;
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--colors-pureberry);
          margin-left: 20px;
          .anticon {
            color: var(--colors-white);
            font-size: 20px;
          }
        }
        img {
          margin-right: 13px;
          filter: brightness(0.5);
        }
        h3 {
          margin: 0;
          font-family: "Lato";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--colors-pureberry);
        }
      }
      &More-button {
        flex: 1 1;
        display: flex;
        justify-content: flex-end;
        button {
          background: var(--colors-litberry);
          border-radius: 5px;
          width: 94px;
          height: 40px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-white);
        }
      }
    }

    .card-small {
      width: 90%;
      height: 187px;
      position: relative;
      border-radius: 10px;
      display: flex;
      margin-right: 7px;
      &__background {
        width: 100%;
        height: 187px;
        position: absolute;
        z-index: 1;
        border-radius: 10px;
        border-radius: 5px;
      }
      &__content {
        z-index: 99;
        width: 100%;
        height: 187px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(
          0deg,
          rgba(74, 58, 124, 0.308),
          rgba(74, 58, 124, 0.301)
        );
        border-radius: 5px;

        &Header {
          padding: 18px 20px;
          height: 20%;

          p {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: var(--colors-white);
          }
        }

        &Body {
          height: 80%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          &--active {
            display: flex;
            flex-direction: column;

            &__insurances {
              width: 100%;
              height: 85%;
              display: flex;
              align-items: flex-end;
              flex-direction: row;
              padding: 0 15px 10px 19px;

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
                width: 80px;
              }

              .insurance {
                width: 26px;
                height: 26px;
                background: var(--colors-white);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &__visibility {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              width: 100%;
              height: 15%;
              background: var(--colors-pureberry);
              box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
              padding: 0 9px 0 12px;
              border-radius: 0 0 5px 5px;

              img {
                width: 16px;
                height: 11px;
                cursor: pointer;
              }

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
              }
            }
          }

          &--disabled {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            &__insurances {
              display: flex;
              flex-direction: row;
              padding: 8px 12px;

              p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 15px;
                color: var(--colors-white);
                margin: 0;
                width: 80px;
              }

              .insurance {
                width: 26px;
                height: 26px;
                background: var(--colors-white);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &__visibility {
              display: flex;
              flex-direction: row;
              padding: 12px 11px;
              p {
                display: none;
              }

              img {
                width: 16px;
                height: 11px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      padding-left: 0 !important;
      &__header {
        padding-left: 20px;
      }
      .cards-category {
        .cont-card {
          width: 259px;
          margin-right: 5px;
          margin-left: 20px;
        }
      } 
    }
    
  }
  .flex-center {
    display: flex;
    margin-top: 50px;
    .icon-line {
      background-color: var(--colors-white);
      height: 26px;
      width: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin: 0 !important;
      }
    }
    .marging {
      margin-top: -35px;
    }
    .visible-eye {
      position: absolute;
      bottom: 0;
      height: 30px;
      width: 100%;
      background-color: var(--colors-pureberry);
      left: 0;
      padding: 0 20px;
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 15px;
        color: var(--colors-white);
      }
    }
  }
  &__products {
    display: flex;
    justify-content: flex-start;
    &__header {
      margin-bottom: 35px;
      h3 {
        margin: 0;
        font-family: "Lato";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-litberry);
      }
    }
    &__card {
      background: #f7f7f7;
      border-radius: 10px;
      width: 260px !important;
      margin-right: 20px;
      .anticon {
        margin-right: 10px;
        font-size: 20px;
        color: var(--colors-frozenberry);
      }
      .caroucel-market-two-section {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        &::-webkit-scrollbar {
          background: #5f5f5f2c;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #5f5f5f3b;
        }
        
      }
      @media only screen and (min-width: 1441px) {
        max-width: 280px;
      }
      &__header {
        padding: 23px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        min-height: 206px;
        &::before {
          content: "";
          position: absolute;
          background: linear-gradient(0deg, rgba(75, 58, 124, 0.55), rgba(75, 58, 124, 0.55));
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          z-index: 1;
        }
        &Background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          object-fit: cover;
        }
        &Categorie {
          position: relative;
          display: flex;
          align-items: center;
          z-index: 2;
          img {
            margin-right: 11px;
          }
          h4 {
            font-family: "Lato";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: var(--colors-white);
            margin-bottom: 0;
          }
        }
        &Sponsor {
          position: absolute;
          z-index: 2;
          bottom: 30px;
          display: flex;
          .icon-line {
            margin-left: 10px;
          }
          p {
            font-family: "Lato";
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            color: rgba(255, 255, 255, 0.6);
          }
          img {
            margin-left: 8px;
          }
          &--mod {
            bottom: 15px;
          }
        }
        .modal-meneric-editable {
          position: absolute;
          z-index: 10;
          bottom: 4px;
          right: 20px;
        }
        &__visible {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: var(--colors-pureberry);
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          z-index: 2;
          padding: 10px 20px 10px;
          p {
            font-family: "Lato";
            font-style: normal;
            font-weight: bold;
            font-size: 7.96478px;
            line-height: 10px;
            text-align: center;
            letter-spacing: -0.02em;
            color: var(--colors-white);
            margin-bottom: 0;
          }
          &--disabled {
            background: none;
            bottom: 5px;
            p {
              opacity: 0;
            }
          }

          img {
            cursor: pointer;
          }
        }
      }
      &__body {
        padding: 20px 23px;
        a {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-litberry);
        }
        &Title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: var(--colors-pureberry);
        }
        .footer-card {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
        &Descrip {
          font-family: "Roboto";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
          color: var(--colors-pureberry);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        &Read {
          font-family: "Roboto";
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          text-decoration-line: underline;
          color: var(--colors-litberry);
        }
      }
    }
    .alice-carousel__stage-item {
      padding-right: 30px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 8px 0 12px;
    a {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: var(--colors-litberry);
    }

    .ant-btn {
      width: 75px;
      height: 40px;
      border: none;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      background: var(--colors-frozenberry);
      border-radius: 5px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--colors-white);
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      width: 75px;
      height: 40px;
      border: none;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      background: var(--colors-frozenberry);
      border-radius: 5px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--colors-white);
    }
  }

  &__footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 30px 0 12px;
    a {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: var(--colors-litberry);
    }

    .ant-btn {
      width: 75px;
      height: 40px;
      border: none;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      background: var(--colors-frozenberry);
      border-radius: 5px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--colors-white);
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      width: 75px;
      height: 40px;
      border: none;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      background: var(--colors-frozenberry);
      border-radius: 5px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: var(--colors-white);
    }
  }
  @media (max-width: $medium) {
    margin-left: 0px;
    padding: 0px;
    padding-bottom: 100px;
    &__header {
      padding: 20px;
      padding-bottom: 0;
    }
    &__specials {
      padding: 0px;
      .slick-slide {
        padding-left: 20px;
      }
      .slick-slide.slick-active.slick-current {
        padding-left: 50px;
      }
      &__headerTitle {
        margin-left: 20px;
      }
    }
    &__aseguradorasTitle {
      padding: 0 20px;
    }
    &__categories {
      padding: 0 20px;
      padding-right: 0;
      &__header {
        padding-right: 20px;
      }
    }
    &__aseguradoras {
      .ant-col-4 {
        max-width: 200px;
        flex: 0 0 45%;
      }
    }
    &__know-more {
      flex-direction: column;
      height: auto;
      padding: 20px;
      padding-right: 0;
      &Info {
        width: 100%;
        margin-bottom: 20px;
        padding-right: 20px;
      }
      &Cards {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        display: none;
        &--responsive {
          display: block !important;
          .slick-arrow {
            display: none !important;
          }
          .slick-dots li.slick-active button:before {
            opacity: 1;
            color: black;
            background: #fff;
          }
          .slick-dots {
            position: absolute;
            bottom: -34px;
          }
          .slick-dots li button:before {
            font-family: "slick";
            font-size: 0px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 8px;
            content: "•";
            text-align: center;
            border: 2px solid #fff;
            opacity: 1;
            color: black;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            border-radius: 50%;
          }
        }
        .border-card {
          margin: 10px;
          margin-left: 20px !important;
          width: 112px !important;
        }
        &__card {
          width: 110px;
          margin: 0px;
        }
        @media (max-width: 768px) {
          .border-card {
            width: 85% !important;
          }
          &__card {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.ant-modal-confirm-confirm {
  width: 649px !important;
  transform-origin: -65px 343px;
  border-radius: 32px;
  .ant-modal-confirm-body-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-modal-confirm-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: var(--colors-pureberry) !important;
  }
  .ant-btn {
    height: 50px;
    min-width: 60px;
    border-radius: 5px;
    background: #eb5757;
    border-color: #eb5757;
    color: var(--colors-white);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    &-primary {
      background: #27ae60 !important;
      border-color: #27ae60 !important;
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 0px !important;
  }
  .ant-modal-body {
    background: var(--colors-warningberry);
    .anticon.anticon-exclamation-circle {
      display: none;
    }
  }
}

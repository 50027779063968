// Headings

.h1 {
  @extend h1;
  @include font-size($typography-h-1-font-size);
  color: var(--colors-pureberry);
  line-height: 60px;
  font-weight: 900;
}
.h1-color-gradient-berry {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 52px;
  background: linear-gradient(93deg, #00dbd0 2.5%, #7b3bf9 35.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.h2 {
  @extend h2;
  @include font-size($typography-h-2-font-size);
  color: var(--colors-pureberry);
  line-height: 36px;
  font-weight: 900;
}

.h3 {
  @extend h3;
  @include font-size($typography-h-3-font-size);
  color: var(--colors-pureberry);
}

.h4 {
  @extend h4;
  @include font-size($typography-h-3-font-size);
  color: var(--colors-pureberry);
}

.h5 {
  @extend h5;
  @include font-size($typography-h-3-font-size);
  color: var(--colors-pureberry);
}

.h6 {
  @extend h6;
  @include font-size($typography-h-3-font-size);
  color: var(--colors-pureberry);
}

.sub-title {
  font-family: $typography-sub-title-font-family;
  font-weight: $typography-sub-title-font-weight;
  @include font-size($typography-sub-title-font-size);
  color: var(--colors-pureberry);
  line-height: 24px !important;
  letter-spacing: -0.02em !important;
}

.body-bold {
  font-family: $typography-body-bold-font-family;
  font-style: $typography-body-bold-font-style;
  font-weight: $typography-body-bold-font-weight;
  @include font-size($typography-body-bold-font-size);
  color: var(--colors-pureberry);
}

.body-regular {
  font-family: $typography-body-regular-font-family;
  font-style: $typography-body-regular-font-style;
  font-weight: $typography-body-regular-font-weight;
  line-height: 150% !important;
  @include font-size($typography-body-regular-font-size);
  color: var(--colors-pureberry);
}

.body-detail {
  font-family: $typography-small-detail-font-family;
  font-style: $typography-small-detail-font-style;
  font-weight: $typography-small-detail-font-weight;
  @include font-size($typography-button-txt-font-size);
  line-height: $typography-button-txt-line-height;
  color: var(--colors-pureberry);
}
.small-detail {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #b2b2b2;
}
.mobile-h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4b3a7c;
}

.welcome {
    display: flex;
    flex-direction: row;

    &__go {
        width: 54%;
        padding: 63px 56px 0;
        padding-right: 20px;
        h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 50px;
            line-height: 59px;
            color: var(--colors-white);
            margin-bottom: 0;
        }
        .name {
            text-transform: capitalize;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 150.19%;
            color: var(--colors-white);
            margin-top: 25px;
            margin-bottom: 34px;
        }

        .secundary {
            background: var(--colors-frozenberry);
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            width: 185px;
            height: 51px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin: 0;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: var(--colors-white);
            }
        }

        .secundary:hover,
        .secundary:focus,
        .secundary:active {
            background: var(--colors-frozenberry);
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 185px;
            height: 51px;

            span {
                margin: 0;
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: var(--colors-white);
            }
        }
    }

    &__image {
        width: 46%;
        display: flex;
        flex-direction: column;

        &Close {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            img {
                width: 23px;
                height: 23px;
                margin: 27px 33px;
            }
        }

        .berry {
            width: 305px;
            height: 341px;
            margin: 3px 25px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        &__go {
            width: 100%;
            padding: 20px;
            position: relative;
            z-index: 2;
            margin-top: 10px;
            .titles {
                display: flex;
            }
            h4 {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 900;
                font-size: 25px;
                line-height: 30px;
                color: #FFFFFF;
                margin-right: 4px;
            }
        }
        &__imageClose {
            position: absolute;
            top: 0px;
            right: 0;
            z-index: 10;
        }
        &__image {
            margin-top: 10px;
            .berry {
                width: 104px;
                height: 116px;
                margin: 3px 25px;
            }
        }
    }
}

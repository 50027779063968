.offices-editing {
  padding: 22px 24px 32px 24px;

  &__card {
    height: 530px;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    background-image: url(../../../../../../../../../assets/images/location.jpg) !important;

    .ant-card-body {
      padding: 0 35px 0 40px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &Header {
      height: 15%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
        margin: 0;
      }
    }

    .wrapper {
      width: 100%;
      height: 100%;
      padding: 20px 40px;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      .goBack,
      .goBack:hover,
      .goBack:active {
        position: absolute;
        left: 40px;
        background: transparent;
        border-color: transparent;
        .anticon {
          color: var(--colors-frozenberry);
          font-size: 20px;
        }
      }
      &__cont {
        display: flex;
        background: var(--colors-white);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: space-between;
        padding: 50px;
        height: 100%;
      }
      &__card {
        background: var(--colors-white);
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 50px 20px;
        width: 50%;
        max-width: 270px;
        h3 {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 20px;
          color: var(--colors-pureberry);
        }
        .state {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 30px;
        }
        h5 {
          font-family: Roboto;
          margin: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 150.19%;
          margin-left: 10px;
          color: #f2c94c;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 150%;
          margin: 0;
          color: var(--colors-pureberry);
        }
      }
      #horizontal_login {
        width: 50%;
        width: 60%;
        button {
          margin-top: 20px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      h4 {
        margin-bottom: 30px;
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
      .form-inputs {
        display: flex;
        justify-content: center;
        .ant-form-item .ant-input-number-handler-wrap {
          display: none;
        }
        .ant-input-number {
          border: 0px solid #d9d9d9;
          border-bottom: 2px solid var(--colors-litberry);
          width: 54px;
        }
        .ant-input-number-input {
          height: 50px;
          font-size: 50px;
          color: var(--colors-litberry);
          padding-bottom: 15px;
        }
        .ant-input-number:hover {
          border-bottom: 2px solid var(--colors-litberry);
          border-color: var(--colors-litberry);
          border-right-width: 0px !important;
        }
        &__item {
          width: 95px;
        }
      }
    }

    &Back {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 41px;
      width: 75%;

      img {
        width: 27px;
        height: 23px;
        margin-right: 31px;
      }

      h6 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-black);
        margin: 0;
      }
    }

    &Tabs {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .tab {
        &--active {
          width: 198px;
          height: 3.71px;
          margin-right: 7px;
          background: var(--colors-midberry);
        }
        &--disabled {
          width: 198px;
          height: 3.71px;
          margin-right: 7px;
          background: var(--colors-midberry);
          opacity: 0.3;
        }
      }
    }

    &Forms {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        width: 58%;
        align-items: flex-start;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
      }

      .ant-form label {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: rgba(51, 51, 51, 0.75);
      }

      .ant-input {
        height: 40px;
        background: var(--colors-white);
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        width: 90%;
      }
      .ant-input-number {
        height: 40px;
        background: var(--colors-white);
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        width: 90%;
      }

      .ant-input-number-input {
        height: 38px;
        background: var(--colors-white);

        box-sizing: border-box;
        border-radius: 5px;
        width: 90%;
      }
      .ant-form-item {
        margin-bottom: 16px !important;
      }

      .ant-form-item-label > label.ant-form-item-required::before {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        margin-right: 0;
        color: rgba(51, 51, 51, 0.75);
      }

      .ant-form-item-has-error .ant-form-item-explain,
      .ant-form-item-has-error .ant-form-item-split {
        margin: -5px 0 !important;
        width: 100%;
      }

      .ant-btn {
        background: var(--colors-frozenberry);
        border-radius: 5px;
        width: 143px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 9px;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        background: #2cb6af;
        border-radius: 5px;
        width: 143px;
        height: 40px;
        margin-top: 9px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }
    }

    &Finished {
      height: 100%;
      display: flex;
      align-items: center;

      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: var(--colors-pureberry);
        margin-bottom: 15px;
      }

      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 125.19%;
        color: var(--colors-pureberry);
        width: 70%;

        a {
          color: var(--colors-frozenberry);
          text-decoration: underline;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      @media (max-width: 768px) {
        .marker {
          display: none;
        }
      }

      .ant-btn {
        background: var(--colors-frozenberry);
        border-radius: 5px;
        width: 152px;
        height: 40px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }

      .ant-btn:hover,
      .ant-btn:focus,
      .ant-btn:active {
        background: #2cb6af;
        border-radius: 5px;
        width: 152px;
        height: 40px;
        margin-top: 25px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: var(--colors-white);
        }
      }

      img {
        margin-right: 100px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 50px;
    .ant-card-body {
      padding: 0;
      .wrapper {
        padding: 10px;
      }
    }
    &__cardHeader {

    }
    &__cardForms {
      width: 100%;
    }
    &__cardFinished {
      flex-direction: column;
      padding: 25px;
    }
  }
}

.validateCode {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .item {
    background-color: var(--colors-white);
      padding: 20px;
      position: relative;
      border-radius: 10px;
      margin-bottom: 20px!important;
      .ant-input {
        border: 1px solid #d9d9d9 !important;
      }
  }
  .ant-alert-message {
    overflow-wrap: break-word;
  }
  &__header {
    position: relative;
    text-align: center;
    max-width: 280px;
    &Title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 36px;
      color: var(--colors-white);
      margin-bottom: 25px;
    }
    &Descrip {
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: var(--colors-white);
      max-width: 215px;
      margin: 50px auto 40px;
    }
  }
  &__form {
    max-width: 280px;
    width: 100%;
    .ant-col {
      width: 100%;
    }
    &TxtAcces {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--colors-white);
      border-bottom: 2px solid;
      padding-bottom: 4px;
      max-width: fit-content;
      margin: 0 auto 30px;
    }
  }
  .btn-custom {
    width: 93%;
    margin-left: 10px;
    &--secundary {
      margin-bottom: 10px;
      margin-top: 0px;
    }
  }
  &__link-forgot-pass {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-white);
    border-bottom: 2px solid;
    padding-bottom: 3px;
    max-width: fit-content;
    margin-bottom: 30px;
    display: block;
  }
}

@media screen and (max-width: $small) {
  .validateCode {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    &__forgot_password {
      width: 250px !important;
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $small) {
  .validateCode {
    &__card {
      width: 300px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $s-medium) {
  .validateCode {
    &__card {
      width: 350px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $medium) {
  .validateCode {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $large) {
  .validateCode {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $x-large) {
  .validateCode {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $xx-large) {
  .validateCode {
    &__card {
      width: 450px;
      .ant-form-item-label {
        text-align: start;
      }
    }

    .alert {
      margin-top: 20px;
    }
  }
}

.col-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--colors-white);
  @media (max-width: 768px) {
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .loader {
    p {
      color: var(--colors-white) !important;
    }
  }
  &__welcome {
    max-width: 376px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 57px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &Title {
      color: var(--colors-pureberry);
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      margin-bottom: 13px;
    }
    &Subtitle {
      color: var(--colors-pureberry);
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-bottom: 40px;
    }
    &Link {
      font-weight: bold;
      font-family: "Lato";
      font-size: 12px;
      line-height: 125.19%;
      text-align: center;
      color: var(--colors-litberry);
      border-bottom: 1px solid var(--colors-litberry);
      padding-bottom: 2px;
    }
    .btn-custom {
      width: 192px;
      margin-bottom: 25px;
    }
    &ForgotPassword {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: var(--colors-litberry);
    }
  }
}
.full-height {
  .logo-berry {
    display: none;
  }
  .step-pass {
    color: var(--colors-pureberry);
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 13px;
  }
  @media screen and (max-width: $medium) {
    height: auto;
    flex-direction: column !important;
    display: flex !important;
    .logo-berry {
      display: unset;
    }
    .cont-img {
      height: 100px;
    }
    .col-signup::before {
      position: absolute;
      height: 685px;
      top: -10px;
      margin-top: 30px;
      border-radius: 15px;
    }
    .ant-col {
      max-width: 100%;
      width: 100% !important;
    }
    .col-login__welcome {
      display: none;
    }
  }
}
$colors-12: linear-gradient(194deg, #5d30dd4d 0%, #7f14fc 100%);
$colors-litberry: #36B0C9 ;
$colors-midberry: #257F91 ;
$colors-frozenberry: #E0E622 ;
$colors-backberry: #F7F7F7 ;
$colors-pureberry: #257F91 ;
$colors-coldberry: #8CD075 ;
$colors-darkberry: #184B53 ;
$colors-warningberry: #FFFAED;
$colors-smoothieberry: linear-gradient(90deg, #00DBD0 0.01%, #7B3BF9 99.98%);;
$colors-hotberry: linear-gradient(274.08deg, #E0E622 -2.97%, #36B0C9 56.11%);;

$colors-gradient: linear-gradient(0deg, #5d33e4 0%, #08cdd2 100%);
$colors-black: #1b1b1b;
$colors-white: #ffffff;
$colors-highlight-azul: #4814dc;
$colors-whatsapp: #25d366;
$colors-oldberry: #b2b2b2;
$colors-badberry: #eb5757;
$colors-gradient-image: rgba(0, 0, 0, 0);

:root .fortis {
  --colors-12: #{$colors-12};
  --colors-litberry: #{$colors-litberry};
  --colors-midberry: #{$colors-midberry};
  --colors-frozenberry: #{$colors-frozenberry};
  --colors-backberry: #{$colors-backberry};
  --colors-pureberry: #{$colors-pureberry};
  --colors-coldberry: #{$colors-coldberry};
  --colors-darkberry: #{$colors-darkberry};
  --colors-warningberry: #{$colors-warningberry};
  --colors-smoothieberry: #{$colors-smoothieberry};
  --colors-hotberry: #{$colors-hotberry};
  --colors-gradient: #{$colors-gradient};
  --colors-black: #{$colors-black};
  --colors-white: #{$colors-white};
  --colors-highlight-azul: #{$colors-highlight-azul};
  --colors-whatsapp: #{$colors-whatsapp};
  --colors-oldberry: #{$colors-oldberry};
  --colors-badberry: #{$colors-badberry};
  --colors-gradient-image: #{$colors-gradient-image};
}

.col-signup {
  &::before {
    content: "";
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    top: 40px;
    border-radius: 15px 0 0 15px;
    background: var(--colors-12);
    background-blend-mode: multiply;
  }
  @media (max-height: 660px) {
    &::before {
      height: 100%;
      top: 0 !important;
    }
  }
}
.login-text-res--fortis {
  display: none;
}
.welcomeForgotPassword--fortis {
  display: none;
}
.fortis {
  .welcomeForgotPassword--fortis {
    display: unset;
  }
  .welcomeForgotPassword--berry {
    display: none;
  }
  .login-text-res {
    &--berry {
      display: none;
    }
    &--fortis {
      display: unset;
    }
  }
}
.fortis .col-signup::before{
  background: var(--colors-pureberry);
}
.signup {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--colors-white);
  .loader {
    p {
      color: var(--colors-white) !important;
    }
  }
  .ant-checkbox-wrapper {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    color: #999999 !important;
    opacity: 1 !important;
  }
  .checked {
    margin-bottom: 10px;
  }

  .ant-checkbox {
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: #b8b8b8;
    }
  }
  .Loader {
    position: relative;
  }
  .card-login {
    padding: 20px 16px;
    min-width: 290.43px;
    padding-top: 28px;
    padding-bottom: 0;
    background: var(--colors-white);
    border-radius: 10px;
    position: relative;
    .red {
      cursor: pointer;
      width: 40px;
      margin: 0 10px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--colors-litberry);
      border-radius: 50%;
      i {
        font-size: 13px;
      }
      img {
        margin: 0 !important;
      }
    }
    figure.signup__redes {
      width: 100%;
      .images {
        display: flex;
        justify-content: center;
      }
    }
    .btn--login {
      position: absolute;
      margin-top: 10px;
      &-tertiary {
        position: absolute;
        margin-top: 70px;
        color: var(--colors-litberry) !important;
        background-color: var(--colors-white);
        display: none;
        @media screen and (max-width: $medium) {
          display: block;
        }
      }
    }
    .input-custom {
      border: 1px solid #bdbdbd !important;
      border-radius: 5px !important;
    }
    .ant-input-number {
      width: 100%;
      .ant-input-number-input {
        height: 40px;
      }
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-input-affix-wrapper,
    .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
      border-color: #ff4d4f !important;
    }
  }
  &__redes {
    position: relative;
    text-align: center;
    max-width: 280px;
    &Title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      margin-top: -60px;
      line-height: 36px;
      position: relative;
      color: var(--colors-white);
      margin-bottom: 15px;
      @media (max-width: 768px) {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
      }
    }
    img {
      margin-right: 13px;
      cursor: pointer;
      &:nth-child(4) {
        margin-right: 0;
      }
    }
    &Separator {
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: var(--colors-oldberry);
      position: relative;
      margin-top: 25px;
      margin-bottom: 20px;
      text-transform: uppercase;
      &::before {
        content: "";
        position: absolute;
        border: 1px solid #f7f7f7;
        width: 45%;
        top: 50%;
        left: 0;
      }
      &::after {
        content: "";
        position: absolute;
        border: 1px solid #f7f7f7;
        width: 45%;
        top: 50%;
        right: 0;
      }
    }
  }
  &__form {
    max-width: 280px;
    width: 100%;
    .ant-row.ant-form-item.ant-form-item-with-help.checked.ant-form-item-has-error {
      .ant-checkbox-inner {
        border: 2px solid #ff4d4f;
        opacity: 1;
      }
      .ant-form-item-explain.ant-form-item-explain-connected {
        display: none;
      }
    }
    .ant-form-item-control-input-content {
      a {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        display: flex;
        align-items: center;
        color: #000000 !important;
        opacity: 0.4;
        transform: translate(0px, -2px);
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--colors-litberry);
      border-color: var(--colors-litberry);
    }
    .ant-col {
      width: 100%;
    }
    &TxtAcces {
      cursor: pointer;
      font-family: "Lato";
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--colors-white);
      border-bottom: 2px solid;
      padding-bottom: 4px;
      max-width: fit-content;
      margin: 0 auto 30px;
      position: absolute;
      margin-top: 110px;
      margin-left: 45px;
      font-size: 14px;
    }
  }
  .btn-custom {
    width: 110%;
    margin-left: -15px;
  }
  @media screen and (max-width: $medium) {
    h1 {
      margin-top: 40px;
    }
    &__formTxtAcces {
      margin-top: 200px;
    }
  }
}

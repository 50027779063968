.button {
  font-family: $typography-button-txt-font-family !important;
  font-weight: $typography-button-txt-font-weight !important;
  @include font-size($typography-button-txt-font-size);
  line-height: $typography-button-txt-line-height !important;
  block-size: sizes($button-size-height) !important;
  padding-inline-start: sizes($button-padding-side) !important;
  padding-inline-end: sizes($button-padding-side) !important;
  border-radius: sizes($border-radius) !important;
  font-style: $typography-button-txt-font-style !important;

  &-primary {
    background-color: var(--colors-frozenberry) !important;
    color: var(--colors-white) !important;
    border: none !important;
  }
  &-secundary {
    background-color: var(--colors-litberry) !important;
    color: var(--colors-white) !important;
    border: none !important;
  }
  &-tertiary {
    background-color: var(--colors-white) !important;
    border: 1.5px solid var(--colors-litberry) !important;
    color: var(--colors-litberry) !important;
  }
  &--disabled {
    background-color: #B2B2B2 !important;
    color: var(--colors-white) !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mask-style {
  background: rgba(0, 0, 0, 0.75);
  background-blend-mode: multiply;
  backdrop-filter: blur(10px);
}

.tutorial {
  height: 100%;
  background: var(--colors-litberry);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 24px 0 38px 0;
  max-width: 900px;

  .button-menu {
    display: none;
    z-index: 400;
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--colors-midberry);
      position: absolute;
      left: 0;
      width: 145.54px;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      height: 40.48px;
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 150.19%;
        color: var(--colors-white);
        margin: 0;
        margin-left: 10px;
      }
    }
  }
  &__modal {
    padding-bottom: 0 !important;
    margin: 0 !important;

    .ant-modal-content {
      height: 100%;
      background: none;
      border-radius: 20px;
      max-width: 900px;
    }

    .ant-modal-body {
      background: none;
      height: 100%;
      padding: 0;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-close-x {
      display: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 33px 36px;

    .warnning {
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      border-bottom-right-radius: 10px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      position: absolute;
      z-index: 40;
      left: 0;
      top: 0;
      padding: 21px;
      width: 89%;
      @media screen and (max-width: 768px) {
        width: 100%;
        z-index: 500;
        flex-direction: column;
        top: 150px;
        border-radius: 10px;
        p {
          margin-bottom: 20px !important;
        }
        .yes, .no {
          margin-left: 20px;
          width: 40%;
        }
      }
      button, button:hover , button:active {
        height: 51.65px;
        border-radius: 5px;
        font-family: Roboto;
        width: 63.7px;
        font-weight: 500;
        font-size: 14px;
        color: var(--colors-white);
        margin: 0 2px;
        span {
          margin-right: 0;
        }
      }
      .yes {
        background: #27AE60;
      }
      .no {
        background: #EB5757;
      }
      h4 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-pureberry);
      }
      p {
        font-family: Roboto;
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: var(--colors-pureberry);
      }
    }

    .ant-btn {
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: var(--colors-white);
        margin-right: 31px;
      }

      img {
        width: 23px;
        height: 23px;
      }
    }

    .ant-btn:active,
    .ant-btn:hover,
    .ant-btn:focus {
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: var(--colors-white);
        margin-right: 31px;
      }

      img {
        width: 23px;
        height: 23px;
      }
    }
	}
	.ant-slider-disabled .ant-slider-track {
    background: var(--colors-midberry) !important;
	}
  .content {
    .slick-arrow {
      display: none !important;
		}
		.slick-dots li button:before {
			width: 8px;
			height: 8px;
			border: 2px solid var(--colors-white);
			border-radius: 50%;
			opacity: 1;
			color: transparent;
		}
		.slick-dots li.slick-active button:before {
			opacity: 1;
			color: transparent;
			border: 2px solid var(--colors-white);
			border-radius: 50%;
			background: var(--colors-white);
		}
		.slick-dots {
			bottom: -44px;
			width: auto;
		}
		&--item {
			padding-right: 30px;
		}
		h3 {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 28px;
			letter-spacing: -0.02em;
			color: var(--colors-white);
		}
    .title-name {
      margin-top: 30px;
      margin-bottom: 10px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      /* identical to box height, or 18px */
      
      display: flex;
      align-items: center;
      
      color: #FFFFFF;
    }
		p {
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			line-height: 150%;
			color: var(--colors-white);
			opacity: 0.75;
			margin-top: 5px;
		}
    &__img {
			background: var(--colors-litberry);
			box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 274px;
			.img {
        border-radius: 11px;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
      @media (max-width: $medium) {
        height: auto;
        .img {
          object-fit: contain;
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: row;
  }
  &__sidebar {
    width: 30%;
  }

  &__content {
    width: 70%;
    padding-left: 30px;
  }
  @media screen and (max-width: 768px) {
    &__wrapper {
      flex-direction: column;
    }
    &__sidebar {
      width: 100%;
    }
    &__header {
      .yes , .no {
        span {
          display: unset !important;
        }
      }
      .ant-btn {
        span {
          display: none;
        }
      }
    }
    &__content {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    margin: 65px 0;
    &__header {
      padding-bottom: 10px;
    }
    .content__button {
      justify-content: flex-start;
      margin-top: 60px;
    }
  }
}

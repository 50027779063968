.ShareButton {
  .email {
    height: 15px !important;
  }
  .draw__backdrop {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .secundary {
    border: none;
    background: var(--colors-frozenberry);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    border-radius: 5px;
    width: 20px;
    transition: 0s !important;
    padding: 0 !important;

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--colors-white);
      margin-left: 0px;
    }
    &--active {
      width: 40px !important;
      .span-btn {
        display: none;
      }
    }
  }
  .icons {
    width: 200px;
    height: 40px;
    background: #f5f5f5;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      img {
        width: 20px;
        height: 17px;
        object-fit: contain;
      }
    }
    .anticon {
      color: var(--colors-litberry);
      font-size: 16px;
    }
  }
  &-responsive {
    display: none;
  }
  .btn-close {
    display: none;
  }
  @media screen and (max-width: 768px) {
    &-responsive {
      z-index: 3000;
      display: flex;
      width: 100%;
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
      padding: 20px;
      bottom: 50px;
      padding-top: 45px;
      left: 0;
      background-color: $colors-white;
      flex-direction: column;
      box-shadow: none;
      padding-bottom: 80px;
      padding-top: 20px;
      .button-close {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        border: none;
        background-color: transparent;
        justify-content: center;
        box-shadow: none;
        align-items: center;
        .line {
          width: 87.02px;
          height: 4px;
          background: #B2B2B2;
          border-radius: 50px;
        }
      }
      a {
        height: 35px;
        margin: 5px 0;
        span {
          margin-left: 10px;
          font-family: Lato;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: left;
          color: var(--colors-pureberry);
        }
      }
    }
    .icons {
      display: none !important;
    }
  }
}

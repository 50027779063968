.PiaDashboard {
  background: var(--colors-darkberry);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  height: 180;
  border-radius: 10px;
  min-height: 223px;
  height: 491px;
  margin-bottom: 100px;
  .loader-ligth {
    padding-top: 160px;
  }
  .cont-btn {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .button-link {
    padding: 16px;
    margin-top: 20px;
    img {
      margin-right: 5px;
      margin-top: -3px;
    }
  }

  &--data,
  &--empty {
    padding: 24px 45px;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
  }
  &--card {
    margin-bottom: 30px;
    &--title {
      .box {
        p {
          text-align: left !important;
        }
      }
    }
    img {
      margin-right: 12px;
      width: 154px !important;
      height: auto !important;
    }
  }
  &--empty {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__content {
      border-radius: 10px !important;
      max-width: 512px;
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $s-medium) {
          flex-direction: column;
          gap: 12px;
        }

        .see-demo {
          background-color: transparent;
          border: none;
          box-shadow: none;
          font-family: "Lato";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #35d8d0;
          img {
            margin-left: 5px;
          }
        }
      }
    }
  }
  &--data {
    @media (max-width: 1305px) {
      padding: 30px 20px;
    }
  }
  .pia-content-dashboard {
    padding-left: 50px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    @media (max-width: 1355px) {
      padding-left: 20px;
    }
  }
  .PiaDashboard--card.PiaDashboard--card--title {
    width: auto;
  }
  .PiaDashboard--card {
    &--big {
      width: 38% !important;
    }
    h3 {
      span {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        margin: 0px 5px;
        margin-top: -2px;
      }
    }
  }
  .content-res {
    display: none;
  }
  &--card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 32%;
    @media (max-width: 1355px) {
      width: auto;
    }
    @media (max-width: 1255px) {
      width: 28%;
    }
    &--min {
      width: 32%;
      @media (max-width: 1355px) {
        max-width: 230px;
      }
    }
    h3 {
      margin-bottom: 15px;
    }
    .body-bold {
      color: #ffffff;
    }
    .button {
      margin-top: 15px;
      width: 184px !important;
    }

    .card-pia {
      border: 1px solid #5d30dd;
      padding: 11px 16px;
      border-radius: 10px;
      margin-bottom: 15px;
      width: 100%;
      height: 96px;
      &--big {
        min-width: 255px;
      }
      &--mx {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 16px 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .small-detail {
          text-transform: capitalize;
          margin-bottom: 0;
        }
        .text {
          font-family: Lato;
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 0;
          width: auto;
          min-width: 98px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &-null {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &--box {
        .bar {
          text-transform: capitalize;
          background: var(--colors-pureberry);
          height: 25px;
          width: 100%;
          margin: 7px 0;
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p {
              margin-bottom: 0;
              margin-left: 5px;
            }
          }
          .small-detail--yellow {
            color: #ffbb0d;
            margin-bottom: 0;
          }
        }
      }
      .line {
        background-color: var(--colors-pureberry);
        height: 1px;
        width: 100%;
        margin: 10px 0;
      }
      .section-state,
      .state {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 0;
          color: #ffffff;
          width: 54px;
        }
        .circle {
          height: 6px;
          width: 6px;
          border-radius: 6px;
          margin-left: 5px;
          &-active {
            background-color: #25d366;
          }
          &-inactive {
            background-color: #ffbb0d;
          }
          &-medium {
            background-color: transparent;
            border: 1px solid #ffbb0d;
          }
        }
      }
      .state {
        margin: 0 7.5px;
      }
      &--brand {
        display: flex;
        justify-content: center;
        align-items: center;
        .brand {
          .content-brand {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          &--circle {
            height: 6px;
            width: 6px;
            border-radius: 6px;
            margin-bottom: 5px;
            &--conectado {
              background-color: #25d366;
            }
            &--vencida {
              background-color: #ffbb0d;
            }
            &--proceso {
              border: 1px solid #ffbb0d;
            }
          }
          &--cont {
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border-radius: 50px;
            margin: 0 5px;
          }
        }
      }
    }

    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 62px;
      line-height: 75px;
      margin-bottom: 0;
      display: flex;
      margin-left: -5px;
      align-items: center;
      background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 9.24063px;
      line-height: 11px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }
  }
  .img-pia {
    width: 114px;
    height: 119px;
    object-fit: contain;
  }
  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
    border-radius: 10px 0px 0px 10px;
    max-width: 428px;
    height: 158px;
    padding: 16px;
    padding-top: 20px;
    .button {
      padding: 16.5px 0;
      height: 50px !important;
    }
    .body-regular {
      margin-bottom: 20px;
      color: var(--colors-white);
    }
  }
  &--empty {
    padding-right: 0;
    .PiaDashboard--card--title {
      width: auto;
      flex-direction: row;
      padding: 0 20px;
      .box {
        margin-left: 30px;
        h1 {
          font-size: 97.1857px;
          line-height: 97px;
        }
        p {
          font-size: 14.3829px;
          line-height: 17px;
          text-align: center;
        }
      }
    }
    &__content {
      width: 67%;
      background: linear-gradient(274.08deg, rgba(93, 48, 221, 0.67) -2.97%, #7f14fc 56.11%);
      border-radius: 10px 0px 0px 10px;
      padding: 20px;
      display: block;
      text-align: center;
      p {
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 40px;
    &--data {
      flex-direction: column;
      padding-right: 0;
      padding-left: 0;
    }
    &--empty {
      flex-direction: column;
      padding: 20px;
      &__content {
        width: 100%;
        border-radius: 10px;
      }
      .PiaDashboard--card--title {
        .box {
          margin-left: 15px;
          h1 {
            font-size: 62.4394px;
            line-height: 75px;
          }
          p {
            font-size: 9.24063px;
            line-height: 11px;
          }
        }
      }
    }
    .pia-content-dashboard {
      display: none;
    }
    .content-res {
      display: block !important;
      .slick-arrow {
        display: none !important;
      }
      .slick-dots li button:before {
        font-size: 0;
        width: 8px;
        height: 8px;
        border: 2px solid #5d30dd;
        border-radius: 50px;
        opacity: 1;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        background: #5d30dd;
      }
      .PiaDashboard--card {
        margin-bottom: 20px;
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 20px;
        margin-left: 20px;
      }
    }
    &--card {
      text-align: center;
      margin-bottom: 20px;
      width: 100% !important;
      max-width: 100% !important;
      &--title {
        flex-direction: row !important;
        img {
          margin-right: 20px;
        }
      }
      .button {
        margin: 0 auto !important;
        margin-top: 20px !important;
      }
      .img-pia {
        width: 84px;
        height: 84px;
      }
      h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 57.1857px;
        line-height: 81px;
        margin-bottom: 0;
        display: flex;
        margin-left: -5px;
        align-items: center;
        background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .content {
      height: auto;
      a {
        padding: 10px !important;
      }
    }
  }
}

.onBoardingComplete-responsive {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__congrats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      margin-bottom: 17px;
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: var(--colors-black);
      opacity: 0.5;
      margin-bottom: 42px;
    }
  }

  .finish {
    .ant-btn {
      width: 277px;
      height: 51px;
      background: var(--colors-litberry);
      border-radius: 5px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: var(--colors-white);
    }

    .ant-btn:active,
    .ant-btn:hover,
    .ant-btn:focus {
      border: 2px solid var(--colors-litberry);
      color: var(--colors-white);
      background: var(--colors-litberry);
      border-radius: 5px;
    }
  }
}

.dashboard-pia {
  padding-bottom: 50px;
  padding-top: 35px;
  margin-top: -35px;
  position: relative;
  background-color: #f7f7f7;
  z-index: 1;
  .list-filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    button {
      height: 37px;
      border: 1px solid #5D30DD;
      border-radius: 5px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #5D30DD;
      margin-bottom: 5px;
      text-transform: capitalize;
      svg {
        margin-left: 10px;
        margin-bottom: -2px;
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  &--dark {
    background-color: #1c0e42;
    .DetailProduct {
      .image {
        filter: brightness(100) !important;
      }
    }
    .container {
      background-color: #1c0e42;
    }
    .select-multiple {
      border: 1px solid #00dbd0 !important;
    }
    .HeaderFeedBack {
      border: 1px solid #00dbd0;
      @media (max-width: $medium) {
        border: 0px solid #00dbd0;
      }
    }
    .Policies--open {
      border: 1px solid #00dbd0;
      .ant-slider {
        .button {
          border: 1px solid #00dbd0 !important;
          background-color: #1c0e42;
          .body-bold {
            color: #ffffff !important;
          }
          img {
            filter: brightness(100);
          }
        }
      }
    }
    .Policies__content__filter .box-big-before {
      background: #1c0e42 !important;
      border: none;
      .button-list {
        border: 1px solid #00dbd0 !important;
      }
    }
    .button-see-less {
      color: #ffffff !important;
    }
  }
  .container {
    position: relative;
  }
  .Policies {
    margin-top: 20px !important;

    .button-transparent {
      width: auto !important;
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      img {
        width: 20px;
      }
    }
    @media (max-width: $medium) {
      margin-top: 30px;
    }
    &__content-head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        padding: 0 15px;
      }
      h2 {
        margin-bottom: 0;
      }
    }
    margin-bottom: 20px !important;
    &--open {
      position: relative;
      padding: 20px;
      background-color: #ffffff !important;
      box-shadow: 1px 1px 11px -7px rgba(0, 0, 0, 0.2) !important;
      transition: 0.7s;
      @media (max-width: $medium) {
        width: 100% !important;
        right: auto;
      }
    }
  }
  .h2-policy {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .policies-found {
    margin-top: 20px;
    padding-bottom: 40px;
    button {
      border: none;
      background-color: transparent;
      margin-top: 15px;
      img {
        margin-right: 15px;
      }
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: var(--colors-pureberry);
      }
    }
  }
  .msj-alert {
    width: 100%;
    height: 35px;
    background: #fffaed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 100px;
    .camping {
      margin-right: 10px;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 0;
      color: var(--colors-pureberry);
    }
    @media (max-width: 1000px) {
      margin-top: 250px;
    }
    @media (max-width: 760px) {
      margin-top: 40px;
    }
  }
  .container {
    max-width: 1137px;
  }
  .efect-darck {
    background-color: #1c0e42 !important;
    border-radius: 10px;
    padding: 20px 10px;
    .DetailProduct {
      background-color: #1c0e42 !important;
      border: 1px solid #00dbd0;
      box-sizing: border-box;
      .ant-select-selector {
        background-color: #1c0e42 !important;
        border: 1px solid #00dbd0 !important;
      }
      &__head {
        .button--primary {
          background: #35d8d0 !important;
          color: #1c0e42 !important;
        }
        .body-bold {
          color: #ffffff;
        }
      }
      &__content {
        .row {
          .column {
            .body-bold {
              color: #ffffff;
            }
          }
        }
      }
    }
    &--top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .h2 {
      background: linear-gradient(90deg, #00dbd0 2.5%, #7b3bf9 75.97%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
	@media (max-width: $medium) {
		padding: 0;
    .Policies__content {
      padding: 0;
    }
    .Policies .InsurersFilter {
      width: 100%;
      .box .btn-select-caroucel {
        margin-right: 0 !important;
        margin-left: 0;
      }
    } 
    .Policies__content__filter .box-mini--border .button--select {
      width: 100% !important;
    }
	}
}

.container-modal {
  width: 100%;
  min-height: 606px;
  &__requestDate {
    text-transform: capitalize;
  }
  .HospitalGroup {
    background: var(--colors-white);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    border-radius: 5px;
    .title {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      margin-bottom: 20px;
    }
    img {
      margin-bottom: 15px;
    }
    .text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
    }
  }
  &__msj {
    background: var(--colors-white);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 48%;
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;

    h3 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      color: var(--colors-pureberry);
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    h6 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: var(--colors-pureberry);
      margin-bottom: 15px;
      margin-top: -5px;
    }

    h4 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      margin-top: 15px;
    }
    .divider {
      width: 300px;
      height: 1px;
      margin-left: -30px;
      background-color: var(--colors-pureberry);
      opacity: 0.1;
    }
    &Texts {
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
      &__left {
        width: 272px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        margin: 0;
      }
      span {
        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: var(--colors-black);
        max-width: 300px;
      }
      &__right {
        width: 272px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }

    .ant-btn {
      background: #25D366;
      border-color: #25D366;
      border-radius: 5px;
      border: none;
      padding: 0;
      display: flex;
      margin-top: 10px;
      justify-content: center;
      align-items: center;
      height: 62px;
      width: 35%;
      box-shadow: none;
      @media (max-width:768px) {
        height: 54px;
      }
      img {
        width: 18px;
        height: 18px;
      }

      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-white);
        margin-left: 10px;
      }
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      background: #25D366;
      border-color: #25D366;
      border-radius: 5px;
      border: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 62px;
      box-shadow: none;
      @media (max-width:768px) {
        height: 54px;
      }
      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: var(--colors-white);
        margin-left: 10px;
      }
    }
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
      .divider {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }

  &__recommended {
    background: var(--colors-white);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    height: 48%;

    &Header {
      height: 20%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 20px 22px;
      padding-top: 25px;

      .circle {
        background: var(--colors-litberry);
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: var(--colors-frozenberry);
          margin: 0;
        }
      }

      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: var(--colors-pureberry);
        margin-bottom: 0;
      }
    }

    &Product {
      height: 80%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      padding: 0 30px 0 23px;
      overflow: auto;
      padding: 25px;
      @media (max-width: 768px) {
        padding: 0 20px 0 20px;
        overflow: hidden;
        justify-content: space-between;
        .cont-card {
          width: 48.5%;
          margin-bottom: 10px;
        }
        .category {
          width: 100% !important;
        }
      }

      .image-logo {
        object-fit: contain;
        width: 80px;
        height: 23px;
        margin: 10px;
      }
      .icons {
        display: flex;
      }
      .link {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        color: var(--colors-litberry);
      }

      .category {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 155px;
        height: 144px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 5px;
        margin-right: 23px;
        padding: 8px;

        i:before {
          color: var(--colors-frozenberry);
        }

        .circle {
          width: 26px;
          height: 26px;
          background-color: var(--colors-white);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin: 10px;
          
          img{
            max-width: 14px;
          }

        }
        &--img {
          width: 175px;
          height: 144px;
          position: absolute;
          z-index: 1;
          border-radius: 5px;
        }
        .circle-img {
          position: relative;
          z-index: 23;
          background: var(--colors-white);
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin: 15px 18px;
          .logo {
            height: 13px;
          }
        }

        span {
          z-index: 99;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 13.4154px;
          line-height: 16px;
          color: var(--colors-white);
          margin: 15px 18px;
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        margin-top: 10px;
        .button {
          min-width: auto;
          background: var(--colors-frozenberry);
          border-color: var(--colors-frozenberry);
          color: var(--colors-white);
          height: 40px !important;
        }
        span {
          text-decoration-line: none !important;
        }
      }

      .link {
        background: none;
        border: none;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;

        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          text-decoration-line: underline;
          color: var(--colors-litberry);
        }
      }

      .link:hover,
      .link:focus,
      .link:active {
        background: none;
        border: none;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 125.19%;
          text-decoration-line: underline;
          color: var(--colors-litberry);
        }
      }

      /*&::-webkit-scrollbar {
        background: #5f5f5f2c;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #5f5f5f3b;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        align-items: center;
        margin-top: 15px;
        .btn {
          border-radius: 5px;
          height: 40px;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          text-align: center;

          &--secondary {
            border: none;
            background: var(--colors-frozenberry);
            border-color: var(--colors-frozenberry);
            color: var(--colors-white);
          }*/
      &Product {
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 30px 0 23px;
        position: relative;
        overflow: auto;
        .category {
          width: 175px;
          height: 144px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: 5px;
          margin-right: 23px;
          img {
            width: 175px;
            height: 144px;
            position: absolute;
            z-index: 1;
            border-radius: 5px;
            filter: brightness(0.4);
          }

          span {
            z-index: 99;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 13.4154px;
            line-height: 16px;
            color: var(--colors-white);
            margin: 15px 18px;
          }
        }

        .ant-btn {
          background: none;
          border: none;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-shadow: none;
          margin-top: 14px;

          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 125.19%;
            text-decoration-line: underline;
            color: var(--colors-litberry);
          }
        }

        .ant-btn:hover,
        .ant-btn:focus,
        .ant-btn:active {
          background: none;
          border: none;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-shadow: none;
          margin-top: 14px;
          span {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 125.19%;
            text-decoration-line: underline;
            color: var(--colors-litberry);
          }
        }

        &::-webkit-scrollbar {
          background: #5f5f5f2c;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #5f5f5f3b;
        }
      }
    }
    @media (max-width: $medium) {
      padding-bottom: 50px;
    }
  }
  @media (max-width: $medium) {
    &__msj {
      position: relative;
      border-radius: 15px;
      padding: 20px;
      .divider {
        display: none;
      }
      h4 {
        display: none;
      }
      h6 {
        font-size: 15px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
      }
      h3 {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
      }
      .ant-btn {
        padding: 0 20px;
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}

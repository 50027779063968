.page-404 {
  .padding-header {
    display: none;
  }
  .header {
    .custom-trigger-btn--notifications, #tutorial-button-header {
      display: none !important;
    }
  }
  overflow-x: hidden;
}
.error_page {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-white);
  margin: 0px;
  margin-top: 0px;
	min-height: calc(100vh - 51px);
  .coetent-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fcfcfc;
    border-radius: 5px;
    height: 517px;
    max-width: 517px;
    .logo {
      width: 240px;
      height: 273px;
    }
    .logo-pia {
      width: 470px;
    }
    @media (max-width: 768px) {
      height: 282px;
      width: 90%;
      margin-top: 20px;
      .logo-pia {
        width: 259px;
      }
    }
  }
  
  &__contain {
    max-width: 517px;
    min-height: calc(100vh - 283px);
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding-left: 100px;
    img {
      width: 245px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 40px;
    }
    .logo {
      width: 240px;
      height: 273px;
      object-fit: contain;
      position: absolute;
      right: 16%;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: var(--colors-pureberry);
      max-width: 450px;
    }
    .text-span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: var(--colors-pureberry);
      margin-top: -20px;
      a {
        color: var(--colors-litberry);
      }
    }
    .button {
      max-width: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 0;
    &__contain {
      min-height: calc(100vh - 283px);
      width: 100%;
      h1 {
        font-size: 30px;
        line-height: 36px;
      }
      p {
        font-size: 15px;
        line-height: 150.19%;
      }
    }
    &__contain, .coetent-img {
      padding: 20px;
      .logo {
        width: 85px;
        height: 100px;
      }
    }
  }
}